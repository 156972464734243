import React from 'react';
import classNames from 'classnames';

import styles from './ErrorText.module.scss';

const ErrorText = ({ className, children, keepSpace = false }) => (
  <span className={classNames(className, styles.errorText)}>
    {children}
    {keepSpace && <>&nbsp;</>}
  </span>
);

export default ErrorText;
