import { ROUTES, CONSULT_MODE } from '../constants';

const { MIA_OVERVIEW } = ROUTES;
const ID_REGEXP = new RegExp(':id');

export const MIAOverviewUrl = (id, consultMode) => {
  const url = MIA_OVERVIEW.replace(ID_REGEXP, id);
  if (consultMode !== CONSULT_MODE.REVIEW) {
    return url;
  }

  return `${url}?mode=review`;
};
