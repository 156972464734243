/* eslint-disable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */

import classnames from 'classnames';
import React from 'react';
import { createPortal } from 'react-dom';
import ReactFocusTrap from 'focus-trap-react';

import Button from '../../../components/widgets/Button';
import closeImg from '../../../assets/close.svg';

import styles from './Modal.module.scss';

const ModalContent = ({
  ariaLabel,
  buttonRef,
  cancel,
  className,
  content,
  modalRef,
  modalTitle,
  onClickAway,
  onClose,
}) => {
  return createPortal(
    <ReactFocusTrap
      focusTrapOptions={{
        initialFocus: modalTitle ? '#modal-title' : '#modal-content',
        onDeactivate: onClose,
      }}>
      <div
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        aria-modal="true"
        className={styles.cover}
        onClick={(e) => {
          // if event isn't a bubble up
          if (e.target === e.currentTarget) {
            onClickAway();
          }
        }}
        role="dialog">
        <div className={classnames(styles.modal, className)} ref={modalRef}>
          <div className={styles.header}>
            <div
              className={styles.title}
              id="modal-title"
              tabIndex={modalTitle ? '0' : ''}>
              {modalTitle}
            </div>
            <button
              className={styles.close}
              onClick={onClose}
              ref={buttonRef}
              type="button"
              aria-label="Close">
              <img src={closeImg} alt="" />
            </button>
          </div>
          <div
            aria-label={ariaLabel}
            className={styles.content}
            id="modal-content"
            tabIndex={modalTitle ? '' : '0'}>
            {content}
          </div>
          {cancel && (
            <div className={styles.actions}>
              <Button label="Cancel" onClick={onClose} />
            </div>
          )}
        </div>
      </div>
    </ReactFocusTrap>,
    document.body,
  );
};

export default ModalContent;
