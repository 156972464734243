import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import {
  renderHashedSSN,
  renderAge,
  renderGender,
  renderName,
} from '../../../utils/functions';
import { renderStatus } from '../../../utils/enumStrings';
import { MIAOverviewUrl } from '../../../utils/urls';
import { CONSULT_MODE } from '../../../constants';
import i18n from '../../../i18n';
import List from '../List';
import VerifySSNModal from '../VerifySSNModal';
const { VIEW_ONLY } = CONSULT_MODE;

const searchList = (list, text) => {
  let lowerSearch = text.toLowerCase();
  return list.filter((item) => {
    const complaintName = item.main_complaint.name.toLowerCase();
    const patientName = renderName(
      item.account.first_name,
      item.account.last_name,
    ).toLowerCase();
    const complaintMatch = complaintName.includes(lowerSearch);
    const nameMatch = patientName.includes(lowerSearch);
    return complaintMatch || nameMatch;
  });
};

const PastConsultsList = ({ list, loading }) => {
  const history = useHistory();
  const [isSSNModalOpen, setSSNModalOpen] = useState(false);
  const [selection, setSelection] = useState(null);

  const modalCancel = useCallback(() => setSSNModalOpen(false), [
    setSSNModalOpen,
  ]);
  const handleSSNVerified = useCallback((args) => {
    history.push(MIAOverviewUrl(selection.id, VIEW_ONLY));
  });
  const handleSearch = useCallback((text) => searchList(list, text), [list]);
  const handleClickView = useCallback((item) => {
    setSelection(item);
    setSSNModalOpen(true);
  });

  const viewHandler = (item) => (e) => {
    e.preventDefault();
    handleClickView(item);
  };

  const TABLE_COLUMNS = [
    {
      label: i18n.t('initial_complaint'),
      render: (item) => item.main_complaint.name,
    },
    {
      label: i18n.t('name'),
      render: (item) =>
        renderName(item.account.first_name, item.account.last_name),
    },
    {
      label: i18n.t('age_gender'),
      render: (item) => {
        const { date_of_birth, gender } = item.account;
        return (
          <>
            {renderAge(date_of_birth)} - {renderGender(gender)}
          </>
        );
      },
    },
    {
      label: i18n.t('patient_id'),
      render: (item) => renderHashedSSN(item.account.username),
    },
    {
      label: i18n.t('status'),
      render: (item) => renderStatus(item.status),
    },
    {
      label: '',
      render: (item) => (
        <Link
          to={MIAOverviewUrl(item.id, VIEW_ONLY)}
          onClick={viewHandler(item)}>
          {i18n.t('view')}
        </Link>
      ),
    },
  ];

  return (
    <>
      <List
        columns={TABLE_COLUMNS}
        items={list}
        loading={loading}
        searchable
        onSearch={handleSearch}
      />
      {isSSNModalOpen && (
        <VerifySSNModal
          patientId={selection.account.username}
          onClose={modalCancel}
          onSSNVerified={handleSSNVerified}
        />
      )}
    </>
  );
};

export default PastConsultsList;
