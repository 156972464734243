/**
 * setup a search function from a local dataset
 * @param {*} data data set
 * @param {*} getStringToMatch function to extract string to test from any item
 * @returns function that take a search text params and return match results sorted by match index
 */
export default function localSearch(data, getStringToMatch = (i) => i) {
  return function(searchText) {
    let lowerSearch = searchText.toLowerCase();
    return (
      data
        // compute match index
        .map((d) => [
          d,
          getStringToMatch(d)
            .toLowerCase()
            .indexOf(lowerSearch),
        ])
        // filter out when no match
        .filter(([, idx]) => idx !== -1)
        // sort by match index
        .sort(([, i1], [, i2]) => i1 - i2)
        // remap to model only
        .map(([d]) => d)
    );
  };
}
