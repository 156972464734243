export const ROUTES = {
  LOGIN: (next) => `/login${next ? `?next=${encodeURIComponent(next)}` : ''}`,
  NEW_PATIENT: '/new_patient',
  SEARCH_PATIENT: '/search_patient',
  MIA_OVERVIEW: '/mia_overview/:id',
};

export const GENDER = { Male: 'Male', Female: 'Female' };

export const STATUS = {
  WAITING: 'standby',
  MIA_IN_PROCESS: 'picked',
  MIA_COMPLETE: 'submitted',
  CONSULT_IN_PROCESS: 'consulting',
  CONSULT_COMPLETE: 'consulted',
};

export const KEY = {
  ENTER: 13,
  ESC: 27,
  UP: 38,
  DOWN: 40,
};

export const ROLE = {
  ASSISTANT: 'assistant',
  DOCTOR: 'doctor',
  PATIENT: 'patient',
};

export const CONSULT_MODE = {
  VIEW_ONLY: 'view',
  START: 'start',
  REVIEW: 'review',
};

export const PATIENT_ID_LENGTH = 10;
