import BODYPARTS from '../constants/bodyparts';

import imgSrc from '../assets/right-side.png';
import imgHeadSrc from '../assets/head-right-side.png';
import imgRightLegSrc from '../assets/leg-right-side.png';
import imgFootLegSrc from '../assets/foot-right-side.png';
import imgTorsoTopSrc from '../assets/torso-top-right-side.png';
import imgTorsoMidSrc from '../assets/torso-mid-right-side.png';
import imgTorsoLowSrc from '../assets/torso-low-right-side.png';

export default {
  name: BODYPARTS.Body_R,
  viewBox: '0 0 1668 2388',
  background: imgSrc,
  areas: [
    {
      name: BODYPARTS.Head_R,
      viewBox: '0 0 2388 1669',
      background: imgHeadSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Mastoid_R,
          path:
            'M1171.2 412.2c20.4.7 44.8-.5 69.1-1.1 13.6-.3 27.3-1.4 40.9-1.8 7.8-.2 9.8 1.4 11 9.1 4.7 30 9.1 60 13.7 89.9.4 2.5.8 5-1.7 6.8-2.6 1.8-4.8.5-7-.7-19.3-10-39.3-11-59.6-3.7-18.5 6.6-30 20.4-37.6 37.9-8.3 19.3-11.7 39.5-12.5 60.4-.8 22.8 1.1 45.3 9.3 66.9 5.1 13.4 12.2 25.9 18.6 38.7 9.8 19.9 20.3 39.4 32 58.3 3.6 5.8 7.3 11.5 11.8 16.7 12 13.9 27.1 17.8 44.7 14.4 6.8-1.3 9.1.6 9.6 7.6.3 3.5.6 7 .8 10.5.2 4.6-2.3 7.8-6.8 8.3-4.1.5-8.3.9-12.4 1-29.8.8-59.6 1.4-89.4 2.2-17 .5-34 1.1-50.9 1.9-5.7.3-9.3-1.8-11.8-7.1-28.7-60.5-49.6-123.3-58.6-189.9-3.1-22.8-4.9-45.8-4.6-68.7.7-49 7.9-96.9 26.6-142.6 6.1-14.9 6.1-14.9 22.3-14.9 12.9-.1 25.8-.1 42.5-.1z',
        },
        {
          name: BODYPARTS.Cheek_R,
          path:
            'M1701 758.5c-1.1 22.1 2.3 43.9 3.8 65.8.7 9.8.9 19.6-.9 29.3-6.6 35.6-13.2 71.1-25.1 105.4-3.9 11.1-5.8 12-16.5 7.2-28.9-12.9-54.7-30.4-77.5-52.3-15.7-15-30.8-30.4-42.5-48.9-16.2-25.7-25.6-53.9-28.2-84-5.4-62.9 12.3-120.2 43.9-174 1.3-2.2 2.7-4.2 4.3-6.2 3.9-4.8 6.8-4.4 8.9 1.3 4.2 11.4 11 20.8 20.4 28.5 14.1 11.5 30.3 18.7 47.7 23.4 13.5 3.7 27.2 6.4 41.4 6.3 6.8-.1 8.6 1.8 8.7 8.7.1 9 2.5 17.4 5.8 25.7 5.6 14.2 5.3 29.4 6.1 44.3 0 6.5-.3 13-.3 19.5z',
        },
        {
          name: BODYPARTS.Chin_R,
          path:
            'M1727.1 1098.8c2.1 32.6-22.6 61.1-55.3 68.1-6.9 1.5-13.8 2.6-20.8 2.8-10.4.3-13-3.5-14.1-14-2.5-23.7 2.5-46.4 7.2-69.2 6.1-29.7 14.2-59 22.8-88 .9-3.1.7-8 5-8.4 4.8-.4 4.8 4.8 6.3 7.8 7.9 15.8 17.8 29.8 34.9 36.9 2.5 1 4.2 2.7 4.7 5.4 3.5 19.4 9.8 38.5 9.3 58.6z',
        },
        {
          name: BODYPARTS.Ear_R,
          path:
            'M1355.9 672c.1 23.1-.6 44.1-5.1 64.6-5.1 23.3-15.5 43.9-34.4 59.4-16.4 13.6-39.2 12.5-54.3-2.9-10.2-10.3-16.6-23.2-24.2-35.4-11.1-17.9-18.8-37.5-29.1-55.8-10.3-18.4-18-37.6-20.4-58.6-4.1-36-1.5-71.4 16-104.1 10.4-19.5 27.5-30.3 49.5-33.1 14.2-1.8 28-.1 40.8 6.5 13.2 6.8 22.6 17.8 30.5 30.2 17.8 27.8 24.6 59.1 28.8 91.2 1.6 13.4 2.2 26.7 1.9 38z',
        },
        {
          name: BODYPARTS.Eye_R,
          path:
            'M1681.6 509.4c13.3.5 26.2 2.5 38.1 8.7 8.8 4.6 10.4 9.5 6.3 18.4-7.3 15.5-14.8 31-21.8 46.7-8.6 19.6-12.8 40.3-14 61.6-.8 14.2.1 17.4-17.5 15.7-26.6-2.6-51.9-9.2-74.3-24.3-10.1-6.7-18.6-15.1-24.1-26.2-9.3-18.7-5.8-36.3 7.1-51.2 23.6-27.3 53.8-43.6 89.8-48.6 1.8-.3 3.6-.6 5.4-.8 1.6-.1 3.3 0 5 0z',
        },
        {
          name: BODYPARTS.Forehead_R,
          path:
            'M1733.2 501.6c-.3 5.6.3 10.3-.8 14.9-1.3 5.6-4.7 7.4-9.5 4.3-10.8-7.1-22.7-9.8-35.4-10.7-16.5-1.2-32.1 2.2-47.3 8.1-4.8 1.9-9.4 4.2-14.2 6.2-6.8 2.8-9.6 1.8-12.4-5.2-4.9-12.4-10.7-24.4-16.8-36.3-20.5-39.7-46-76.1-73.7-111-5.7-7.2-5.7-9.7.6-16.2 35.5-36.2 77.9-60 127.2-71.5 12.3-2.9 13-2.3 18.9 8.5 26.7 48.9 46.1 100.5 56.5 155.3 3.6 18.1 6.7 36.2 6.9 53.6z',
        },
        {
          name: BODYPARTS.Temporal_R,
          path:
            'M1511.7 364.1c3.9-.2 6.7 1.8 9 4.6 26.1 32.1 49.9 65.9 70.1 102.2 8.7 15.6 16.7 31.5 22.9 48.3 3.4 9 2.8 10.3-5.1 15.7-12.7 8.6-24.3 18.2-32.4 31.5-3.3 5.4-5.1 11.2-6.3 17.3-1.4 6.9-3.8 8.5-10.9 7.3-38.1-6.8-76.1-13.5-114.2-20.3-36.3-6.4-72.5-12.8-108.8-19.1-5.1-.9-8.4-3.3-10.9-7.7-4.9-8.8-11.1-16.7-19-23-1.8-1.5-2.6-3.4-3.1-5.6-5.3-20.4-8.3-41.1-10.4-62.1-1.2-12-1-24-1-36 0-8.1 1.1-9.3 9.2-10.9 38.2-7.7 76.5-15.4 114.7-23.1 30.4-6.1 60.8-12.2 91.2-18.2 1.7-.4 3.4-.6 5-.9z',
        },
        {
          name: BODYPARTS.Preauricular_R,
          path:
            'M1501.9 753.5c-10-1.8-22.3-4-34.6-6.2-34.1-6-68.3-11.9-102.4-17.8-9.5-1.6-11.5-3.5-11-13.1.6-14.8 2.2-29.5 2-44.4-.4-38-5.9-75-20.5-110.4-3-7.3-.4-10.3 7.7-8.9 36.1 6.3 72.2 12.7 108.3 19 32 5.6 64 11.1 96 16.6 3.6.6 7.2 1.4 10.7 2.4 6 1.6 7.3 4.6 4.2 10-4.3 7.3-9 14.4-13 21.9-16 30-26.4 61.9-32.3 95.2-1.7 9.7-3.1 19.4-4 29.2-.7 5-2.9 6.6-11.1 6.5z',
        },
        {
          name: BODYPARTS.Backside_Head_R,
          path:
            'M1090.7 835.5c-21 .2-42 .2-63-1-39.2-2.3-78.5-4.6-116.9-13.9-3.8-.9-7.3-2-9.5-5.8-17.7-29.9-39.4-57.1-58.3-86.1-19.7-30.2-37.5-61.5-49.7-95.6-8.3-23.3-13.4-47.4-16.1-72-1.6-14.4-2.2-28.9-2.5-43.4-.3-15.3.8-30.6 1.9-45.9 1.9-25.1 5.5-50 10.7-74.6 1-4.6 2-9.1 3.2-13.6 1.7-6.5 4.3-8.3 10.9-7.1 6.1 1.1 12.1 2.6 18.1 3.9 43.5 9.5 87.4 16.3 131.7 21.1 26.2 2.8 52.3 5.2 78.6 6.9 22.1 1.4 44.2 2.8 66.4 3 1.8 0 3.6.3 5.5.6 9.2 1.3 10.2 3 6.6 11.7-14.4 34.1-22.2 69.7-25.9 106.4-2.2 22-2.6 44-1.5 65.9 2.1 44.5 10.2 88 23 130.6 9.8 32.6 22.1 64.3 36.4 95.2.4.9.8 1.8 1.2 2.8 2.6 6.9.2 10.8-7.3 10.9-14.5.1-29 0-43.5 0z',
        },
        {
          name: BODYPARTS.Jaw_R,
          path:
            'M1314.9 807.7c-2-7.5 1.6-13.2 7.5-18.6 14.7-13.4 22-31.1 27.3-49.7.5-1.6.8-3.2 1.3-4.8 1.7-6 4.1-7.6 10.3-6.6 23.3 4 46.7 8 70 12 23.6 4.1 47.3 8.2 70.9 12.4 8.9 1.6 10.4 3.6 10.8 12.6 1.7 45.8 15.7 87.3 46.3 121.8 29.8 33.6 63.9 61.9 105.9 79.4 8.5 3.5 9.4 5.8 6.4 15.8-7.9 26.7-15.5 53.5-22.1 80.5-5.1 20.9-9.6 41.9-12.3 63.3-1.5 11.8-.7 23.6.9 35.3.8 5.7-.1 7.7-5.7 8-6.3.4-12.7 0-18.9-.6-20.9-2.1-41.8-4-62.6-7-17.4-2.5-33.8-9.1-49.9-15.8-28.5-11.9-54.9-27.3-78.2-47.8-31.9-28.1-57-61-70.5-102-7.5-22.8-15.6-45.4-22.9-68.3-4.8-15.1-6.8-30.6-8.5-46.1-2.4-21.5-4.1-43.1-6-64.6-.2-2.9 0-5.7 0-9.2z',
        },
        {
          name: BODYPARTS.Mouth_R,
          path:
            'M1672.7 979.5c1.6-5.7 3.2-12.3 5.4-18.7 12.2-35.1 19-71.4 25.8-107.8.3-1.5.5-3 1-4.4 1.3-3.8 4.7-4.9 7.3-1.9 9 10.4 20.4 17.5 32.5 23.6 1.9.9 3 2.6 3.6 4.6 2.4 8.6 6.5 16.4 10.6 24.2 2.3 4.4 3.3 8.9 2.3 14-1.2 5.7-1.9 11.5-3.1 17.2-1.6 7.7-5.4 14-12.5 17.8-2.4 1.3-2.8 2.5-1.4 5.1 10 19.2 6.7 42.2-8 57.9-5.1 5.5-9.6 11.5-13.7 17.8-4.2 6.4-7.5 7.2-14.3 3.7-12.5-6.4-20.6-17.2-27.1-29.2-4.1-7.1-7.6-14.5-8.4-23.9z',
        },
        {
          name: BODYPARTS.Neck_Frontside_R,
          path:
            'M1139.6 1081.2c1.4-27.1.6-54.3 1.2-81.5.6-28.8 1.7-57.6 3-86.4 1-22.1 2.5-44.2 3.9-66.3.4-6.9 2.4-9.4 9.3-10 9.8-.9 19.6-1.1 29.4-1.5 18.6-.8 37.3-1.4 55.9-2.2 21-.9 41.9-2 62.9-2.8 10.2-.4 10.6.2 11.8 10.3 2.5 22.5 4.4 45.1 7.8 67.5 1.6 10.6 5.2 20.7 8.6 30.8 9.1 27.4 17.7 54.9 29.2 81.4 4.3 9.8 9.5 19.1 15.7 27.8 3.5 4.8 3.8 9.1.6 14.2-10.8 17.3-14.4 36.7-16.2 56.6-2 21.8-.4 43.3 3.3 64.7 2.5 14.4 7.7 28.3 10.6 42.7 5.1 25 8.2 50.2 9.4 75.8 1.9 38.4-1.3 76.2-9 113.7-.1.7-.4 1.3-.3 2 2 15.2-2.3 30.2-1.2 45.4 1 13.8 1.2 27.6 3.7 41.3.5 2.5.9 5.2-2 6.5-3 1.4-4.7-1-6.3-2.8-18.8-21.6-37.4-43.4-56.4-64.8-19.4-21.8-40.2-42.3-63.2-60.3-59-46.1-93.6-106.5-104.7-180.4-3.9-25.9-6.5-51.9-6-78.1.3-14.6-1.5-29-1-43.6z',
        },
        {
          name: BODYPARTS.Lower_Neck_R,
          path:
            'M1146.7 865.8c-1.3 25.9-2.5 51.9-3.8 77.8-.5 10.8-.6 21.7-1 32.5-1.7 54.7-2 109.4-1 164 .7 37.8 5.6 75.1 17.2 111.3 10.8 33.7 27.3 64.3 50.7 91.1 16.9 19.5 36.7 35.8 56.5 52.1 18.3 15.1 34.5 32.4 50.4 49.9 21.3 23.5 41.3 48 61.1 72.7 9.2 11.5 17.2 23.7 25.1 36.1.7 1.1 1.3 2.3 1.8 3.6 1.5 3.8-.2 6-4.2 5.3-4.1-.8-8.1-1.9-12.1-3-36.1-9.8-72.7-16.8-109.8-21.4-31.1-3.9-62.3-6.1-93.6-7.2-21.7-.8-43.3-.5-65-.6-44.2-.4-88.1-4.4-131.8-11.2-56.5-8.8-111.7-22.6-165.9-40.8-23.1-7.8-45.9-16.2-68-26.6-6.9-3.3-7.4-5.9-2.2-11.6 23.8-26.2 44.5-54.9 64.3-84.1 38.3-56.5 78.4-111.6 118.4-166.8 46.2-63.7 93.3-126.7 137.1-192.1 29-43.2 53.4-88.9 70.2-138.3.3-.9.7-1.9 1.1-2.8.5-1 1.2-2.2 2.6-2 1.5.2 1.6 1.6 1.7 2.7.1 3.2 0 6.3 0 9.5.1-.1.2-.1.2-.1z',
        },
        {
          name: BODYPARTS.Neck_Backside_R,
          path:
            'M1145.2 842.8c-2 10.4-5.4 20.5-9.1 30.5-14.9 40.2-35.3 77.6-58.4 113.6-29 45.2-61.5 88-92.6 131.7-50.9 71.4-103.9 141.2-153.4 213.7-21.5 31.5-43.1 63-67.3 92.6-4.8 5.8-9.7 11.4-15 16.7-3.3 3.4-6.8 3.9-10.8 1.8-2.2-1.1-4.5-2.2-6.8-3.2-6.6-2.9-7.8-5.8-4.1-12.5 5.6-10.1 11.4-20 17.5-29.8 22.8-36.9 45.9-73.5 68.5-110.5 18.4-30 34.5-61.3 49.3-93.2 14-30.1 26.7-60.7 37.3-92.2 13.4-40 22.2-81 26.5-122.9 2.2-21.6 3.6-43.2 2.7-64.9-1.2-28.2-6.9-55.5-19.1-81.2-.4-.8-.8-1.5-1-2.3-1-2.8-2.8-5.7-.5-8.5 2.2-2.6 5.1-1 7.5-.5 27.6 5.9 55.6 8.6 83.7 10.8 44.9 3.5 89.9 2.9 134.9 2.9 2.8 0 5.7-.5 8 1.8 1.4 1.6 2.4 3.2 2.2 5.6z',
        },
        {
          name: BODYPARTS.Nose_R,
          path:
            'M1721 564.1c-4 16-.3 31.8 7 47.1 3.8 8 7.7 15.9 11.9 23.7 10.9 20.4 22.1 40.7 32.9 61.2 10.7 20.4 22.3 40.2 35.5 59.1 5.9 8.5 11.4 17.3 14 27.5 3.2 12.3 3 24.4-2.3 36.1-3.8 8.5-10.1 14.2-18.8 17.6-12.6 4.9-25.5 9-38.7 11.7-8.2 1.7-13.5 5.4-14.5 14.5-.6 5.4-4.2 7.1-9 4.8-13.1-6.2-24-15-31.4-27.6-1.7-2.8-2.4-5.8-2.5-9-.7-15.3-2.1-30.5-3.7-45.8-.3-2.6-.3-5.3-.3-8-.1-22.6.7-45.3-2-67.9-.7-6-2.7-11.8-5.2-17.1-5.7-12.6-5.2-25.7-4.6-38.8.4-8.3 1.2-16.6 2.1-24.9 2.6-24.2 12.2-45.9 23.3-67.1.9-1.7 1.9-4 4.4-3.2 2.4.8 1.7 3.3 1.9 6.1z',
        },
        {
          name: BODYPARTS.Throat_R,
          path:
            'M1378.4 1411.3c1.7-12.1 3.2-22.9 4.6-33.6 3.6-28.9 3.7-57.8 2-86.8-1.8-30.7-6.6-61-15.2-90.5-7.3-25.2-8.7-50.9-7.8-76.7.6-17.4 3.8-34.5 10.9-50.6 1.3-2.9 2.7-5.8 4.4-8.4 4.1-6.5 8.1-6.6 13-.8 3.9 4.6 7.8 9.1 11.8 13.6 32.7 35.9 73 59.7 118.4 75.7 2.7.9 5.3 2 7.9 3.1 1.5.6 3.3 1.3 2.9 3.5-.4 1.8-2.1 1.9-3.6 2.1-6.8 1-13.5 2.1-20.3 3-13.9 1.7-21.7 9.8-24.6 23.2-2.8 12.9-5.4 25.8-9 38.5-7.1 25-18.4 48.2-30.9 70.9-17.7 32.1-37.2 63.2-52.2 96.8-2.5 5.6-5 11.3-7.6 16.9-.6 1.4-1.5 2.8-3.4 2.3-1.6-.8-1.3-2.3-1.3-2.2z',
        },
        {
          name: BODYPARTS.Head_Top_R,
          path:
            'M1246.8 41.8c72 .4 141.5 13.7 208.3 40.5 41.5 16.7 78.8 40.4 112.5 69.7 28.1 24.5 50 53.9 69.9 85.1 7.3 11.4 14.6 22.7 20.8 34.7 4.1 7.9 3.1 10.3-5.5 12.2-32.8 7.4-63 20.6-90.7 39.6-14.8 10.1-28.3 21.7-40.7 34.6-4.1 4.3-8.8 6.7-14.6 7.9-44.3 8.6-88.7 17.4-133 26.1-24.9 4.9-49.8 9.6-74.6 14.6-10.2 2.1-20.5 2.5-30.8 3.1-19.6 1.1-39.3 1.7-58.9 2.2-18.8.5-37.7 1.1-56.5.9-35-.4-70-.9-104.9-3-72.9-4.4-145.4-12.1-217-26.8-9.5-2-18.9-4.1-28.3-6.4-8.5-2-9.7-3.6-7.4-12.3 5.1-19.6 12.1-38.5 20.3-57 17.8-40.4 41.1-77.4 69.9-110.9 21.3-24.8 46.1-45.8 73.3-63.8 23.8-15.7 48.3-30.3 73.6-43.5 40.1-20.9 82.8-33.2 127.2-40.5 28.9-4.5 57.9-7.3 87.1-7z',
        },
      ],
      path:
        'M883.9 441.5c-15.6-6.3-31.2-13.4-47.3-19-20.1-7.1-41-11-62.3-12.8-6.9-.6-13.9-1.5-20.8-1.8-13.3-.6-26.1 1.9-38.6 6.2-5 1.7-9.8 3.6-16.2 6 3.5-6.1 6.3-11.1 9.2-16 12-19.7 24.9-38.9 34.3-60.1 9-20.6 15-41.8 16.1-64.3.9-17.7-5.4-32.7-15.8-46.6-11-14.7-21.4-30-24.4-48.5-4.9-30.7-1.2-60.5 14.5-87.7 21.1-36.6 54.1-55.6 95.4-60.8 30-3.8 58.5 1.5 85.3 15.6 22.7 11.9 36.6 31.8 47.9 53.9 8.4 16.2 14.3 33.3 15.6 51.5.5 6.2-1.4 12.7-2.8 19-1.2 5.2-.4 9.9 2.1 14.4 7.4 13.4 14.9 26.6 22.3 40 1.3 2.3 2.5 4.7 3.2 7.2 1.8 6.1-1.2 11.9-7 14.1-2.5.9-5 1.6-7.6 2.3-6.1 1.7-7.7 4.4-4.8 10.2 3.2 6.3 3.4 11.9-1.3 17.5-.5.6-.2 2.1 0 3.1 1.2 5.4.5 10.3-3.4 14.3-3.9 3.9-4.8 8.2-3.4 13.6 1.9 7.3 2.8 14.7-2.5 21.4-3.9 4.9-8.9 7.7-15 7.6-8.6-.1-17.2-1.1-25.9-1.4-5.1-.2-10.3.1-15.4.5-3.3.2-5.6 2-6.2 5.4-3 18.2-12.7 33.3-21.6 48.9-7.1 12.5-10.2 25.5-6.3 39.7.9 2.4 2 4.8 2.7 6.6z',
    },
    {
      name: BODYPARTS.Right_Leg_RL,
      viewBox: '0 0 1669 2388',
      background: imgRightLegSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Lateral_Thigh_RL,
          path:
            'M984.1 226c-1.3 34.7-2.3 69.5-4.8 104.2-2.2 31.9-4.8 63.7-10.2 95.2-1.4 8.1-3.1 16-5.7 23.8-1 3.1-1.1 7.7-4.5 8.4-3.6.7-4.8-4-6.6-6.5-17.3-24.3-33.3-49.4-48.2-75.3-19.2-33.2-39.1-65.9-55.6-100.5-9.3-19.6-17.5-39.6-25.7-59.6-3.8-9.1-1.7-17.7-.7-26.5 1.2-10.9 2.4-21.8 2.9-32.8.1-1.7.4-3.3.6-5 .3-3.7 2.3-4.6 5.8-4.1 29 4.1 57.2 11.2 84.4 21.9 21.3 8.4 41.5 18.9 60.3 32.1 5.9 4.1 9.2 8.8 8.1 16.2-.4 2.8-.1 5.7-.1 8.5z',
        },
        {
          name: BODYPARTS.Right_Foot_RL,
          viewBox: '0 0 1669 2388',
          background: imgFootLegSrc,
          // sub areas displayed when zooming in
          areas: [
            {
              name: BODYPARTS.Right_Heel_RL,
              path:
                'M274 1293.5c.4-33 1.5-65.9 4.1-98.8.4-4.5 1.2-8.9 1.6-13.4.3-3.6 1.9-5.7 5.3-7 19.6-7.1 32.5-21.6 42.6-38.9 17.1-29.2 25.5-61.4 31.9-94.2 5.4-27.8 8.6-55.9 10.6-84.2.6-8.6.1-17.3 1.7-25.9.3-1.4 0-3.3 2-3.5 1.9-.2 2.6 1.4 3.1 2.8 3.4 8.9 7 17.7 10 26.7 26.3 77.4 46.6 156.5 61.1 236.9 4.5 25.1 8.3 50.3 10.5 75.7 2.5 28 4.2 56.2-1.7 84-.8 3.6-1.7 7.1-2.9 10.6-5.9 16.6-14.3 22.6-31.8 23.7-32 2.1-63.9.8-95.5-5-13.8-2.6-27.3-6.2-40.5-11-4.3-1.6-6.7-4.1-7.6-8.8-2.1-11.5-3.1-23.1-3.7-34.7-.4-11.7-1.2-23.4-.8-35z',
            },
            {
              name: BODYPARTS.Right_Forefoot_RL,
              path:
                'M874.1 1020.8c5-.6 8.6 1.7 12.1 4.9 23.2 21.3 47.1 41.7 70.8 62.3 44.7 38.7 92.6 72.9 141.6 105.8 23.3 15.6 44.8 33.4 66.2 51.5 14.6 12.4 29.8 24.1 45.7 34.7 14.2 9.4 29.3 17.3 44.2 25.3 9.1 4.9 18.3 9.5 27.5 14.3 2.2 1.2 5.8 1.8 5.1 5.2-.6 2.9-4 2.6-6.3 2.9-24.5 3.7-48.3 9.8-71.3 19.1-22.5 9.1-43 21.3-60.7 37.9-12.9 12.1-23 26.3-30.5 42.3-3.6 7.6-4.4 8-12.3 5.7-34.5-10.3-69-20.6-103.4-31-8.6-2.6-8.6-2.8-7.8-11.7 1.9-23.4-1.7-46.1-8.3-68.5-11.8-40.2-30.5-77.4-51.4-113.5-31.9-55.1-68.8-106.6-108.8-156-4.9-6.1-4.9-8.6 1-13.9 10.4-9.4 23.3-13.2 36.7-15.8 3.3-.8 6.6-1.1 9.9-1.5z',
            },
            {
              name: BODYPARTS.Right_Lateral_Foot_RL,
              path:
                'M995.9 1378.8c-.2 2.6-.6 7-1 11.5-.6 6.2-3 7.8-9.1 6-11-3.2-22-6.7-33-9.9-39.3-11.4-79.2-19.8-119.9-24.1-18.2-1.9-36.5-3.1-54.8-3.4-17.3-.3-34.6-.2-51.9.3-23.3.7-46.5 2.3-69.7 4.6-22.2 2.2-44.4 4.1-66.6 6.3-20.9 2.1-41.7 4.5-62.6 6.5-17.2 1.6-34.4 3.9-51.7 4.6-7.6.3-15.3 1.1-22.9.9-5.5-.1-6.9-2.2-4.1-6.8 8.2-13.6 10.3-28.8 11.3-44.1 2.4-36.9-2-73.4-7.4-109.9-1.8-12.2-4-24.3-6.1-36.5-1.5-9-.1-10.6 8.9-10.8 71.5-1.6 141.6-11.7 209.4-35.4 52.9-18.5 101.5-44.6 144.6-80.6 2.4-2 4.8-4.2 7.2-6.2 4.8-3.8 6.4-3.9 10.4.8 6 7 11.6 14.2 17.2 21.4 29.8 38.3 57.7 77.9 82.7 119.5 23.5 39.1 44.6 79.3 58.4 123 6.1 18.9 10.8 44.5 10.7 62.3z',
            },
            {
              name: BODYPARTS.Right_Sole_RL,
              path:
                'M809.1 1432c-27.5 1-53.3 2.8-79.2 5.5-56.5 6-112.2 17.4-168.5 25-13.8 1.9-27.8 3-41.8 3.6-17.5.7-34.9.8-52.4-.1-43.8-2.2-86.4-9-126.1-29.1-16.7-8.5-32.7-18-44.7-32.8-8.8-10.8-14.1-23.3-17-36.8-1.5-7.2 1-9.4 8.1-7.4 28.6 8.1 57.5 14.4 86.9 18.6 23.9 3.4 48 5.7 72.2 4.5 27.1-1.4 54.1-3.4 81.1-6.5 27.6-3.1 55.3-5.7 82.9-8.4 24.2-2.3 48.4-4.7 72.6-6.7 13.6-1.1 27.2-1.6 40.9-2.2 9.1-.4 18.3-.9 27.4-.9 73.2-.7 144.7 10.3 214.5 32.1 45.6 14.2 91.4 28 137.3 41.5.5.1 1 .3 1.4.4 9.2 2.9 9.4 2.9 9.2 12.4-.3 14.4 1.5 28.6 5.9 42.4 2.7 8.4.2 11.2-8.7 9.8-19.1-3-37.9-7.7-56.4-13.2-55.1-16.4-110.2-32.7-166.7-43.6-21.6-4.2-43.4-6.9-65.5-6.9-5-.3-9.9-1.6-13.4-1.2z',
            },
            {
              name: BODYPARTS.Right_Lateral_Ankle_RL,
              path:
                'M379.2 761.9c28.1 0 56.3.4 84.4-.1 26-.5 51.9-1.5 77.8-3 36.7-2.1 73.4-4.3 110-8.7 11.4-1.4 22.8-2.6 34.2-3.9 1.7-.2 3.3-.2 5-.2 4.4-.2 6.3 2 6.4 6.3.1 8.8.3 17.6.8 26.5 2.3 42.2 11.3 82.7 27 121.9 17.8 44.2 42.8 83.8 75 119 6.3 6.9 13 13.3 19.8 19.7 4.2 4 4.3 5.5 0 9.4-19.1 17.7-40.1 33-62.4 46.6-51.4 31.2-107.1 51.3-165.7 63.7-31.5 6.6-63.3 11.1-95.4 13.1-14.5.9-28.9 2-43.4 1.8-7.6-.1-8.2-.6-9.7-7.7-6.4-31.9-13.6-63.5-21.9-95-12.9-49.2-27.2-97.9-45.6-145.4-4.3-11.2-5.1-23.4-7.4-35.2-7.9-39.7-16.4-79.3-25.3-118.8-1.7-7.5-.5-8.9 7.2-9h29c.2-.3.2-.6.2-1z',
            },
          ],
          path:
            'M779.6 1935.8c23.8-.5 47.1 2.9 70.3 8 5.2 1.1 7.8 3.5 8.5 9.2 2.1 18 4.8 36 11.8 53 8.4 20.6 23.3 36.4 39.1 51.3 20.1 19 41 37.1 63.3 53.4 12.8 9.4 26.3 17.6 38.8 27.4 9.9 7.9 19.1 16.7 29.9 23.3 12.2 7.5 25 14 38.2 19.6 7.7 3.2 16.1 4 24.1 2.6 16.3-2.9 30.7 1.9 44.3 10 6.4 3.8 12.7 6.9 20.4 6.6 2.9-.1 5.9 1.2 8.9 1.2 10.3.1 15.1 7 19 14.9 3.8 7.9.5 19.5-6.5 24.7-7 5.2-15.1 7.1-23.4 7.8-8.6.7-17.4 1-25.8-1.8-10.6-3.5-21.3-2.3-31.6.2-22 5.4-43.9 4.7-65.9.2-4.3-.9-8.4-1.3-12.8-.5-20.6 3.8-40-1.7-59.4-7.5-21.1-6.3-42.2-12.4-64-15.7-18.7-2.8-37.2-1.3-55.8.7-29.7 3.2-58.7 10.2-88.2 14.9-22.5 3.6-43.6-.1-63.4-11.9-15.8-9.4-22.4-23.5-23.6-41-2.3-32.4.2-64.6 6.8-96.3 6.5-31.3 8.9-62.9 8.3-94.8-.2-12.8 0-25.7-.1-38.5 0-3.7 1.4-5.8 4.9-7.1 18.9-6.9 38.3-11.2 58.3-13.1 8.6-.6 17.1-.7 25.6-.8z',
        },
        {
          name: BODYPARTS.Lateral_Lower_Leg_RL,
          path:
            'M852.1 1844c-.3 35 1.8 69.9 5.6 104.7.6 5.1-.4 6.7-5.6 6.3-11.8-.8-23.5-2-35.3-3.2-17.9-1.7-35.8-3.6-53.8-3.7-2.3 0-5.7 1.2-6.4-2.1-.6-3.1.6-6.5 3.7-8.1 2.6-1.3 3-2.9 2.8-5.5-1.2-23.9-2-47.9-3.4-71.8-2-34.9-4.4-69.8-6.7-104.6-1.7-24.9-3.2-49.8-5.3-74.7-2.6-30.8-5.1-61.7-8.6-92.4-3.6-32.3-3.9-64.8-2.5-97.3 1.7-38.6 5.8-77 11.3-115.2 6.1-42.4 13.9-84.4 23.4-126.2 1.8-8.1 2.2-8.4 10.5-6.4 17.5 4.2 35.3 4.9 53.1 4.2 4.8-.2 7.1 1 7.5 6.4 1.5 20.2 5.2 39.9 13.1 58.8 6 14.4 13.8 27.8 23.9 39.7 2.9 3.4 3.7 6.9 3.3 11.5-1.5 17.2-2.2 34.5-3.6 51.8-3.8 50.4-4.1 101.1-11.6 151.3-4.2 28-6.5 56.2-7.6 84.5-1.3 34.6-3.2 69.2-5 103.8-1.6 29.3-3 58.7-2.8 88.2z',
        },
        {
          name: BODYPARTS.Knee_RL,
          path:
            'M840.9 1241.6c1.1-40.2 8-77.5 24-112.7 5.5-12 15-19.3 27.6-22.6 10.8-2.8 21.6-5.8 32.1-9.4 5.5-1.9 10.9-4 16-7 4.8-2.8 7.5-1.8 9.9 3.3 1.6 3.6 2 7.4 1.6 11.3-1.7 16.7-.9 33.6-3 50.3-3.4 26.4-5.1 53.1-15.8 78-4.3 10.1-8.5 20.4-10.5 31.2-4.5 24.5-13.7 46.9-28.2 67.2-4.4 6.1-7.4 13-9.9 20.1-1.8 4.9-3.3 5.2-6.6 1.1-9.2-11.3-16.8-23.6-22.5-37-8.3-19.9-12.9-40.5-13.9-62.1-.1-4.4-.6-8.9-.8-11.7z',
        },
        {
          name: BODYPARTS.Calve_RL,
          path:
            'M610.8 1486.2c-1.9-34.5 7.3-66.7 20.2-98.1 18-43.9 36.6-87.6 53.7-131.9 2-5.1 4.3-10.1 6.3-15.2 2.7-6.6 6.3-12.2 12.7-16 4.7-2.8 8.7-6.7 12.8-10.3 2.6-2.3 4.8-2.5 7.4-.1 12.6 11.6 27.5 19.3 43.4 25.1 5.8 2.1 6.3 3 4.9 9.4-7.1 31.4-13.4 62.9-18.5 94.6-5.3 32.2-9.8 64.5-12.8 97-2.3 25.2-3.8 50.4-4.2 75.8-.4 26.7 1.1 53.2 4 79.7 2.6 23.5 4.4 47.1 6.4 70.6 2.4 28.7 4.7 57.4 6.7 86.1 1.7 23.6 2.9 47.2 4.3 70.8 1.6 26.4 3.2 52.8 4.7 79.2.6 9.8.8 19.6 1.2 29.4.2 3.6-1.3 5.4-4.8 6.4-21.1 5.5-41.9 12.3-62.2 20.2-4.3 1.7-5.8.6-6-4.2-.4-8.6-.7-17.3-.8-26-1.1-54.7-8.6-108.5-19.7-162-8.1-39.1-15.6-78.5-26.7-116.9-5.5-19.1-13.6-37.3-18.9-56.4-7.2-25.8-10.8-52.1-13.1-78.7-.9-9.6-.7-19.1-1-28.5z',
        },
        {
          name: BODYPARTS.Lateral_Knee_RL,
          path:
            'M822.9 1249c-34.6-.4-67-8.2-94.9-29.9-8.5-6.6-8.4-7-1.1-14.7 25.6-26.9 46.1-57.3 63.6-89.8 7.2-13.3 13.8-27 19.7-40.9 2.9-6.7 4.3-6.9 8.9-1.1 12.9 16.3 29.5 27.6 48.3 36.2 6.9 3.2 6.9 3.3 2.7 9.5-10.7 16-15.1 34.3-19 52.8-4.8 22.8-7.6 45.9-9.2 69.2-.5 7.3-1.7 8.6-9.1 8.8-3.2 0-6.5-.1-9.9-.1z',
        },
        {
          name: BODYPARTS.Politea_RL,
          path:
            'M696 1196.6c-.3-19.8 2.8-38 5.9-56.2 3.4-20 4.7-40.3 8.9-60.2 1.9-8.8 1.8-9 10.8-9.5 18.3-1 36.5-1.8 54.8-2.9 9.8-.6 19.5-2 29.3-2.8 7.7-.7 8.5.4 5.6 7.5-9.9 23.9-21.7 46.8-35.4 68.7-15.7 25.2-33.4 48.9-54.6 69.9-5.4 5.4-11.1 10.6-17.5 14.8-4.7 3.1-6.6 2.2-6.6-3.5-.1-9-1.9-18-1.2-25.8z',
        },
        {
          name: BODYPARTS.Lateral_Hamstrings_RL,
          path:
            'M882.1 1084.8c-.3 5.2.7 12.4-.7 19.5-1.1 5.6-2.8 6.6-8.4 5.2-8.2-2.1-15.4-6.4-22.3-11-13.2-8.8-25.3-19-36-30.8-2.5-2.7-5.1-2.7-8.1-2.2-28.4 3.9-57 4.8-85.7 5.6-6.8.2-7.6-.7-6.2-7.1 5.4-25.2 3.7-50.4.7-75.6-7.4-63.7-18.5-126.9-26.8-190.5-4.2-32.2-9.3-64.3-13.2-96.5-4.3-35.2-9.5-70.3-14.3-105.4-.5-3.8-1.1-7.6-1-11.4.5-17.4-6.9-31.6-17.2-44.8l-1.8-2.4c-2.8-3.4-2.1-6 1.9-7.9 16.3-7.8 31.6-17.1 46.3-27.7 4-2.9 6.2-1 8.5 2.3 20.1 28.7 38.7 58.3 56.1 88.7 24.4 42.6 46.3 86.4 64.8 131.8 22.2 54.3 39.6 110.1 48 168.3 3.4 23.7 5.4 47.6 7.4 71.5 1.9 21.9 4.1 43.7 5.6 65.7 1.3 17.4 2.7 35.1 2.4 54.7z',
        },
        {
          name: BODYPARTS.Lateral_Upper_Leg_RL,
          path:
            'M818.4 210s-48.1 241.8-125.5 287.6c0 0 82.1 108 131.9 244.5 52.5 143.8 50.8 264.1 56.2 366.2 0 0 45.3-15.1 62-21.1 9.3-3.3 9.1 12.1 9.1 12.1s54.9-152.6 76.9-341.7c12.4-106.3-.3-258.6-8.9-328-6.6-53.4-21.7-110.1-24.4-141.7-5-59.3-1.4-64.6-1.4-64.6l-11.1-12.1s-3.1 230.4-26.1 246.4c-4.2 2.9-67.9-102.3-88.2-138.7-14.5-25.7-50.5-108.9-50.5-108.9z',
        },
      ],
      path:
        'M1054.8 2334.9c-.8-5.7-7.1-11.5-13.1-12.2-2.1-.2-4.3-.8-6.4-.6-4.8.3-8.5-1.9-12.3-4.4-9-6.1-18.5-9.6-30-8.4-6.6.7-14-1.1-20.2-3.7-13.1-5.5-24.9-13.3-35.5-22.9-5.1-4.5-10.8-8.4-16.4-12.2-22.6-15.2-43.2-32.8-62.3-52.1-10.8-10.9-17.9-23.9-21-39-4.9-23.8-7.3-47.9-6.9-72 1-50.4 2.7-100.9 5.3-151.2 1.3-24.9 5.5-49.6 7.4-74.5 2.5-32.2 4.2-64.5 5.9-96.7.6-12.3 4.3-23.3 10.9-33.7 5.1-8.1 9.3-16.9 12.6-25.9 4.8-13.2 8.4-27 12.7-40.4 6.4-19.9 7.5-40.5 9.1-61.1.4-5.8.1-11.8 1.7-17.3 8.2-28.5 18-56.5 25-85.3 7.9-32.6 14-65.8 19.7-98.9 7-39.9 10.9-80.2 9.8-120.8-1.6-23.9-1.6-47.3-3.4-71.2-40.5-12.4-82.6-21.2-124.6-15-47.3 7-111.3 28.4-118.3 38.5-.1-.1-.1-.2-.2-.3-.6 2.8-.6 5.9 0 9.5 4.4 25.9 6.8 52.1 10.2 78.1 3.7 28.2 7.5 56.4 11.4 84.5 4.7 33.8 9.8 67.5 14.4 101.2 2.2 16 3.8 32.1 1.3 48.2-2.6 16.6-5.5 33.1-8.3 49.7-3.3 19.2-6 38.5-4.1 58.1.2 2.5-.4 5.4-1.3 7.8-11.8 29.1-24.1 58-35.7 87.2-6 15.3-12.5 30.6-16 46.5-7.2 32.7-3.1 65.4 5.5 97.3 7.6 28.3 18.6 55.7 23.8 84.8 5.1 28.8 11.8 57.4 15.3 86.4 3.3 28 3.7 56.4 4.6 84.7.8 24.5-.3 48.9-5.7 73-4 17.8-5 35.9-4.6 54.1.5 20.5 6.4 28.4 26 34.4 6.9 2.1 14.3 3.1 21.5 3.9 19.3 1.9 38.1-2.7 57-5.5 18.3-2.7 36.8-4.6 55.1-.2 12.8 3 25.4 6.5 38 10.2 10.6 3.1 21.3 5.1 32.4 3.7 3.4-.4 6.9-.3 10.2.3 13.9 2.3 27.7 2.9 41.6-.4 6.9-1.6 13.9-2.2 21.1-.2 9.1 2.6 18.5 2.2 27.4-1.4 7.1-3.5 10.3-8.4 9.4-14.6z',
    },
    {
      name: BODYPARTS.Abdomen_R,
      viewBox: '0 0 573.1 400.3',
      background: imgTorsoMidSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Right_Lower_Flank_R,
          path:
            'M206.1 192.5c6.9.1 13.8.2 20.7.5 6.1.2 12.2.5 18.3 1 10.4.7 20.8 1.6 31.1 2.8 23.2 2.6 46.2 6.5 68.8 12.5 1 .3 2 .6 3 .8.8.2 1.1.7 1 1.6-2.8 16.8-6.4 33.5-13.2 49.2-1.1 2.5-2.4 4.9-3.7 7.2-.4.7-.4 1.1.5 1.2.8.1 1.6.3 2.4.2 3.8-.1 7 1.7 10.4 3.1 10.1 4.3 19.9 9.3 29.1 15.2 5.2 3.4 10.1 7.1 14.6 11.3.8.8 1.6 1.7 2.3 2.6.7.7.5 1.6.4 2.4-.4 5.8-.8 11.6-1.2 17.3-.5 6.8-1 13.6-1.4 20.4 0 .5 0 1.1-.6 1.3-.6.2-.9-.3-1.2-.8-4.4-5.7-9.2-11-14.5-16-7.8-7.4-16.3-13.6-25.6-18.8-15-8.4-31.1-13.6-48-16.5-6-1-12-1.6-18.1-2.1-6.8-.5-13.6-.6-20.4-.4-7 .2-13.9.8-20.9 1.6-13.3 1.4-26.2 4.7-38.9 8.9-6.7 2.2-13.2 4.8-19.4 8.2-.2.1-.4.2-.7.3-.3.1-.6.1-.8-.1-.2-.3-.1-.5.1-.8.4-.5.7-1.1 1.1-1.6 4.8-6.5 9.1-13.3 12-20.8 1.7-4.4 2.9-8.9 2.9-13.6 0-2.3-.7-4.6-1-6.9-.6-4.1-.6-8.2-1-12.3-.4-5 .9-9.6 2.7-14.2 2.1-5.6 4.3-11.2 5.8-17 1.8-7.2 1.6-14.4.4-21.6-.2-1.4-.5-2.7-.8-4.1-.2-1.5.1-2 1.7-2.1.7.1 1.4.1 2.1.1z',
        },
        {
          name: BODYPARTS.Right_Upper_Flank_R,
          path:
            'M357.7 117.6c-.2 6.9-.5 13.8-1 20.7-.3 4.4-.5 8.8-.8 13.2-.3 4.5-.7 9.1-1.1 13.6-.4 4.5-.8 8.9-1.3 13.4-.7 7-1.6 14-2.5 20.9-.4 3-.9 6-1.3 9-.2 1.6-.6 2-2.2 1.5-9.5-2.5-19.1-4.8-28.7-6.7-12.2-2.4-24.6-4.3-37-5.8-7.8-.9-15.6-1.7-23.4-2.4-5.7-.5-11.3-.9-17-1.2-7.7-.5-15.4-.7-23.1-1-4.9-.2-9.8-.1-14.8-.2-1.3 0-1.9-.5-2.1-1.7-1.1-5-2.3-10-3.5-14.9-.2-1 0-1.7.8-2.5 2.5-2.5 5-4.9 7.5-7.4 10.5-10.7 20-22.3 28.7-34.4 6.9-9.7 13.3-19.7 19.2-30C263 86.1 270.8 70 277 53.1c.2-.6.3-1.6 1.1-1.7.8-.1 1 .8 1.4 1.4 3.4 4.9 7.1 9.4 11.4 13.6 7.4 7.2 15.8 13.2 24.9 18.2 11.5 6.3 23.7 11 36.5 14.1 1.2.3 2.4.6 3.6.8.8.2 1.2.6 1.3 1.5.1 2 .3 3.9.2 5.9v10.7h.3z',
        },
        {
          name: BODYPARTS.Right_Middle_Abdomen_R,
          path:
            'M353 270.5c-6.7-.2-13.1-.4-19.4-1-1.4-.1-1.8-.7-1.1-1.9 7.7-14 11.4-29.2 14.5-44.6 2.3-11.5 3.9-23.1 5.3-34.7 1-8.3 1.8-16.6 2.5-24.9.5-6 .9-12 1.3-18 .6-8 1-15.9 1.2-23.9.2-5 .4-9.9.5-14.9 0-1.4.1-2.9.2-4.3.1-1.3.5-1.6 1.8-1.2 2.6.8 5.2 1.7 7.7 2.6 8.8 2.8 17.8 4.6 27 5.5 4.3.4 8.5.7 12.8.7 3.4-.1 6.8-.2 10.2-.6.3 0 .6 0 1-.1 1.1-.1 1.6.4 1.5 1.5-.1 2-.1 4.1-.3 6.1-.4 5.1-.9 10.2-1.6 15.3-.8 6.4-2.3 12.7-3.8 18.9-3.4 14.7-6.3 29.5-8.4 44.4-1.1 8.2-2.1 16.4-2.6 24.7-.5 6.6-.8 13.2-.7 19.9.1 4.2.2 8.3.6 12.4.2 2.3-.1 4.5-.5 6.8-.1.7-.6 1.2-1.2 1.5-4.2 2.8-8.9 4.6-13.7 5.9-6.6 1.9-13.4 2.8-20.2 3.4-4.9.2-9.9.3-14.6.5z',
        },
      ],
      path:
        'M958.7 1050.1c-11.2-9-21.1-17.8-31.9-25.6-26-18.8-55-30.4-86.7-34.9-43.3-6.2-84.9 1.2-125.2 17.1-1.9.8-3.9 1.5-6.6 2.6 4-15.8 7.4-31.1 11.6-46.1 3.4-12 7.1-23.9 11.8-35.4 6-14.7 6.5-29.3 2.4-44.4-1.8-6.7-3.4-13.5-4.5-20.4-.3-1.9.9-4.9 2.4-6.2 20.4-16.2 38.5-34.7 55.4-54.5 13-15.2 29.5-24.1 49.5-26.8 28.2-3.8 54.3 3.6 79.1 15.7 18.5 9 36 20.1 53.9 30.4 1.5.8 2.9 3.2 2.9 4.9-.3 15.3-1 30.6-1.4 45.9-.6 23.3-2.2 46.5-10.8 68.6-.9 2.3-1 5.4-.3 7.7 5.8 17.7 5.4 35.8 3.8 53.9-1.3 15.7-3.5 31.4-5.4 47.5z',
    },
    {
      name: BODYPARTS.Torso_R,
      viewBox: '0 0 573.1 400.3',
      background: imgTorsoTopSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Right_Lower_Chest_R,
          path:
            'M379.9 255.6c-3.3.2-7.1-.2-10.8-.5-.8-.1-1.5-.3-2.1-.8-6-3.8-11.7-8.1-17.3-12.5-8.2-6.6-16-13.7-23.2-21.3-9.7-10.2-18.3-21.3-24.9-33.8-4.5-8.3-7.9-17.1-9.8-26.3-.5-2.4-.1-2.9 2.3-3.4 8.7-1.8 17.2-4.4 25.2-8.4 3.8-1.9 7.4-4.3 10.8-6.9 1.4-1.1 2.1-.9 2.8.7 2.6 6 6.3 11.3 10.8 16.1 10.3 11 23.2 17.8 37.4 22.2 8.9 2.8 18 4.3 27.3 5.3 3.9.4 7.7.6 11.6.8 1.3.1 2 .6 2.3 1.8.8 3.7 1.4 7.5 1.7 11.3.7 8.4.2 16.7-1.9 24.9-.6 2.4-1.4 4.7-2.3 7.1-.3.9-.4 1.8-.3 2.7.2 2.2.2 4.3.4 6.5.1 1-.3 1.7-1 2.4-4.5 4.6-10.1 7.5-16.2 9.4-7.4 2-14.8 2.9-22.8 2.7z',
        },
        {
          name: BODYPARTS.Right_Shoulder_Blade_R,
          path:
            'M217.9 251.1c.4 9.9-3 19.4-8.5 28.3-3.1 5-6.5 9.9-10.1 14.6-.4.5-.7 1-1.4.9s-.9-.8-1-1.3c-1.8-5.4-3.4-10.8-4.8-16.3-2-7.8-3.8-15.6-6.1-23.3-2.8-9.5-5.8-19-8.7-28.6-1.4-4.6-2.8-9.3-4.2-13.9-.3-1-.7-1.8-1.3-2.7-5.2-6.9-9.3-14.4-11.9-22.8-1.9-6.4-3.2-13-4.4-19.5-.7-3.9-1.5-7.8-2-11.8-.6-5.9-.8-11.8-.2-17.7.7-7.6 2.4-15 5.3-22.1 2.2-5.2 4.9-10.2 8.3-14.7.4-.5.7-1.2 1.5-1s.9 1 1 1.6c1.3 6.8 3.4 13.3 6.5 19.5 4.8 9.6 11.5 17.6 20.1 24.1 1.4 1 1.5 1.4.9 3.1-2.6 7.6-3 15.5-2.3 23.5 1.2 16 6.1 31 12.4 45.6 2.8 6.5 5.8 12.8 8.3 19.4 1.7 4.6 2.8 9.4 2.6 15.1z',
        },
        {
          name: BODYPARTS.Front_Right_Ribcage_R,
          path:
            'M265.2 199.2c0-12.3 1-24.4 3.1-36.5.4-2.1.5-2.3 2.5-2.5 4.6-.5 9.3-.9 13.9-1.6 1.5-.2 3.1-.5 4.6-.7s2 .2 2.3 1.7c3.6 17.4 11.9 32.6 22.6 46.5 3.7 4.8 7.6 9.3 11.7 13.7 11.5 12.3 24.5 23.1 38.3 32.7 1 .7 2 1.4 2.9 2.2 1.2 1 1.3 1.3.4 2.6-2.8 4.1-4.7 8.5-6.3 13.2-2.2 6.5-3.7 13.3-4.8 20-1.2 7.4-2 14.9-2.5 22.4-.3 4.3-.4 8.7-.7 13-.1 1.8-.9 2.4-2.7 1.8-4.9-1.6-9.3-4-13.7-6.6-9.7-5.8-18.5-12.7-27.1-20-8.4-7.2-16.2-15-24-22.8-2.4-2.5-4-5.5-5.5-8.5-6.6-13.1-10.8-26.9-13-41.4-1-6.6-1.7-13.2-2-19.9 0-3.1.1-6.2 0-9.3z',
        },
        {
          name: BODYPARTS.Right_Upper_Chest_R,
          path:
            'M418.7 186.7c-16.7-.7-32.7-3.2-47.9-9.7-8.5-3.6-16.4-8.3-23.2-14.6-6.3-5.7-11.4-12.2-14.7-20-.8-1.9-1-2.1.8-3.6 3.1-2.6 5.6-5.8 7.4-9.4.8-1.5 1.8-2.2 3.4-1.9 1.4.2 2.8.1 4.1.9.9.5 1.5 1.1 1.9 2 .5 1.3 1.3 2.6 2.3 3.6.9 1 1.7 2.2 2.7 3.2.9 1.1 2 2.1 3.4 2.6.9.4 1.7.9 2.5 1.5 1.5 1.2 3.2 2 5.1 2.7 3.1 1.1 4.5.3 6.2-1.7 1.1-1.3 2.4-2.3 3.8-3.2 1.2-.7 1.5-1.5 1.2-2.8-.5-2.6-1-5.3-2.1-7.7-1-2.3-1.6-4.6-1.6-7.1 0-2.8-1.5-4.9-3.2-6.9-2-2.3-4.5-3.9-7.2-5.3-3.3-1.7-6.2-3.9-8.4-7s-5.1-5.7-7.9-8.4c-.8-.8-1.2-1.6-1.4-2.7-1.7-9.7-5-19-9.2-28-1.4-2.9-2.1-6.1-3.5-8.9-1-2-2-4-2.9-6-.3-.6-.8-1.2-.2-1.9.6-.7 1.4-.4 2.1-.1 4.2 1.5 8.4 3.1 12.6 4.6 6.4 2.3 12.8 4.4 19.3 6.1 1.7.4 2.9 1.2 3.7 2.9 1.7 3.4 3.7 6.7 5.5 10.1 2.5 4.5 4.5 9.2 5.7 14.2 1.7 7.1 4.7 13.7 8.1 20.2 3.1 6 5.9 12.2 8.3 18.6 1.5 4.2 3.9 8.1 5.9 12 4 7.7 7.9 15.4 11.3 23.4 3.5 8.1 6.5 16.3 8.6 24.9.1.3.2.6.2.9.3 1.5-.3 2.3-1.9 2.3-.4.2-.8.2-.8.2z',
        },
        {
          name: BODYPARTS.Upper_Right_Flank_R,
          path:
            'M198.5 297.7c-.1-1.7.4-3.2 1.5-4.6 4.9-6.6 9.6-13.4 13.3-20.8 2.7-5.5 4.1-11.3 4.5-17.4.3-4.4.4-8.7-.7-13-1.1-4.6-2.9-9.1-4.8-13.4-4.2-9.7-8.7-19.2-11.9-29.3-2.7-8.6-4.8-17.3-5.7-26.3-.7-7.3-.6-14.6 1.2-21.8.2-.7.4-1.3.7-1.9.6-1.3 1.9-1.8 3.3-1.5 1.3.3 2.3 1.1 3.4 1.7 9.7 5.4 20.1 8.5 30.9 10.2 5.9.9 11.9 1.3 17.9 1.5 3.9.2 7.7-.2 11.6-.1 3 .1 4.2 1.1 4 4.2-.3 3.4-.9 6.8-1.3 10.2-.5 4.4-.9 8.9-1.1 13.4-.3 5-.3 9.9-.2 14.9.2 12.2 1.6 24.2 4.3 36.1 2.4 10.5 5.9 20.6 10.8 30.2 1 2 2.1 4 3.3 5.8 1.3 2 1.4 4.1.7 6.3-1.8 5.4-4 10.7-6.3 16-2.6 5.8-5.3 11.5-8.3 17.2-6.6 12.3-14.2 23.9-22.8 34.9-5.9 7.5-12.5 14.4-19.4 21-4.2 4-8.6 7.7-13.2 11.1-2.3 1.7-4.2.9-4.7-1.8-.7-3.6-1-7.2-1.4-10.8-.9-7.5-1.5-15.1-2.6-22.6-1.3-9.1-2.4-18.3-3.7-27.4-.9-6.3-1.5-12.7-3.1-18.9-.2-1.3-.3-2.2-.2-3.1z',
        },
      ],
      path:
        'M728.7 859.4c-2-16.5-4.2-32.3-5.9-48.1-2.9-27-7.9-53.5-16-79.4-7.2-22.9-13.4-46.2-20.1-69.3-.5-1.9-1.2-3.9-2.3-5.5-13.4-18-19.4-38.9-22.4-60.6-1.9-13.5-2.8-27.2-3-40.8-.1-9.9 1.6-19.8 2.7-29.7.8-7.8 1.2-15.7 3.1-23.2 2.8-11.4 6-22.8 10.5-33.6 6.4-15.3 14-30.1 21.3-45 1.1-2.3 3.4-4.7 5.7-5.7 17.4-7.7 35.5-11.9 54.7-10.7 32.8 2 64.9 7.7 95.6 19.9 5.5 2.2 11 4.6 16.4 7.1 9.4 4.3 17.1 10.2 24.2 18.3 17.3 19.6 29 41.8 36.5 66.6 1 3.3 2.6 6.4 4.1 9.6 13.1 28.7 27.1 57 39 86.2 9 22 10.8 45.2 2.4 68.3-.5 1.5-.7 3.3-.5 4.9 1.5 14.7-.3 29.2-1.6 43.8-1 11.6.5 23.5 1.8 35.2 2 18.5 1.5 36.8-1.5 55.9-4-2.5-7.7-4.8-11.4-7.1-23.9-14.7-48.1-28.7-75.4-36.3-21.4-6-42.9-8.1-64.6-.9-16.1 5.3-28.1 15.8-38.7 28.5-15.3 18.1-32.3 34.5-50.9 49.3-1 .6-2 1.2-3.7 2.3z',
    },
    {
      name: BODYPARTS.Hip_Region_R,
      viewBox: '0 0 573.1 400.3',
      background: imgTorsoLowSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Right_Gluteal_BR,
          path:
            'M107 241.1c.1-10.1.6-20.1 1.9-30.1 2-16 5.4-31.8 10.1-47.2 3.6-11.6 7.8-22.9 12.7-34 7.1-16.3 15.5-32 25.7-46.7.7-1 1.3-1.9 1.5-3.1.2-1 1-1.6 1.8-2.1 9.4-5.2 19.1-9.6 29.2-13.4 12.5-4.7 25.4-8.3 38.5-10.9 8.8-1.7 17.6-3 26.6-3.8 5.2-.4 10.4-.7 15.5-1 4.9-.2 9.8-.3 14.6-.1 5.9.2 11.8.3 17.7 1.1 1.1.1 1.8.5 1.9 1.7 0 .1 0 .3.1.3 1.5 1 .9 2.3.6 3.5-3.8 14.8-7.8 29.6-11.9 44.4-4 14.3-8 28.5-12.3 42.7-3 10.2-6.1 20.4-9.3 30.5-4.3 13.9-8.7 27.7-13.4 41.4-4.9 14.2-9.7 28.5-15.4 42.4-6 14.7-12.8 29.1-21.1 42.6-5.2 8.4-10.9 16.4-17.5 23.7-7.7 8.6-16.2 16.1-26.1 22.1-8.5 5.2-17.6 9.1-27.3 11.6-2.2.6-4.4 1.1-6.6 1.6-1.5.3-2.5-.1-3.4-1.2-2.3-2.9-4.5-6-6.5-9.1-5.9-9-10.5-18.6-14.3-28.6-2.3-6-4-12.2-5.7-18.5-2.7-10.1-4.6-20.4-5.8-30.9-.6-5-1.1-10.1-1.3-15.2-.2-4.6-.5-9.1-.5-13.7z',
        },
      ],
      path:
        'M958.7 1050.1c-48.4-43.4-90.7-61.8-146.7-62.5-34.7-.4-67.9 7.1-99.8 20.5-2.1.9-4.3 2.7-5.5 4.6-28.9 45.2-44.7 94-40.1 148.3 1.9 22.4 6.8 44.1 18.2 63.8 5.7 9.9 12.7 19.1 19.2 28.5.1.1.1.2.2.3 7-10.1 71-31.4 118.3-38.5 42-6.2 82.5 2.1 123 14.5.5.2 1.1.3 1.6.5-1.4-17.4-3.1-34.1-4-50.9-.4-6.2.5-12.5 1.3-18.7.8-6.8 2.8-13.4 3.2-20.2 2-28.9 5-61.7 11.1-90.2z',
    },
  ],
};
