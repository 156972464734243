import React from 'react';
import classNames from 'classnames';

import styles from './Card.module.scss';

const Card = ({ className, ...rest }) => (
  <div className={classNames(styles.card, className)} {...rest} />
);

export default Card;
