import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../components/widgets/Button';
import { renderStatus } from '../../../utils/enumStrings';
import {
  renderHashedSSN,
  renderAge,
  renderGender,
  renderName,
} from '../../../utils/functions';
import { userIsDoctor } from '../../../utils/roles';
import { MIAOverviewUrl } from '../../../utils/urls';
import { STATUS, CONSULT_MODE } from '../../../constants';
import i18n from '../../../i18n';
import VerifySSNModal from '../VerifySSNModal';
import ViewModeModal from '../ViewModeModal';
import List from '../List';

import styles from './PatientList.module.scss';

const { VIEW_ONLY } = CONSULT_MODE;

const renderComplaint = (complaint) => {
  const defaultReturnValue = 'N/A';
  if (!complaint) {
    console.warn('Rendering empty complaint');
    return defaultReturnValue;
  }

  return complaint;
};

const renderTime = (miaUseTime) => {
  const defaultReturnValue = 'N/A';
  if (miaUseTime.length === 0) {
    return defaultReturnValue;
  }

  const number = parseInt(miaUseTime, 10);
  if (isNaN(number)) {
    console.warn(`Could not parse MIA use time: '${miaUseTime}'`);
    return defaultReturnValue;
  }

  const h = Math.floor(number / 3600);
  const m = Math.floor((number % 3600) / 60);
  const s = Math.floor(number % 60);

  if (h) {
    return `${h}h ${m}m ${s}s`;
  }

  if (m) {
    return `${m}m ${s}s`;
  }

  if (s) {
    return `${s}s`;
  }

  return defaultReturnValue;
};

const TABLE_COLUMNS = [
  {
    label: i18n.t('initial_complaint'),
    render: (item) => renderComplaint(item.complaintName),
  },
  {
    label: i18n.t('name'),
    render: (item) => renderName(item.firstName, item.lastName),
  },
  {
    label: i18n.t('age_gender'),
    render: (item) =>
      `${renderAge(item.birthDate)} - ${renderGender(item.gender)}`,
  },
  {
    label: i18n.t('patient_id'),
    render: (item) => (
      <span className={styles.patientId}>
        {renderHashedSSN(item.patientId)}
      </span>
    ),
  },
  {
    label: i18n.t('mia_use_time'),
    render: (item) => renderTime(item.miaUseTime),
  },
  { label: i18n.t('status'), render: (item) => renderStatus(item.status) },
];

const isRowSelectable = (item) =>
  ![STATUS.WAITING, STATUS.MIA_IN_PROCESS].includes(item.status);

const PatientList = ({ list, loading }) => {
  const history = useHistory();

  const [isActionsModalOpen, setActionsModalOpen] = useState(false);
  const [isSSNModalOpen, setSSNModalOpen] = useState(false);
  const [selection, setSelection] = useState(null);

  const modalCancel = () => setSSNModalOpen(false);
  const proceedWithSelection = () => setSSNModalOpen(true);
  const handleSSNVerified = () => {
    if (
      userIsDoctor() &&
      [
        STATUS.MIA_COMPLETE,
        STATUS.CONSULT_IN_PROCESS,
        STATUS.CONSULT_COMPLETE,
      ].includes(selection.status)
    ) {
      setSSNModalOpen(false);
      setActionsModalOpen(true);
    } else {
      openSummary();
    }
  };
  const openSummary = () => {
    history.push(MIAOverviewUrl(selection.id, VIEW_ONLY));
  };

  const handleSelectionChange = useCallback((idx) => setSelection(list[idx]), [
    list,
  ]);

  return (
    <div className={styles.summaryList}>
      <List
        items={list}
        columns={TABLE_COLUMNS}
        emptyMessage={i18n.t('emptySummaryListMessage')}
        loading={loading}
        selectable
        isSelectable={isRowSelectable}
        onSelectionChange={handleSelectionChange}
      />
      <div>
        <Button disabled={!selection} onClick={proceedWithSelection}>
          {i18n.t('access_mia_overview')}
        </Button>
      </div>
      {isSSNModalOpen && (
        <VerifySSNModal
          patientId={selection.patientId}
          onClose={modalCancel}
          onSSNVerified={handleSSNVerified}
        />
      )}
      {isActionsModalOpen && (
        <ViewModeModal
          id={selection.id}
          onProceed={(newConsultMode) => openSummary(newConsultMode)}
          onClose={modalCancel}
          patientId={selection.patientId}
          status={selection.status}
        />
      )}
    </div>
  );
};

export default PatientList;
