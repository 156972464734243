import React from 'react';

import SvgIcon from './SvgIcon';

const PatientIcon = (props) => (
  <SvgIcon viewBox="0 0 27 37" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-5 0h37v37H-5z" />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(.5 .5)">
        <path d="M0 30h26" />
        <circle cx={13} cy={18} r={4} />
        <path d="M21 30c-.847-3.509-4.168-6-8-6s-7.153 2.491-8 6" />
        <path d="M17 7h7.47c.845 0 1.53.683 1.53 1.526v25.948c0 .843-.685 1.526-1.53 1.526H1.53C.684 36 0 35.317 0 34.474V8.526C0 7.683.685 7 1.53 7H9M13 3.5a.5.5 0 110 1 .5.5 0 010-1" />
        <path d="M17 9.167c0 .46-.358.833-.8.833H9.8c-.442 0-.8-.373-.8-.833v-5C9 1.865 10.79 0 13 0s4 1.865 4 4.167z" />
      </g>
    </g>
  </SvgIcon>
);

export default PatientIcon;
