import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Card from '../../../widgets/Card';
import Label from '../../misc/Label';
import ErrorText from '../../misc/ErrorText';

import styles from './RadioButtons.module.scss';

const RadioButtonCard = ({
  label,
  name,
  value: propValue,
  onChange = () => null,
  options,
  error,
}) => {
  const [ctrl, setCtrl] = useState(typeof propValue !== 'undefined');
  const [stateValue, setStateValue] = useState('');
  useEffect(() => {
    let type = typeof propValue;
    if (ctrl && type === 'undefined') {
      console.warn(
        'RadioButtonCard - changing controlled input to uncontrolled mode',
      );
      setCtrl(false);
    } else if (!ctrl && type !== 'undefined') {
      console.warn(
        'RadioButtonCard - changing uncontrolled input to controlled mode',
      );
      setCtrl(true);
    }
  }, [propValue, ctrl]);
  const handleChange = (e) => {
    if (!e.target.checked) {
      return;
    }
    const newValue = e.target.value;
    onChange(newValue);
    setStateValue(newValue);
  };
  const finalValue = ctrl ? propValue : stateValue;
  const classes = classNames(styles.root, !!finalValue && styles.hasValue);
  return (
    <div className={classes}>
      <Label>{label}</Label>
      <div className={styles.optionsContainer}>
        {options.map(({ value, label }, i) => (
          <label key={i} className={styles.option}>
            <input
              name={name}
              type="radio"
              value={value}
              checked={value === finalValue}
              onChange={handleChange}
            />
            <Card className={styles.card}>{label}</Card>
          </label>
        ))}
      </div>
      <ErrorText>{error}</ErrorText>
    </div>
  );
};

export default RadioButtonCard;
