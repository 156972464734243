import React from 'react';

import SvgIcon from './SvgIcon';

const LocationIcon = (props) => (
  <SvgIcon viewBox="0 0 35 37" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-1 0h37v37H-1z" />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(.5 .5)">
        <circle cx={17} cy={10} r={5} />
        <path d="M17 0c5.523 0 10 4.47 10 9.983 0 4.681-7.21 16.286-9.386 19.682a.73.73 0 01-1.228 0C14.209 26.269 7 14.667 7 9.983a9.972 9.972 0 012.928-7.059A10.01 10.01 0 0117 0z" />
        <path d="M24.391 28C30.08 28.682 34 30.124 34 31.79 34 34.115 26.39 36 17 36S0 34.116 0 31.79c0-1.664 3.895-3.102 9.547-3.79" />
      </g>
    </g>
  </SvgIcon>
);

export default LocationIcon;
