import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextField from '../TextField';
import ErrorText from '../../misc/ErrorText';
import { posMod } from '../../../../utils/functions';
import { KEY } from '../../../../constants';

import styles from './AutoComplete.module.scss';

const searchCardProps = {
  className: styles.searchCard,
};
const AutoComplete = ({
  value: propValue,
  label,
  error,
  disabled,
  fullWidth,
  keepErrorSpace,
  onChange,
  onSearch,
  formatSearchResult,
}) => {
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [highlightIndex, setHighlightIndex] = useState(0);
  const [ctrl, setCtrl] = useState(typeof propValue !== 'undefined');
  const [stateValue, setStateValue] = useState(null);
  const inputRef = useRef(null);
  const resultsRef = useRef(null);
  useEffect(() => {
    let type = typeof propValue;
    if (ctrl && type === 'undefined') {
      console.warn(
        'AutoComplete - changing controlled input to uncontrolled mode',
      );
      setCtrl(false);
    } else if (!ctrl && type !== 'undefined') {
      console.warn(
        'AutoComplete - changing uncontrolled input to controlled mode',
      );
      setCtrl(true);
    }
  }, [propValue, ctrl]);
  const handleChange = (newValue) => {
    onChange(newValue);
    setStateValue(newValue);
    inputRef.current.blur();
    setSearch('');
    setHighlightIndex(0);
  };
  const handleSearchChange = (searchText) => {
    setSearch(searchText);
    setHighlightIndex(0);
    if (searchText) {
      setResultsVisible(true);
      setSearchResults(onSearch(searchText));
    } else {
      setSearchResults([]);
      setResultsVisible(false);
    }
  };
  const handleSearchFocus = () => {
    setSearching(true);
    if (search) {
      setResultsVisible(true);
    }
  };
  const handleSearchBlur = (e) => {
    // ignore blur if it's because of a click on a search result
    const newFocusTarget = e.relatedTarget;
    let ignoreBlur =
      newFocusTarget &&
      (resultsRef.current && resultsRef.current.contains(newFocusTarget));
    if (!ignoreBlur) {
      setSearching(false);
      setResultsVisible(false);
    }
  };
  const handleMouseInResult = (resultIndex) => () => {
    setHighlightIndex(resultIndex);
  };
  const handleArrowNavigation = (newHighlightIndex) => {
    let index = posMod(newHighlightIndex, searchResults.length);
    setHighlightIndex(index);
    if (resultsRef.current) {
      const selector = `.${styles.result}:nth-child(${index + 1})`;
      let target = resultsRef.current.querySelector(selector);
      target && target.scrollIntoView && target.scrollIntoView();
    }
  };
  const handleSearchKeyDown = (e) => {
    if (e.which === KEY.UP) {
      handleArrowNavigation(highlightIndex - 1);
    } else if (e.which === KEY.DOWN) {
      handleArrowNavigation(highlightIndex + 1);
    } else if (e.which === KEY.ENTER) {
      // select highlighted value
      handleSelectResult(highlightIndex);
    }
  };
  const handleClickOnResult = (resultIndex) => () => {
    handleSelectResult(resultIndex);
    setSearching(false);
    setResultsVisible(false);
  };
  const handleSelectResult = (resultIndex) => {
    handleChange(searchResults[resultIndex]);
  };
  const value = ctrl ? propValue : stateValue;
  const classes = classNames(
    styles.root,
    fullWidth && styles.fullWidth,
    resultsVisible && styles.resultsVisible,
  );
  const textFieldValue = searching
    ? search
    : value
    ? formatSearchResult(value)
    : '';
  return (
    <div className={classes}>
      <div className={styles.textFieldWrapper}>
        <TextField
          ref={inputRef}
          className={styles.textField}
          value={textFieldValue}
          label={label}
          placeholder="Search"
          cardProps={searchCardProps}
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          onKeyDown={handleSearchKeyDown}
          disableClearButton={!searching}
          fullWidth
          disabled={disabled}
        />
        {resultsVisible && (
          <div ref={resultsRef} className={styles.results}>
            {searchResults.length === 0 && (
              <div className={styles.noResult}>No results</div>
            )}
            {searchResults.map((result, idx) => {
              const hightlighted = idx === highlightIndex;
              const classes = classNames(
                styles.result,
                hightlighted && styles.highlighted,
              );
              return (
                <div
                  key={idx}
                  className={classes}
                  tabIndex="-1"
                  onMouseEnter={handleMouseInResult(idx)}
                  onClick={handleClickOnResult(idx)}>
                  {formatSearchResult(result)}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <ErrorText keepSpace={keepErrorSpace}>{error}</ErrorText>
    </div>
  );
};

AutoComplete.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  keepErrorSpace: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  formatSearchResult: PropTypes.func,
};

AutoComplete.defaultProps = {
  value: '',
  label: '',
  error: '',
  disabled: false,
  fullWidth: false,
  keepErrorSpace: false,
  onChange: () => null,
  onSearch: () => [],
  formatSearchResult: (i) => i,
};

export default AutoComplete;
