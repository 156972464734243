import React from 'react';
import classNames from 'classnames';

import styles from './Label.module.scss';

const Label = ({ className, children }) => (
  <span className={classNames(className, styles.label)}>{children}</span>
);

export default Label;
