import React, { useEffect } from 'react';

import Button from '../../components/widgets/Button';
import useFetchData from '../../hooks/useFetchData';
import { userIsAssistant } from '../../utils/roles';
import { SummaryApi } from '../../utils/api';
import { ROUTES } from '../../constants';
import i18n from '../../i18n';
import PastConsultsList from './PastConsultsList';
import PatientList from './PatientList';
import styles from './SearchPatient.module.scss';
import { useCallback } from 'react';

const SearchPatientPage = () => {
  const {
    data: activeSummaries = [],
    loading: activesLoading,
    error: activesError,
    refetch: refetchActives,
  } = useFetchData(SummaryApi.waitList);
  const {
    data: pastSummaries = [],
    loading: pastLoading,
    error: pastError,
    refetch: refetchPast,
  } = useFetchData(SummaryApi.pastConsults);

  const refreshAll = useCallback(() => {
    refetchActives();
    refetchPast();
  }, [refetchActives, refetchPast]);

  useEffect(() => {
    // Refresh every 120s
    let inter = setInterval(refreshAll, 120 * 1000);
    return () => clearInterval(inter);
  }, [refreshAll]);

  useEffect(() => {
    if (activesError) {
      console.error('Error while loading summary list', activesError);
    }
  }, [activesError]);

  useEffect(() => {
    if (pastError) {
      console.error('Error while loading summary list', pastError);
    }
  }, [pastError]);

  return (
    <div className={styles.root}>
      <div className={styles.actions}>
        <Button
          className={styles.button}
          onClick={refreshAll}
          busy={activesLoading || pastLoading}>
          {i18n.t('reload')}
        </Button>
        {userIsAssistant() && (
          <Button href={ROUTES.NEW_PATIENT} primary className={styles.button}>
            {i18n.t('new_patient')}
          </Button>
        )}
      </div>
      <PatientList list={activeSummaries} loading={activesLoading} />
      <h2>{i18n.t('past_consults')}</h2>
      {pastError && (
        <p>An error occured while loading the list of past consults</p>
      )}
      {!pastError && (
        <PastConsultsList list={pastSummaries} loading={pastLoading} />
      )}
    </div>
  );
};

export default SearchPatientPage;
