import React from 'react';
import PropTypes from 'prop-types';

import PatientIcon from '../../components/icons/Patient';
import {
  renderAge,
  renderGender,
  renderHashedSSN,
  renderHeight,
  renderWeight,
} from '../../utils/functions';

import SectionTitle from './SectionTitle';
import ValueDisplay from './ValueDisplay';

import i18n from '../../i18n';

const PatientData = ({
  className,
  data: { patientId, birthDate, height, weight, gender, complaintName },
}) => {
  return (
    <div className={className}>
      <SectionTitle>
        <PatientIcon />
        &nbsp;{i18n.t('patient_data')}
      </SectionTitle>
      <ValueDisplay label={i18n.t('patient_id')}>
        {renderHashedSSN(patientId)}
      </ValueDisplay>
      <ValueDisplay label={i18n.t('age')}>{renderAge(birthDate)}</ValueDisplay>
      <ValueDisplay label={i18n.t('gender')}>
        {renderGender(gender, true)}
      </ValueDisplay>
      <ValueDisplay label={i18n.t('initial_complaint')}>
        {complaintName}
      </ValueDisplay>
      {!!height && (
        <ValueDisplay label={i18n.t('height')}>
          {renderHeight(height)}
        </ValueDisplay>
      )}
      {!!weight && (
        <ValueDisplay label={i18n.t('weight')}>
          {renderWeight(weight)}
        </ValueDisplay>
      )}
    </div>
  );
};

PatientData.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.shape({
    patientId: PropTypes.string.isRequired,
    height: PropTypes.number,
    weight: PropTypes.number,
    birthDate: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    complaintName: PropTypes.string.isRequired,
  }).isRequired,
};

PatientData.defaultProps = {
  data: {
    height: null,
    weight: null,
  },
};

export default PatientData;
