import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import ModalContent from './ModalContent';

class Modal extends Component {
  static propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    cancel: PropTypes.bool,
    children: PropTypes.object.isRequired,
    modalTitle: PropTypes.node,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    cancel: false,
    className: null,
    modalTitle: '',
    onClose: null,
  };

  constructor(props) {
    super(props);

    this.state = { isOpen: true };

    this.buttonRef = React.createRef();
    this.modalRef = React.createRef();
  }

  onClickAway = (e) => {
    this.setState({ isOpen: false });
    this.closeModal();
  };

  closeModal = () => {
    const { onClose } = this.props;

    this.setState({ isOpen: false });
    if (onClose) {
      onClose();
    }
  };

  render() {
    const { isOpen } = this.state;
    const { ariaLabel, cancel, children, className, modalTitle } = this.props;

    return (
      <Fragment>
        {isOpen && (
          <ModalContent
            ariaLabel={ariaLabel}
            className={className}
            onClickAway={this.onClickAway}
            buttonRef={this.buttonRef}
            modalRef={this.modalRef}
            modalTitle={modalTitle}
            content={children}
            onClose={this.closeModal}
            cancel={cancel}
          />
        )}
      </Fragment>
    );
  }
}

export default Modal;
