import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Field } from 'react-final-form';
import { Redirect } from 'react-router-dom';

import SummaryIcon from '../../components/icons/Summary';
import LocationIcon from '../../components/icons/Location';
import AIResultsIcon from '../../components/icons/AIResults';
import Button from '../../components/widgets/Button';
import Card from '../../components/widgets/Card';
import Dummy from '../../components/widgets/Dummy';
import FillingContainer from '../../components/widgets/FillingContainer';
import {
  renderAutoComplete,
  renderTextareaField,
} from '../../components/form/renderers';
import { ROUTES, STATUS, CONSULT_MODE } from '../../constants';
import { SummaryApi, ICPCApi, NotFoundError } from '../../utils/api';
import { getQueryStringArgs } from '../../utils/functions';
import localSearch from '../../utils/localSearch';
import { userIsDoctor } from '../../utils/roles';

import styles from './MiaOverview.module.scss';
import PatientData from './PatientData';
import SectionTitle from './SectionTitle';
import ValueDisplay from './ValueDisplay';

import i18n from '../../i18n';

const DISPLAY_STEP = {
  INITIAL: 'initial',
  LOADING: 'loading',
  DISPLAY: 'display',
  GO_BACK_TO_SEARCH: 'goBack',
  ERROR: 'error',
};

const formatICPC = ({ name }) => name;

function validateDoctorInput({ ICPCCode }) {
  const errors = {};
  if (!ICPCCode) {
    errors.ICPCCode = i18n.t('required');
  }
  return errors;
}

const isReadOnlyMode = (consultMode, status) => {
  if (!userIsDoctor()) {
    return true;
  }
  if (status === STATUS.CONSULT_IN_PROCESS) {
    return false;
  }
  if (consultMode === CONSULT_MODE.REVIEW) {
    return false;
  }
  return true;
};

const renderNavButtons = (consultMode, status, disabled) => {
  const closeOnly = (
    <Button primary href={ROUTES.SEARCH_PATIENT}>
      {i18n.t('close_overview')}
    </Button>
  );

  const finishOrGoBack = (
    <div className={styles.buttonStack}>
      <Button primary type="submit" disabled={disabled}>
        {i18n.t('finish_consult')}
      </Button>
      <Button muted href={ROUTES.SEARCH_PATIENT}>
        {i18n.t('go_back')}
      </Button>
    </div>
  );

  const updateOrGoBack = (
    <div className={styles.buttonStack}>
      <Button primary type="submit" disabled={disabled}>
        {i18n.t('update_consult')}
      </Button>
      <Button muted href={ROUTES.SEARCH_PATIENT}>
        {i18n.t('go_back')}
      </Button>
    </div>
  );

  if (!userIsDoctor()) {
    return closeOnly;
  }

  switch (status) {
    case STATUS.MIA_COMPLETE:
      return closeOnly;
    case STATUS.CONSULT_IN_PROCESS:
      return finishOrGoBack;
    case STATUS.CONSULT_COMPLETE:
      if (consultMode === CONSULT_MODE.REVIEW) {
        return updateOrGoBack;
      }
      return closeOnly;
    default:
      return closeOnly;
  }
};

const MiaOverView = ({ match }) => {
  const { id } = match.params;

  const { mode: consultMode } = getQueryStringArgs();

  const [error, setError] = useState('');
  const [patientData, setPatientData] = useState(null);
  const [ICPCCodes, setICPCCodes] = useState(null);
  const [currentDisplayStep, setDisplayStep] = useState(DISPLAY_STEP.INITIAL);

  useEffect(() => {
    (async () => {
      setDisplayStep(DISPLAY_STEP.LOADING);
      let sumId = parseInt(id, 10);
      if (isNaN(sumId)) {
        throw new Error('Invalid Summary ID');
      }
      try {
        const response = await SummaryApi.find(sumId);
        setPatientData(response);
        setDisplayStep(DISPLAY_STEP.DISPLAY);
      } catch (err) {
        console.error('Error while loading patient data', err);
        if (err instanceof NotFoundError) {
          setError(i18n.t('summaryNotFound'));
        } else {
          setError(i18n.t('error_loading_data'));
        }
        setDisplayStep(DISPLAY_STEP.ERROR);
      }
    })();
  }, [id]);

  useEffect(() => {
    new Promise(async () => {
      const response = await ICPCApi.list();
      setICPCCodes(response);
    }).catch((err) => {
      console.error('Error while loading ICPC codes', err);
    });
  }, []);

  const handleSave = async ({ ICPCCode, freeText }) => {
    const { status } = patientData;

    if (status === STATUS.CONSULT_IN_PROCESS) {
      try {
        await SummaryApi.finishConsult(id, {
          icpc_code: ICPCCode.code,
          comment: freeText,
        });
      } catch (e) {
        console.error('Error while saving consult', e);
      }
    } else {
      try {
        await SummaryApi.updateConsult(id, {
          icpc_code: ICPCCode.code,
          comment: freeText,
        });
      } catch (e) {
        console.error('Error while updating consult', e);
      }
    }
    setDisplayStep(DISPLAY_STEP.GO_BACK_TO_SEARCH);
  };

  switch (currentDisplayStep) {
    case DISPLAY_STEP.INITIAL:
      return null;
    case DISPLAY_STEP.LOADING:
      return (
        <FillingContainer>
          <CircularProgress />
        </FillingContainer>
      );
    case DISPLAY_STEP.DISPLAY:
      const {
        summary,
        status,
        freeText,
        ICPCCode,
        dummySelection,
        otherComplaints,
        helpingQuestion,
      } = patientData;

      const readOnlyForm = isReadOnlyMode(consultMode, status);

      const fullText = [
        i18n.t('general_anamnesis'),
        summary || 'N/A',
        i18n.t('other_complaints'),
        otherComplaints || 'N/A',
        i18n.t('helping_question'),
        helpingQuestion || 'N/A',
      ].join('\n');

      return (
        <div className={styles.root}>
          <aside>
            <PatientData data={patientData} className={styles.patientData} />
          </aside>

          <Card className={classnames(styles.summary, styles.card)}>
            <SectionTitle>
              <SummaryIcon />
              &nbsp;{i18n.t('mia_summary')}
            </SectionTitle>
            <div className={styles.vSplit}>
              <textarea
                id="fullSummary"
                className={styles.fullText}
                defaultValue={fullText}
              />
              <ValueDisplay label={i18n.t('general_anamnesis')}>
                {summary}
              </ValueDisplay>
              <div className={styles.summaryRightCol}>
                <ValueDisplay label={i18n.t('other_complaints')}>
                  {otherComplaints}
                </ValueDisplay>
                <ValueDisplay label={i18n.t('helping_question')}>
                  {helpingQuestion}
                </ValueDisplay>
                <div className={styles.spacer} />
                <Button
                  fullWidth
                  onClick={() => {
                    document.getElementById('fullSummary').select();
                    document.execCommand('copy');
                  }}>
                  {i18n.t('copy_summary')}
                </Button>
              </div>
            </div>
          </Card>

          <Card className={classnames(styles.location, styles.card)}>
            <SectionTitle>
              <LocationIcon />
              &nbsp;{i18n.t('location_of_complaint')}
            </SectionTitle>

            {dummySelection && (
              <div className={styles.dummyPositioner}>
                <div className={styles.dummyWrapper}>
                  <Dummy
                    canSelect={false}
                    canRotate={false}
                    canZoom={false}
                    selection={dummySelection}
                  />
                </div>
              </div>
            )}
          </Card>

          <Card className={classnames(styles.ai, styles.card)}>
            <SectionTitle>
              <AIResultsIcon />
              &nbsp;{i18n.t('ai_results')}
            </SectionTitle>
          </Card>

          <Form
            className={styles.consultFormRoot}
            onSubmit={handleSave}
            validate={validateDoctorInput}
            initialValues={{ freeText, ICPCCode }}>
            {(formProps) => {
              const { handleSubmit, submitting, invalid } = formProps;
              return (
                <form onSubmit={handleSubmit} className={styles.consultForm}>
                  <div className={styles.icpc}>
                    <SectionTitle>
                      <SummaryIcon />
                      &nbsp;{i18n.t('diagnosis_and_feedback')}
                    </SectionTitle>
                    <Field
                      name="ICPCCode"
                      label={i18n.t('fill_in_icpc_code')}
                      fullWidth
                      component={renderAutoComplete}
                      onSearch={localSearch(ICPCCodes, formatICPC)}
                      formatSearchResult={formatICPC}
                      disabled={!ICPCCodes || readOnlyForm}
                    />
                  </div>

                  <div className={styles.free}>
                    <Field
                      name="freeText"
                      label={i18n.t('free_text')}
                      fullWidth
                      component={renderTextareaField}
                      className={styles.freeText}
                      inputClassName={styles.freeTextInput}
                      labelWrapperClassName={styles.freeTextLabel}
                      cardProps={{ className: styles.freeTextCard }}
                      disabled={readOnlyForm}
                    />
                  </div>

                  <div className={styles.nav}>
                    {renderNavButtons(
                      consultMode,
                      status,
                      submitting || invalid,
                    )}
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      );
    case DISPLAY_STEP.GO_BACK_TO_SEARCH:
      return <Redirect to={ROUTES.SEARCH_PATIENT} />;
    case DISPLAY_STEP.ERROR:
    default:
      return <p>{error}</p>;
  }
};

MiaOverView.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MiaOverView;
