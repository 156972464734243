import React from 'react';
import MaskedInput from 'react-text-mask';

import AutoComplete from './fields/AutoComplete';
import DropDown from './fields/DropDown';
import TextField from './fields/TextField';
import RadioButtonCard from './fields/RadioButtons/RadioButtonCard';
import RadioButtonRound from './fields/RadioButtons/RadioButtonRound';

export const renderAutoComplete = ({
  input: { value, onChange },
  meta: { error, submitError },
  ...rest
}) => {
  const props = {
    value,
    onChange,
    error: error || submitError,
    ...rest,
  };
  return <AutoComplete {...props} />;
};

export const renderDropDown = ({
  input: { value, onChange },
  meta: { error, submitError },
  ...rest
}) => {
  const props = {
    value,
    onChange,
    error: error || submitError,
    ...rest,
  };
  return <DropDown {...props} />;
};

export const renderTextField = ({
  input: { value, onChange, type, name },
  meta: { error, submitError },
  ...rest
}) => {
  const props = {
    value,
    onChange,
    type,
    name,
    error: error || submitError,
    ...rest,
  };
  return <TextField {...props} />;
};

export const renderTextareaField = ({
  input: { value, onChange, type, name },
  meta: { error, submitError },
  ...rest
}) => {
  const props = {
    value,
    onChange,
    type,
    name,
    error: error || submitError,
    inputComponent: 'textarea',
    ...rest,
  };
  return <TextField {...props} />;
};

const N = /\d/;
const DATE_TEXT_MASK = [N, N, '/', N, N, '/', N, N, N, N];
const DateTextInput = (props) => (
  <MaskedInput {...props} mask={DATE_TEXT_MASK} guide={false} />
);
export const renderDateTextField = (props) =>
  renderTextField({ ...props, inputComponent: DateTextInput });

export const renderRadioButtonCards = ({
  input: { value, onChange, name },
  meta: { error, submitError },
  ...rest
}) => {
  const props = {
    value,
    onChange,
    name,
    error: error || submitError,
    ...rest,
  };
  return <RadioButtonCard {...props} />;
};

export const renderRadioButton = ({
  input: { name, value: selectedOption, onChange },
  option,
  ...rest
}) => {
  const props = {
    name,
    option,
    checked: option === selectedOption,
    onChange,
    ...rest,
  };
  return <RadioButtonRound {...props} />;
};
