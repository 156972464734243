import React from 'react';

import SvgIcon from './SvgIcon';

const AIResultsIcon = (props) => (
  <SvgIcon viewBox="0 0 38 35" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0-1h37v37H0z" />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(.5 .5)">
        <path d="M12.952 5.753c1.453-.482 2.302-1.965 1.97-3.439C14.59.841 13.183-.149 11.66.02c-1.524.167-2.673 1.44-2.666 2.949.004.266.044.531.121.787-.166-.015-.327-.049-.496-.049-2.143-.01-4.104 1.184-5.05 3.077a5.442 5.442 0 00.602 5.817C1.823 12.76 0 14.68 0 16.998c0 2.318 1.823 4.24 4.171 4.399-1.315 1.665-1.548 3.924-.602 5.817s2.907 3.087 5.05 3.077c.169 0 .33-.034.496-.05-.446 1.553.456 3.171 2.026 3.634 1.57.463 3.226-.4 3.72-1.938.496-1.538-.355-3.182-1.909-3.693M23.05 5.753c-1.452-.482-2.302-1.965-1.97-3.439S22.82-.149 24.343.02c1.523.167 2.673 1.44 2.666 2.949a2.899 2.899 0 01-.121.787c.166-.015.327-.049.496-.049 2.143-.01 4.103 1.184 5.05 3.077a5.442 5.442 0 01-.602 5.817c2.348.159 4.17 2.08 4.17 4.398 0 2.318-1.822 4.24-4.17 4.399a5.442 5.442 0 01.602 5.817c-.947 1.892-2.907 3.087-5.05 3.077-.17 0-.33-.034-.496-.05.445 1.553-.457 3.171-2.026 3.634-1.57.463-3.227-.4-3.721-1.938s.355-3.182 1.91-3.693M15 3.402a6 6 0 016 0M21 31.598a6 6 0 01-6 0" />
        <rect height={10} rx={1.5} width={10} x={13} y={12} />
        <path d="M16 8.5v3M20 8.5v3M16 22.5v3M20 22.5v3M26.5 15h-3M26.5 19h-3M12.5 15h-3M12.5 19h-3" />
      </g>
    </g>
  </SvgIcon>
);

export default AIResultsIcon;
