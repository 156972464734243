import React from 'react';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';

import svg_animation from '../../assets/MIA_animation.svg';
import medvice_logo from '../../assets/medvice-logo.svg';
import { renderTextField } from '../../components/form/renderers';
import Button from '../../components/widgets/Button';
import { LoginApi } from '../../utils/api';
import { getQueryStringArgs } from '../../utils/functions';

import styles from './Login.module.scss';

import i18n from '../../i18n';

const LoginScreen = ({ history }) => {
  const handleLogin = async ({ username, password }) => {
    if (!username || !password) {
      let errors = {};
      if (!username) {
        errors.username = 'Required';
      }
      if (!password) {
        errors.password = 'Required';
      }
      // short-circuit invalid call
      return errors;
    }
    try {
      await LoginApi.signIn(username, password);
      const { next } = getQueryStringArgs();
      history.push(next || '/');
    } catch (err) {
      return { [FORM_ERROR]: 'Login failed' };
    }
  };
  return (
    <div className={styles.root}>
      <div className={styles.mainRow}>
        <img
          className={styles.svg}
          src={svg_animation}
          alt="An animation representing a doctor"
        />
        <Form onSubmit={handleLogin}>
          {({ handleSubmit, submitError, submitting }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <h1>{i18n.t('welcome')}</h1>
              <Field
                name="username"
                label={i18n.t('username')}
                fullWidth
                component={renderTextField}
              />
              <Field
                name="password"
                label={i18n.t('password')}
                type="password"
                fullWidth
                component={renderTextField}
              />
              {!submitting && (
                <Button type="submit" fullWidth>
                  {i18n.t('log_in')}
                </Button>
              )}
              {submitting && <CircularProgress />}
              <p className={styles.submitError}>{submitError}</p>
            </form>
          )}
        </Form>
      </div>
      <div className={styles.bottomRow}>
        <img className={''} src={medvice_logo} alt="Medvice logo" />
        <p>
          Dit is een pilot applicatie in de vroege alpha fase van de minimal
          viable product
        </p>
      </div>
    </div>
  );
};

export default LoginScreen;
