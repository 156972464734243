import React, { useState, useEffect, useRef } from 'react';

import MedviceLogoIcon from '../../icons/MedviceLogo';
import HelpIcon from '../../icons/Help';
import LogoutIcon from '../../icons/Logout';
import { LoginApi } from '../../../utils/api';
import { ROUTES } from '../../../constants';

import NavMenu from './NavMenu';
import styles from './Layout.module.scss';

const HeadBtn = ({ children, ...props }) => (
  <button {...props} type="button" className={styles.iconBtn}>
    {children}
  </button>
);

const Layout = ({ children, history, }) => {
  const headerRef = useRef(null);
  const [headHeight, setHeadHeight] = useState(0);
  const handleLogout = () => {
    LoginApi.logOut();
    history.push(ROUTES.LOGIN());
  };
  useEffect(() => {
    const updateHeight = () => {
      const newHeight = headerRef.current.clientHeight;
      if (newHeight !== headHeight) {
        setHeadHeight(newHeight);
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      // tear down
      window.removeEventListener('resize', updateHeight);
    };
  }, [headHeight, setHeadHeight]);

  const mainStyle = {
    marginTop: `${headHeight}px`,
  };
  return (
    <div className={styles.root}>
      <header ref={headerRef}>
        <div className={styles.banner}>
          <div className={styles.logoWrapper}>
            <MedviceLogoIcon className={styles.logo} alt="Medvice Logo" />
          </div>
          <div className={styles.buttonsRow}>
            <HeadBtn title="Help">
              <HelpIcon />
            </HeadBtn>
            <HeadBtn title="Logout" onClick={handleLogout}>
              <LogoutIcon />
            </HeadBtn>
          </div>
        </div>
        <NavMenu className={styles.menu} />
      </header>
      <main style={mainStyle}>{children}</main>
    </div>
  );
};

export default Layout;
