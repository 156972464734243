import React from 'react';

import SvgIcon from './SvgIcon';

const SummaryIcon = (props) => (
  <SvgIcon viewBox="0 0 31 37" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-3 0h37v37H-3z" />
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M24.758 27.8c.478-.58.741-1.318.742-2.08V8.5h-20v24h13.97a3.03 3.03 0 002.336-1.12z" />
        <path d="M28.921 3.5H20.32c-.837-1.897-2.727-3-4.816-3s-3.979 1.103-4.816 3H2.08C1.207 3.5.5 4.324.5 5.189v29.745c0 .865.707 1.566 1.579 1.566H28.92c.872 0 1.579-.7 1.579-1.566V5.19c0-.865-.707-1.689-1.579-1.689zM21.5 12.5h-12M21.5 15.5h-12M21.5 18.5h-12M21.5 21.5h-12M19.5 24.5h-10M16.5 27.5h-7" />
      </g>
    </g>
  </SvgIcon>
);

export default SummaryIcon;
