import BODYPARTS from '../constants/bodyparts';

import imgSrc from '../assets/left-side.png';
import imgHeadSrc from '../assets/head-left-side.png';
import imgRightLegSrc from '../assets/leg-left-side.png';
import imgFootLegSrc from '../assets/foot-left-side.png';
import imgTorsoTopSrc from '../assets/torso-top-left-side.png';
import imgTorsoMidSrc from '../assets/torso-mid-left-side.png';
import imgTorsoLowSrc from '../assets/torso-low-left-side.png';

export default {
  name: BODYPARTS.Body_L,
  viewBox: '0 0 1668 2388',
  background: imgSrc,
  areas: [
    {
      name: BODYPARTS.Head_L,
      viewBox: '0 0 2388 1669',
      background: imgHeadSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Mastoid_L,
          path:
            'M1259.3 412.3c16.2 0 16.2 0 22.3 14.9 18.7 45.7 25.9 93.6 26.6 142.6.3 22.9-1.5 45.9-4.6 68.7-9 66.6-29.9 129.4-58.6 189.9-2.5 5.3-6.1 7.4-11.8 7.1-16.9-.8-33.9-1.4-50.9-1.9-29.8-.8-59.6-1.4-89.4-2.2-4.1-.1-8.3-.5-12.4-1-4.5-.5-7-3.7-6.8-8.3.2-3.5.5-7 .8-10.5.5-7 2.8-8.9 9.6-7.6 17.6 3.4 32.7-.5 44.7-14.4 4.5-5.2 8.2-10.9 11.8-16.7 11.7-18.9 22.2-38.4 32-58.3 6.4-12.8 13.5-25.3 18.6-38.7 8.2-21.6 10.1-44.1 9.3-66.9-.8-20.9-4.2-41.1-12.5-60.4-7.6-17.5-19.1-31.3-37.6-37.9-20.3-7.3-40.3-6.3-59.6 3.7-2.2 1.2-4.4 2.5-7 .7-2.5-1.8-2.1-4.3-1.7-6.8 4.6-29.9 9-59.9 13.7-89.9 1.2-7.7 3.2-9.3 11-9.1 13.6.4 27.3 1.5 40.9 1.8 24.3.6 48.7 1.8 69.1 1.1 16.7 0 29.6 0 42.5.1z',
        },
        {
          name: BODYPARTS.Cheek_L,
          path:
            'M686.7 739c.8-14.9.5-30.1 6.1-44.3 3.3-8.3 5.7-16.7 5.8-25.7.1-6.9 1.9-8.8 8.7-8.7 14.2.1 27.9-2.6 41.4-6.3 17.4-4.7 33.6-11.9 47.7-23.4 9.4-7.7 16.2-17.1 20.4-28.5 2.1-5.7 5-6.1 8.9-1.3 1.6 2 3 4 4.3 6.2 31.6 53.8 49.3 111.1 43.9 174-2.6 30.1-12 58.3-28.2 84-11.7 18.5-26.8 33.9-42.5 48.9-22.8 21.9-48.6 39.4-77.5 52.3-10.7 4.8-12.6 3.9-16.5-7.2-11.9-34.3-18.5-69.8-25.1-105.4-1.8-9.7-1.6-19.5-.9-29.3 1.5-21.9 4.9-43.7 3.8-65.8 0-6.5-.3-13-.3-19.5z',
        },
        {
          name: BODYPARTS.Chin_L,
          path:
            'M670.2 1040.2c.5-2.7 2.2-4.4 4.7-5.4 17.1-7.1 27-21.1 34.9-36.9 1.5-3 1.5-8.2 6.3-7.8 4.3.4 4.1 5.3 5 8.4 8.6 29 16.7 58.3 22.8 88 4.7 22.8 9.7 45.5 7.2 69.2-1.1 10.5-3.7 14.3-14.1 14-7-.2-13.9-1.3-20.8-2.8-32.7-7-57.4-35.5-55.3-68.1-.5-20.1 5.8-39.2 9.3-58.6z',
        },
        {
          name: BODYPARTS.Ear_L,
          path:
            'M1034 634c4.2-32.1 11-63.4 28.8-91.2 7.9-12.4 17.3-23.4 30.5-30.2 12.8-6.6 26.6-8.3 40.8-6.5 22 2.8 39.1 13.6 49.5 33.1 17.5 32.7 20.1 68.1 16 104.1-2.4 21-10.1 40.2-20.4 58.6-10.3 18.3-18 37.9-29.1 55.8-7.6 12.2-14 25.1-24.2 35.4-15.1 15.4-37.9 16.5-54.3 2.9-18.9-15.5-29.3-36.1-34.4-59.4-4.5-20.5-5.2-41.5-5.1-64.6-.3-11.3.3-24.6 1.9-38z',
        },
        {
          name: BODYPARTS.Eye_L,
          path:
            'M711.4 509.4c1.8.2 3.6.5 5.4.8 36 5 66.2 21.3 89.8 48.6 12.9 14.9 16.4 32.5 7.1 51.2-5.5 11.1-14 19.5-24.1 26.2-22.4 15.1-47.7 21.7-74.3 24.3-17.6 1.7-16.7-1.5-17.5-15.7-1.2-21.3-5.4-42-14-61.6-7-15.7-14.5-31.2-21.8-46.7-4.1-8.9-2.5-13.8 6.3-18.4 11.9-6.2 24.8-8.2 38.1-8.7 1.7 0 3.4-.1 5 0z',
        },
        {
          name: BODYPARTS.Forehead_L,
          path:
            'M661.7 448c10.4-54.8 29.8-106.4 56.5-155.3 5.9-10.8 6.6-11.4 18.9-8.5 49.3 11.5 91.7 35.3 127.2 71.5 6.3 6.5 6.3 9 .6 16.2-27.7 34.9-53.2 71.3-73.7 111-6.1 11.9-11.9 23.9-16.8 36.3-2.8 7-5.6 8-12.4 5.2-4.8-2-9.4-4.3-14.2-6.2-15.2-5.9-30.8-9.3-47.3-8.1-12.7.9-24.6 3.6-35.4 10.7-4.8 3.1-8.2 1.3-9.5-4.3-1.1-4.6-.5-9.3-.8-14.9.2-17.4 3.3-35.5 6.9-53.6z',
        },
        {
          name: BODYPARTS.Temporal_L,
          path:
            'M881.3 365c30.4 6 60.8 12.1 91.2 18.2 38.2 7.7 76.5 15.4 114.7 23.1 8.1 1.6 9.2 2.8 9.2 10.9 0 12 .2 24-1 36-2.1 21-5.1 41.7-10.4 62.1-.5 2.2-1.3 4.1-3.1 5.6-7.9 6.3-14.1 14.2-19 23-2.5 4.4-5.8 6.8-10.9 7.7-36.3 6.3-72.5 12.7-108.8 19.1-38.1 6.8-76.1 13.5-114.2 20.3-7.1 1.2-9.5-.4-10.9-7.3-1.2-6.1-3-11.9-6.3-17.3-8.1-13.3-19.7-22.9-32.4-31.5-7.9-5.4-8.5-6.7-5.1-15.7 6.2-16.8 14.2-32.7 22.9-48.3 20.2-36.3 44-70.1 70.1-102.2 2.3-2.8 5.1-4.8 9-4.6 1.6.3 3.3.5 5 .9z',
        },
        {
          name: BODYPARTS.Preauricular_L,
          path:
            'M875 747c-.9-9.8-2.3-19.5-4-29.2-5.9-33.3-16.3-65.2-32.3-95.2-4-7.5-8.7-14.6-13-21.9-3.1-5.4-1.8-8.4 4.2-10 3.5-1 7.1-1.8 10.7-2.4 32-5.5 64-11 96-16.6 36.1-6.3 72.2-12.7 108.3-19 8.1-1.4 10.7 1.6 7.7 8.9-14.6 35.4-20.1 72.4-20.5 110.4-.2 14.9 1.4 29.6 2 44.4.5 9.6-1.5 11.5-11 13.1-34.1 5.9-68.3 11.8-102.4 17.8-12.3 2.2-24.6 4.4-34.6 6.2-8.2.1-10.4-1.5-11.1-6.5z',
        },
        {
          name: BODYPARTS.Backside_Head_L,
          path:
            'M1253.8 835.5c-7.5-.1-9.9-4-7.3-10.9.4-1 .8-1.9 1.2-2.8 14.3-30.9 26.6-62.6 36.4-95.2 12.8-42.6 20.9-86.1 23-130.6 1.1-21.9.7-43.9-1.5-65.9-3.7-36.7-11.5-72.3-25.9-106.4-3.6-8.7-2.6-10.4 6.6-11.7 1.9-.3 3.7-.6 5.5-.6 22.2-.2 44.3-1.6 66.4-3 26.3-1.7 52.4-4.1 78.6-6.9 44.3-4.8 88.2-11.6 131.7-21.1 6-1.3 12-2.8 18.1-3.9 6.6-1.2 9.2.6 10.9 7.1 1.2 4.5 2.2 9 3.2 13.6 5.2 24.6 8.8 49.5 10.7 74.6 1.1 15.3 2.2 30.6 1.9 45.9-.3 14.5-.9 29-2.5 43.4-2.7 24.6-7.8 48.7-16.1 72-12.2 34.1-30 65.4-49.7 95.6-18.9 29-40.6 56.2-58.3 86.1-2.2 3.8-5.7 4.9-9.5 5.8-38.4 9.3-77.7 11.6-116.9 13.9-21 1.2-42 1.2-63 1-14.5 0-29 .1-43.5 0z',
        },
        {
          name: BODYPARTS.Jaw_L,
          path:
            'M1073.1 816.9c-1.9 21.5-3.6 43.1-6 64.6-1.7 15.5-3.7 31-8.5 46.1-7.3 22.9-15.4 45.5-22.9 68.3-13.5 41-38.6 73.9-70.5 102-23.3 20.5-49.7 35.9-78.2 47.8-16.1 6.7-32.5 13.3-49.9 15.8-20.8 3-41.7 4.9-62.6 7-6.2.6-12.6 1-18.9.6-5.6-.3-6.5-2.3-5.7-8 1.6-11.7 2.4-23.5.9-35.3-2.7-21.4-7.2-42.4-12.3-63.3-6.6-27-14.2-53.8-22.1-80.5-3-10-2.1-12.3 6.4-15.8 42-17.5 76.1-45.8 105.9-79.4 30.6-34.5 44.6-76 46.3-121.8.4-9 1.9-11 10.8-12.6 23.6-4.2 47.3-8.3 70.9-12.4 23.3-4 46.7-8 70-12 6.2-1 8.6.6 10.3 6.6.5 1.6.8 3.2 1.3 4.8 5.3 18.6 12.6 36.3 27.3 49.7 5.9 5.4 9.5 11.1 7.5 18.6 0 3.5.2 6.3 0 9.2z',
        },
        {
          name: BODYPARTS.Mouth_L,
          path:
            'M706.9 1003.4c-6.5 12-14.6 22.8-27.1 29.2-6.8 3.5-10.1 2.7-14.3-3.7-4.1-6.3-8.6-12.3-13.7-17.8-14.7-15.7-18-38.7-8-57.9 1.4-2.6 1-3.8-1.4-5.1-7.1-3.8-10.9-10.1-12.5-17.8-1.2-5.7-1.9-11.5-3.1-17.2-1-5.1 0-9.6 2.3-14 4.1-7.8 8.2-15.6 10.6-24.2.6-2 1.7-3.7 3.6-4.6 12.1-6.1 23.5-13.2 32.5-23.6 2.6-3 6-1.9 7.3 1.9.5 1.4.7 2.9 1 4.4 6.8 36.4 13.6 72.7 25.8 107.8 2.2 6.4 3.8 13 5.4 18.7-.8 9.4-4.3 16.8-8.4 23.9z',
        },
        {
          name: BODYPARTS.Neck_Frontside_L,
          path:
            'M1247.4 1124.8c.5 26.2-2.1 52.2-6 78.1-11.1 73.9-45.7 134.3-104.7 180.4-23 18-43.8 38.5-63.2 60.3-19 21.4-37.6 43.2-56.4 64.8-1.6 1.8-3.3 4.2-6.3 2.8-2.9-1.3-2.5-4-2-6.5 2.5-13.7 2.7-27.5 3.7-41.3 1.1-15.2-3.2-30.2-1.2-45.4.1-.7-.2-1.3-.3-2-7.7-37.5-10.9-75.3-9-113.7 1.2-25.6 4.3-50.8 9.4-75.8 2.9-14.4 8.1-28.3 10.6-42.7 3.7-21.4 5.3-42.9 3.3-64.7-1.8-19.9-5.4-39.3-16.2-56.6-3.2-5.1-2.9-9.4.6-14.2 6.2-8.7 11.4-18 15.7-27.8 11.5-26.5 20.1-54 29.2-81.4 3.4-10.1 7-20.2 8.6-30.8 3.4-22.4 5.3-45 7.8-67.5 1.2-10.1 1.6-10.7 11.8-10.3 21 .8 41.9 1.9 62.9 2.8 18.6.8 37.3 1.4 55.9 2.2 9.8.4 19.6.6 29.4 1.5 6.9.6 8.9 3.1 9.3 10 1.4 22.1 2.9 44.2 3.9 66.3 1.3 28.8 2.4 57.6 3 86.4.6 27.2-.2 54.4 1.2 81.5.5 14.6-1.3 29-1 43.6z',
        },
        {
          name: BODYPARTS.Lower_Neck_L,
          path:
            'M1241.5 865.9c0-3.2-.1-6.3 0-9.5.1-1.1.2-2.5 1.7-2.7 1.4-.2 2.1 1 2.6 2 .4.9.8 1.9 1.1 2.8 16.8 49.4 41.2 95.1 70.2 138.3 43.8 65.4 90.9 128.4 137.1 192.1 40 55.2 80.1 110.3 118.4 166.8 19.8 29.2 40.5 57.9 64.3 84.1 5.2 5.7 4.7 8.3-2.2 11.6-22.1 10.4-44.9 18.8-68 26.6-54.2 18.2-109.4 32-165.9 40.8-43.7 6.8-87.6 10.8-131.8 11.2-21.7.1-43.3-.2-65 .6-31.3 1.1-62.5 3.3-93.6 7.2-37.1 4.6-73.7 11.6-109.8 21.4-4 1.1-8 2.2-12.1 3-4 .7-5.7-1.5-4.2-5.3.5-1.3 1.1-2.5 1.8-3.6 7.9-12.4 15.9-24.6 25.1-36.1 19.8-24.7 39.8-49.2 61.1-72.7 15.9-17.5 32.1-34.8 50.4-49.9 19.8-16.3 39.6-32.6 56.5-52.1 23.4-26.8 39.9-57.4 50.7-91.1 11.6-36.2 16.5-73.5 17.2-111.3 1-54.6.7-109.3-1-164-.4-10.8-.5-21.7-1-32.5-1.3-25.9-2.5-51.9-3.8-77.8 0 0 .1 0 .2.1z',
        },
        {
          name: BODYPARTS.Neck_Backside_L,
          path:
            'M1245 837.2c2.3-2.3 5.2-1.8 8-1.8 45 0 90 .6 134.9-2.9 28.1-2.2 56.1-4.9 83.7-10.8 2.4-.5 5.3-2.1 7.5.5 2.3 2.8.5 5.7-.5 8.5-.2.8-.6 1.5-1 2.3-12.2 25.7-17.9 53-19.1 81.2-.9 21.7.5 43.3 2.7 64.9 4.3 41.9 13.1 82.9 26.5 122.9 10.6 31.5 23.3 62.1 37.3 92.2 14.8 31.9 30.9 63.2 49.3 93.2 22.6 37 45.7 73.6 68.5 110.5 6.1 9.8 11.9 19.7 17.5 29.8 3.7 6.7 2.5 9.6-4.1 12.5-2.3 1-4.6 2.1-6.8 3.2-4 2.1-7.5 1.6-10.8-1.8-5.3-5.3-10.2-10.9-15-16.7-24.2-29.6-45.8-61.1-67.3-92.6-49.5-72.5-102.5-142.3-153.4-213.7-31.1-43.7-63.6-86.5-92.6-131.7-23.1-36-43.5-73.4-58.4-113.6-3.7-10-7.1-20.1-9.1-30.5-.2-2.4.8-4 2.2-5.6z',
        },
        {
          name: BODYPARTS.Nose_L,
          path:
            'M668.9 558c2.5-.8 3.5 1.5 4.4 3.2 11.1 21.2 20.7 42.9 23.3 67.1.9 8.3 1.7 16.6 2.1 24.9.6 13.1 1.1 26.2-4.6 38.8-2.5 5.3-4.5 11.1-5.2 17.1-2.7 22.6-1.9 45.3-2 67.9 0 2.7 0 5.4-.3 8-1.6 15.3-3 30.5-3.7 45.8-.1 3.2-.8 6.2-2.5 9-7.4 12.6-18.3 21.4-31.4 27.6-4.8 2.3-8.4.6-9-4.8-1-9.1-6.3-12.8-14.5-14.5-13.2-2.7-26.1-6.8-38.7-11.7-8.7-3.4-15-9.1-18.8-17.6-5.3-11.7-5.5-23.8-2.3-36.1 2.6-10.2 8.1-19 14-27.5 13.2-18.9 24.8-38.7 35.5-59.1 10.8-20.5 22-40.8 32.9-61.2 4.2-7.8 8.1-15.7 11.9-23.7 7.3-15.3 11-31.1 7-47.1.2-2.8-.5-5.3 1.9-6.1z',
        },
        {
          name: BODYPARTS.Throat_L,
          path:
            'M1008.3 1413.5c-1.9.5-2.8-.9-3.4-2.3-2.6-5.6-5.1-11.3-7.6-16.9-15-33.6-34.5-64.7-52.2-96.8-12.5-22.7-23.8-45.9-30.9-70.9-3.6-12.7-6.2-25.6-9-38.5-2.9-13.4-10.7-21.5-24.6-23.2-6.8-.9-13.5-2-20.3-3-1.5-.2-3.2-.3-3.6-2.1-.4-2.2 1.4-2.9 2.9-3.5 2.6-1.1 5.2-2.2 7.9-3.1 45.4-16 85.7-39.8 118.4-75.7 4-4.5 7.9-9 11.8-13.6 4.9-5.8 8.9-5.7 13 .8 1.7 2.6 3.1 5.5 4.4 8.4 7.1 16.1 10.3 33.2 10.9 50.6.9 25.8-.5 51.5-7.8 76.7-8.6 29.5-13.4 59.8-15.2 90.5-1.7 29-1.6 57.9 2 86.8 1.4 10.7 2.9 21.5 4.6 33.6 0-.1.3 1.4-1.3 2.2z',
        },
        {
          name: BODYPARTS.Head_Top_L,
          path:
            'M1228.3 48.8c44.4 7.3 87.1 19.6 127.2 40.5 25.3 13.2 49.8 27.8 73.6 43.5 27.2 18 52 39 73.3 63.8 28.8 33.5 52.1 70.5 69.9 110.9 8.2 18.5 15.2 37.4 20.3 57 2.3 8.7 1.1 10.3-7.4 12.3-9.4 2.3-18.8 4.4-28.3 6.4-71.6 14.7-144.1 22.4-217 26.8-34.9 2.1-69.9 2.6-104.9 3-18.8.2-37.7-.4-56.5-.9-19.6-.5-39.3-1.1-58.9-2.2-10.3-.6-20.6-1-30.8-3.1-24.8-5-49.7-9.7-74.6-14.6-44.3-8.7-88.7-17.5-133-26.1-5.8-1.2-10.5-3.6-14.6-7.9-12.4-12.9-25.9-24.5-40.7-34.6-27.7-19-57.9-32.2-90.7-39.6-8.6-1.9-9.6-4.3-5.5-12.2 6.2-12 13.5-23.3 20.8-34.7 19.9-31.2 41.8-60.6 69.9-85.1 33.7-29.3 71-53 112.5-69.7 66.8-26.8 136.3-40.1 208.3-40.5 29.2-.3 58.2 2.5 87.1 7z',
        },
      ],
      path:
        'M784.1 441.5c.7-1.8 1.8-4.2 2.7-6.6 3.9-14.2.8-27.2-6.3-39.7-8.9-15.6-18.6-30.7-21.6-48.9-.6-3.4-2.9-5.2-6.2-5.4-5.1-.4-10.3-.7-15.4-.5-8.7.3-17.3 1.3-25.9 1.4-6.1.1-11.1-2.7-15-7.6-5.3-6.7-4.4-14.1-2.5-21.4 1.4-5.4.5-9.7-3.4-13.6-3.9-4-4.6-8.9-3.4-14.3.2-1 .5-2.5 0-3.1-4.7-5.6-4.5-11.2-1.3-17.5 2.9-5.8 1.3-8.5-4.8-10.2-2.6-.7-5.1-1.4-7.6-2.3-5.8-2.2-8.8-8-7-14.1.7-2.5 1.9-4.9 3.2-7.2 7.4-13.4 14.9-26.6 22.3-40 2.5-4.5 3.3-9.2 2.1-14.4-1.4-6.3-3.3-12.8-2.8-19 1.3-18.2 7.2-35.3 15.6-51.5 11.3-22.1 25.2-42 47.9-53.9C781.5 37.6 810 32.3 840 36.1c41.3 5.2 74.3 24.2 95.4 60.8 15.7 27.2 19.4 57 14.5 87.7-3 18.5-13.4 33.8-24.4 48.5-10.4 13.9-16.7 28.9-15.8 46.6 1.1 22.5 7.1 43.7 16.1 64.3 9.4 21.2 22.3 40.4 34.3 60.1 2.9 4.9 5.7 9.9 9.2 16-6.4-2.4-11.2-4.3-16.2-6-12.5-4.3-25.3-6.8-38.6-6.2-6.9.3-13.9 1.2-20.8 1.8-21.3 1.8-42.2 5.7-62.3 12.8-16.1 5.6-31.7 12.7-47.3 19z',
    },
    {
      name: BODYPARTS.Left_Leg_LL,
      viewBox: '0 0 1669 2388',
      background: imgRightLegSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Lateral_Thigh_LL,
          path:
            'M684.8 217.5c-1.1-7.4 2.2-12.1 8.1-16.2 18.8-13.2 39-23.7 60.3-32.1 27.2-10.7 55.4-17.8 84.4-21.9 3.5-.5 5.5.4 5.8 4.1.2 1.7.5 3.3.6 5 .5 11 1.7 21.9 2.9 32.8 1 8.8 3.1 17.4-.7 26.5-8.2 20-16.4 40-25.7 59.6-16.5 34.6-36.4 67.3-55.6 100.5-14.9 25.9-30.9 51-48.2 75.3-1.8 2.5-3 7.2-6.6 6.5-3.4-.7-3.5-5.3-4.5-8.4-2.6-7.8-4.3-15.7-5.7-23.8-5.4-31.5-8-63.3-10.2-95.2-2.5-34.7-3.5-69.5-4.8-104.2 0-2.8.3-5.7-.1-8.5z',
        },
        {
          name: BODYPARTS.Left_Foot_LL,
          viewBox: '0 0 1669 2388',
          background: imgFootLegSrc,
          // sub areas displayed when zooming in
          areas: [
            {
              name: BODYPARTS.Right_Heel_RM,
              path:
                'M1394.2 1328.5c-.6 11.6-1.6 23.2-3.7 34.7-.9 4.7-3.3 7.2-7.6 8.8-13.2 4.8-26.7 8.4-40.5 11-31.6 5.8-63.5 7.1-95.5 5-17.5-1.1-25.9-7.1-31.8-23.7-1.2-3.5-2.1-7-2.9-10.6-5.9-27.8-4.2-56-1.7-84 2.2-25.4 6-50.6 10.5-75.7 14.5-80.4 34.8-159.5 61.1-236.9 3-9 6.6-17.8 10-26.7.5-1.4 1.2-3 3.1-2.8 2 .2 1.7 2.1 2 3.5 1.6 8.6 1.1 17.3 1.7 25.9 2 28.3 5.2 56.4 10.6 84.2 6.4 32.8 14.8 65 31.9 94.2 10.1 17.3 23 31.8 42.6 38.9 3.4 1.3 5 3.4 5.3 7 .4 4.5 1.2 8.9 1.6 13.4 2.6 32.9 3.7 65.8 4.1 98.8.4 11.6-.4 23.3-.8 35z',
            },
            {
              name: BODYPARTS.Right_Forefoot_RM,
              path:
                'M804.8 1022.3c13.4 2.6 26.3 6.4 36.7 15.8 5.9 5.3 5.9 7.8 1 13.9-40 49.4-76.9 100.9-108.8 156-20.9 36.1-39.6 73.3-51.4 113.5-6.6 22.4-10.2 45.1-8.3 68.5.8 8.9.8 9.1-7.8 11.7-34.4 10.4-68.9 20.7-103.4 31-7.9 2.3-8.7 1.9-12.3-5.7-7.5-16-17.6-30.2-30.5-42.3-17.7-16.6-38.2-28.8-60.7-37.9-23-9.3-46.8-15.4-71.3-19.1-2.3-.3-5.7 0-6.3-2.9-.7-3.4 2.9-4 5.1-5.2 9.2-4.8 18.4-9.4 27.5-14.3 14.9-8 30-15.9 44.2-25.3 15.9-10.6 31.1-22.3 45.7-34.7 21.4-18.1 42.9-35.9 66.2-51.5 49-32.9 96.9-67.1 141.6-105.8 23.7-20.6 47.6-41 70.8-62.3 3.5-3.2 7.1-5.5 12.1-4.9 3.3.4 6.6.7 9.9 1.5z',
            },
            {
              name: BODYPARTS.Right_Medial_Foot_RM,
              path:
                'M683.8 1316.5c13.8-43.7 34.9-83.9 58.4-123 25-41.6 52.9-81.2 82.7-119.5 5.6-7.2 11.2-14.4 17.2-21.4 4-4.7 5.6-4.6 10.4-.8 2.4 2 4.8 4.2 7.2 6.2 43.1 36 91.7 62.1 144.6 80.6 67.8 23.7 137.9 33.8 209.4 35.4 9 .2 10.4 1.8 8.9 10.8-2.1 12.2-4.3 24.3-6.1 36.5-5.4 36.5-9.8 73-7.4 109.9 1 15.3 3.1 30.5 11.3 44.1 2.8 4.6 1.4 6.7-4.1 6.8-7.6.2-15.3-.6-22.9-.9-17.3-.7-34.5-3-51.7-4.6-20.9-2-41.7-4.4-62.6-6.5-22.2-2.2-44.4-4.1-66.6-6.3-23.2-2.3-46.4-3.9-69.7-4.6-17.3-.5-34.6-.6-51.9-.3-18.3.3-36.6 1.5-54.8 3.4-40.7 4.3-80.6 12.7-119.9 24.1-11 3.2-22 6.7-33 9.9-6.1 1.8-8.5.2-9.1-6-.4-4.5-.8-8.9-1-11.5-.1-17.8 4.6-43.4 10.7-62.3z',
            },
            {
              name: BODYPARTS.Left_Sole_LL,
              path:
                'M846.5 1433.2c-22.1 0-43.9 2.7-65.5 6.9-56.5 10.9-111.6 27.2-166.7 43.6-18.5 5.5-37.3 10.2-56.4 13.2-8.9 1.4-11.4-1.4-8.7-9.8 4.4-13.8 6.2-28 5.9-42.4-.2-9.5 0-9.5 9.2-12.4.4-.1.9-.3 1.4-.4 45.9-13.5 91.7-27.3 137.3-41.5 69.8-21.8 141.3-32.8 214.5-32.1 9.1 0 18.3.5 27.4.9 13.7.6 27.3 1.1 40.9 2.2 24.2 2 48.4 4.4 72.6 6.7 27.6 2.7 55.3 5.3 82.9 8.4 27 3.1 54 5.1 81.1 6.5 24.2 1.2 48.3-1.1 72.2-4.5 29.4-4.2 58.3-10.5 86.9-18.6 7.1-2 9.6.2 8.1 7.4-2.9 13.5-8.2 26-17 36.8-12 14.8-28 24.3-44.7 32.8-39.7 20.1-82.3 26.9-126.1 29.1-17.5.9-34.9.8-52.4.1-14-.6-28-1.7-41.8-3.6-56.3-7.6-112-19-168.5-25-25.9-2.7-51.7-4.5-79.2-5.5-3.5-.4-8.4.9-13.4 1.2z',
            },
            {
              name: BODYPARTS.Left_Lateral_Ankle_LL,
              path:
                'M1290 762.9h29c7.7.1 8.9 1.5 7.2 9-8.9 39.5-17.4 79.1-25.3 118.8-2.3 11.8-3.1 24-7.4 35.2-18.4 47.5-32.7 96.2-45.6 145.4-8.3 31.5-15.5 63.1-21.9 95-1.5 7.1-2.1 7.6-9.7 7.7-14.5.2-28.9-.9-43.4-1.8-32.1-2-63.9-6.5-95.4-13.1-58.6-12.4-114.3-32.5-165.7-63.7-22.3-13.6-43.3-28.9-62.4-46.6-4.3-3.9-4.2-5.4 0-9.4 6.8-6.4 13.5-12.8 19.8-19.7 32.2-35.2 57.2-74.8 75-119 15.7-39.2 24.7-79.7 27-121.9.5-8.9.7-17.7.8-26.5.1-4.3 2-6.5 6.4-6.3 1.7 0 3.3 0 5 .2 11.4 1.3 22.8 2.5 34.2 3.9 36.6 4.4 73.3 6.6 110 8.7 25.9 1.5 51.8 2.5 77.8 3 28.1.5 56.3.1 84.4.1 0 .4 0 .7.2 1z',
            },
          ],
          path:
            'M915 1936.6c20 1.9 39.4 6.2 58.3 13.1 3.5 1.3 4.9 3.4 4.9 7.1-.1 12.8.1 25.7-.1 38.5-.6 31.9 1.8 63.5 8.3 94.8 6.6 31.7 9.1 63.9 6.8 96.3-1.2 17.5-7.8 31.6-23.6 41-19.8 11.8-40.9 15.5-63.4 11.9-29.5-4.7-58.5-11.7-88.2-14.9-18.6-2-37.1-3.5-55.8-.7-21.8 3.3-42.9 9.4-64 15.7-19.4 5.8-38.8 11.3-59.4 7.5-4.4-.8-8.5-.4-12.8.5-22 4.5-43.9 5.2-65.9-.2-10.3-2.5-21-3.7-31.6-.2-8.4 2.8-17.2 2.5-25.8 1.8-8.3-.7-16.4-2.6-23.4-7.8s-10.3-16.8-6.5-24.7c3.9-7.9 8.7-14.8 19-14.9 3 0 6-1.3 8.9-1.2 7.7.3 14-2.8 20.4-6.6 13.6-8.1 28-12.9 44.3-10 8 1.4 16.4.6 24.1-2.6 13.2-5.6 26-12.1 38.2-19.6 10.8-6.6 20-15.4 29.9-23.3 12.5-9.8 26-18 38.8-27.4 22.3-16.3 43.2-34.4 63.3-53.4 15.8-14.9 30.7-30.7 39.1-51.3 7-17 9.7-35 11.8-53 .7-5.7 3.3-8.1 8.5-9.2 23.2-5.1 46.5-8.5 70.3-8 8.5.1 17 .2 25.6.8z',
        },
        {
          name: BODYPARTS.Lateral_Lower_Leg_LL,
          path:
            'M814.1 1755.8c-1.8-34.6-3.7-69.2-5-103.8-1.1-28.3-3.4-56.5-7.6-84.5-7.5-50.2-7.8-100.9-11.6-151.3-1.4-17.3-2.1-34.6-3.6-51.8-.4-4.6.4-8.1 3.3-11.5 10.1-11.9 17.9-25.3 23.9-39.7 7.9-18.9 11.6-38.6 13.1-58.8.4-5.4 2.7-6.6 7.5-6.4 17.8.7 35.6 0 53.1-4.2 8.3-2 8.7-1.7 10.5 6.4 9.5 41.8 17.3 83.8 23.4 126.2 5.5 38.2 9.6 76.6 11.3 115.2 1.4 32.5 1.1 65-2.5 97.3-3.5 30.7-6 61.6-8.6 92.4-2.1 24.9-3.6 49.8-5.3 74.7-2.3 34.8-4.7 69.7-6.7 104.6-1.4 23.9-2.2 47.9-3.4 71.8-.2 2.6.2 4.2 2.8 5.5 3.1 1.6 4.3 5 3.7 8.1-.7 3.3-4.1 2.1-6.4 2.1-18 .1-35.9 2-53.8 3.7-11.8 1.2-23.5 2.4-35.3 3.2-5.2.4-6.2-1.2-5.6-6.3 3.8-34.8 5.9-69.7 5.6-104.7.2-29.5-1.2-58.9-2.8-88.2z',
        },
        {
          name: BODYPARTS.Knee_LL,
          path:
            'M827.3 1253.3c-1 21.6-5.6 42.2-13.9 62.1-5.7 13.4-13.3 25.7-22.5 37-3.3 4.1-4.8 3.8-6.6-1.1-2.5-7.1-5.5-14-9.9-20.1-14.5-20.3-23.7-42.7-28.2-67.2-2-10.8-6.2-21.1-10.5-31.2-10.7-24.9-12.4-51.6-15.8-78-2.1-16.7-1.3-33.6-3-50.3-.4-3.9 0-7.7 1.6-11.3 2.4-5.1 5.1-6.1 9.9-3.3 5.1 3 10.5 5.1 16 7 10.5 3.6 21.3 6.6 32.1 9.4 12.6 3.3 22.1 10.6 27.6 22.6 16 35.2 22.9 72.5 24 112.7-.2 2.8-.7 7.3-.8 11.7z',
        },
        {
          name: BODYPARTS.Calve_LL,
          path:
            'M1057.2 1514.7c-2.3 26.6-5.9 52.9-13.1 78.7-5.3 19.1-13.4 37.3-18.9 56.4-11.1 38.4-18.6 77.8-26.7 116.9-11.1 53.5-18.6 107.3-19.7 162-.1 8.7-.4 17.4-.8 26-.2 4.8-1.7 5.9-6 4.2-20.3-7.9-41.1-14.7-62.2-20.2-3.5-1-5-2.8-4.8-6.4.4-9.8.6-19.6 1.2-29.4 1.5-26.4 3.1-52.8 4.7-79.2 1.4-23.6 2.6-47.2 4.3-70.8 2-28.7 4.3-57.4 6.7-86.1 2-23.5 3.8-47.1 6.4-70.6 2.9-26.5 4.4-53 4-79.7-.4-25.4-1.9-50.6-4.2-75.8-3-32.5-7.5-64.8-12.8-97-5.1-31.7-11.4-63.2-18.5-94.6-1.4-6.4-.9-7.3 4.9-9.4 15.9-5.8 30.8-13.5 43.4-25.1 2.6-2.4 4.8-2.2 7.4.1 4.1 3.6 8.1 7.5 12.8 10.3 6.4 3.8 10 9.4 12.7 16 2 5.1 4.3 10.1 6.3 15.2 17.1 44.3 35.7 88 53.7 131.9 12.9 31.4 22.1 63.6 20.2 98.1-.3 9.4-.1 18.9-1 28.5z',
        },
        {
          name: BODYPARTS.Lateral_Knee_LL,
          path:
            'M836.2 1249.1c-7.4-.2-8.6-1.5-9.1-8.8-1.6-23.3-4.4-46.4-9.2-69.2-3.9-18.5-8.3-36.8-19-52.8-4.2-6.2-4.2-6.3 2.7-9.5 18.8-8.6 35.4-19.9 48.3-36.2 4.6-5.8 6-5.6 8.9 1.1 5.9 13.9 12.5 27.6 19.7 40.9 17.5 32.5 38 62.9 63.6 89.8 7.3 7.7 7.4 8.1-1.1 14.7-27.9 21.7-60.3 29.5-94.9 29.9-3.4 0-6.7.1-9.9.1z',
        },
        {
          name: BODYPARTS.Politea_LL,
          path:
            'M971.8 1222.4c0 5.7-1.9 6.6-6.6 3.5-6.4-4.2-12.1-9.4-17.5-14.8-21.2-21-38.9-44.7-54.6-69.9-13.7-21.9-25.5-44.8-35.4-68.7-2.9-7.1-2.1-8.2 5.6-7.5 9.8.8 19.5 2.2 29.3 2.8 18.3 1.1 36.5 1.9 54.8 2.9 9 .5 8.9.7 10.8 9.5 4.2 19.9 5.5 40.2 8.9 60.2 3.1 18.2 6.2 36.4 5.9 56.2.7 7.8-1.1 16.8-1.2 25.8z',
        },
        {
          name: BODYPARTS.Medial_Hamstrings_LM,
          path:
            'M789.3 1030.1c1.5-22 3.7-43.8 5.6-65.7 2-23.9 4-47.8 7.4-71.5 8.4-58.2 25.8-114 48-168.3 18.5-45.4 40.4-89.2 64.8-131.8 17.4-30.4 36-60 56.1-88.7 2.3-3.3 4.5-5.2 8.5-2.3 14.7 10.6 30 19.9 46.3 27.7 4 1.9 4.7 4.5 1.9 7.9l-1.8 2.4c-10.3 13.2-17.7 27.4-17.2 44.8.1 3.8-.5 7.6-1 11.4-4.8 35.1-10 70.2-14.3 105.4-3.9 32.2-9 64.3-13.2 96.5-8.3 63.6-19.4 126.8-26.8 190.5-3 25.2-4.7 50.4.7 75.6 1.4 6.4.6 7.3-6.2 7.1-28.7-.8-57.3-1.7-85.7-5.6-3-.5-5.6-.5-8.1 2.2-10.7 11.8-22.8 22-36 30.8-6.9 4.6-14.1 8.9-22.3 11-5.6 1.4-7.3.4-8.4-5.2-1.4-7.1-.4-14.3-.7-19.5-.3-19.6 1.1-37.3 2.4-54.7z',
        },
        {
          name: BODYPARTS.Medial_Upper_Leg_LM,
          path:
            'M800.1 318.9c-20.3 36.4-84 141.6-88.2 138.7-23-16-26.1-246.4-26.1-246.4l-11.1 12.1s3.6 5.3-1.4 64.6c-2.7 31.6-17.8 88.3-24.4 141.7-8.6 69.4-21.3 221.7-8.9 328 22 189.1 76.9 341.7 76.9 341.7s-.2-15.4 9.1-12.1c16.7 6 62 21.1 62 21.1 5.4-102.1 3.7-222.4 56.2-366.2C894 605.6 976.1 497.6 976.1 497.6 898.7 451.8 850.6 210 850.6 210s-36 83.2-50.5 108.9z',
        },
      ],
      path:
        'M622.6 2349.5c8.9 3.6 18.3 4 27.4 1.4 7.2-2 14.2-1.4 21.1.2 13.9 3.3 27.7 2.7 41.6.4 3.3-.6 6.8-.7 10.2-.3 11.1 1.4 21.8-.6 32.4-3.7 12.6-3.7 25.2-7.2 38-10.2 18.3-4.4 36.8-2.5 55.1.2 18.9 2.8 37.7 7.4 57 5.5 7.2-.8 14.6-1.8 21.5-3.9 19.6-6 25.5-13.9 26-34.4.4-18.2-.6-36.3-4.6-54.1-5.4-24.1-6.5-48.5-5.7-73 .9-28.3 1.3-56.7 4.6-84.7 3.5-29 10.2-57.6 15.3-86.4 5.2-29.1 16.2-56.5 23.8-84.8 8.6-31.9 12.7-64.6 5.5-97.3-3.5-15.9-10-31.2-16-46.5-11.6-29.2-23.9-58.1-35.7-87.2-.9-2.4-1.5-5.3-1.3-7.8 1.9-19.6-.8-38.9-4.1-58.1-2.8-16.6-5.7-33.1-8.3-49.7-2.5-16.1-.9-32.2 1.3-48.2 4.6-33.7 9.7-67.4 14.4-101.2 3.9-28.1 7.7-56.3 11.4-84.5 3.4-26 5.8-52.2 10.2-78.1.6-3.6.6-6.7 0-9.5-.1.1-.1.2-.2.3-7-10.1-71-31.5-118.3-38.5-42-6.2-84.1 2.6-124.6 15-1.8 23.9-1.8 47.3-3.4 71.2-1.1 40.6 2.8 80.9 9.8 120.8 5.7 33.1 11.8 66.3 19.7 98.9 7 28.8 16.8 56.8 25 85.3 1.6 5.5 1.3 11.5 1.7 17.3 1.6 20.6 2.7 41.2 9.1 61.1 4.3 13.4 7.9 27.2 12.7 40.4 3.3 9 7.5 17.8 12.6 25.9 6.6 10.4 10.3 21.4 10.9 33.7 1.7 32.2 3.4 64.5 5.9 96.7 1.9 24.9 6.1 49.6 7.4 74.5 2.6 50.3 4.3 100.8 5.3 151.2.4 24.1-2 48.2-6.9 72-3.1 15.1-10.2 28.1-21 39-19.1 19.3-39.7 36.9-62.3 52.1-5.6 3.8-11.3 7.7-16.4 12.2-10.6 9.6-22.4 17.4-35.5 22.9-6.2 2.6-13.6 4.4-20.2 3.7-11.5-1.2-21 2.3-30 8.4-3.8 2.5-7.5 4.7-12.3 4.4-2.1-.2-4.3.4-6.4.6-6 .7-12.3 6.5-13.1 12.2-.9 6.2 2.3 11.1 9.4 14.6z',
    },
    {
      name: BODYPARTS.Abdomen_L,
      viewBox: '0 0 573.1 400.3',
      background: imgTorsoMidSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Left_Lower_Flank_L,
          path:
            'M367 192.5c.7 0 1.4 0 2.1-.1 1.6.1 1.9.6 1.7 2.1-.3 1.4-.6 2.7-.8 4.1-1.2 7.2-1.4 14.4.4 21.6 1.5 5.8 3.7 11.4 5.8 17 1.8 4.6 3.1 9.2 2.7 14.2-.4 4.1-.4 8.2-1 12.3-.3 2.3-1 4.6-1 6.9 0 4.7 1.2 9.2 2.9 13.6 2.9 7.5 7.2 14.3 12 20.8.4.5.7 1.1 1.1 1.6.2.3.3.5.1.8-.2.2-.5.2-.8.1-.3-.1-.5-.2-.7-.3-6.2-3.4-12.7-6-19.4-8.2-12.7-4.2-25.6-7.5-38.9-8.9-7-.8-13.9-1.4-20.9-1.6-6.8-.2-13.6-.1-20.4.4-6.1.5-12.1 1.1-18.1 2.1-16.9 2.9-33 8.1-48 16.5-9.3 5.2-17.8 11.4-25.6 18.8-5.3 5-10.1 10.3-14.5 16-.3.5-.6 1-1.2.8-.6-.2-.6-.8-.6-1.3-.4-6.8-.9-13.6-1.4-20.4-.4-5.7-.8-11.5-1.2-17.3-.1-.8-.3-1.7.4-2.4.7-.9 1.5-1.8 2.3-2.6 4.5-4.2 9.4-7.9 14.6-11.3 9.2-5.9 19-10.9 29.1-15.2 3.4-1.4 6.6-3.2 10.4-3.1.8.1 1.6-.1 2.4-.2.9-.1.9-.5.5-1.2-1.3-2.3-2.6-4.7-3.7-7.2-6.8-15.7-10.4-32.4-13.2-49.2-.1-.9.2-1.4 1-1.6 1-.2 2-.5 3-.8 22.6-6 45.6-9.9 68.8-12.5 10.3-1.2 20.7-2.1 31.1-2.8 6.1-.5 12.2-.8 18.3-1 6.9-.3 13.8-.4 20.7-.5z',
        },
        {
          name: BODYPARTS.Left_Upper_Flank_L,
          path:
            'M215.7 117.6v-10.7c-.1-2 .1-3.9.2-5.9.1-.9.5-1.3 1.3-1.5 1.2-.2 2.4-.5 3.6-.8 12.8-3.1 25-7.8 36.5-14.1 9.1-5 17.5-11 24.9-18.2 4.3-4.2 8-8.7 11.4-13.6.4-.6.6-1.5 1.4-1.4.8.1.9 1.1 1.1 1.7 6.2 16.9 14 33 22.9 48.6 5.9 10.3 12.3 20.3 19.2 30 8.7 12.1 18.2 23.7 28.7 34.4 2.5 2.5 5 4.9 7.5 7.4.8.8 1 1.5.8 2.5-1.2 4.9-2.4 9.9-3.5 14.9-.2 1.2-.8 1.7-2.1 1.7-5 .1-9.9 0-14.8.2-7.7.3-15.4.5-23.1 1-5.7.3-11.3.7-17 1.2-7.8.7-15.6 1.5-23.4 2.4-12.4 1.5-24.8 3.4-37 5.8-9.6 1.9-19.2 4.2-28.7 6.7-1.6.5-2 .1-2.2-1.5-.4-3-.9-6-1.3-9-.9-6.9-1.8-13.9-2.5-20.9-.5-4.5-.9-8.9-1.3-13.4s-.8-9.1-1.1-13.6c-.3-4.4-.5-8.8-.8-13.2-.5-6.9-.8-13.8-1-20.7h.3z',
        },
        {
          name: BODYPARTS.Left_Middle_Abdomen_L,
          path:
            'M205.5 270c-6.8-.6-13.6-1.5-20.2-3.4-4.8-1.3-9.5-3.1-13.7-5.9-.6-.3-1.1-.8-1.2-1.5-.4-2.3-.7-4.5-.5-6.8.4-4.1.5-8.2.6-12.4.1-6.7-.2-13.3-.7-19.9-.5-8.3-1.5-16.5-2.6-24.7-2.1-14.9-5-29.7-8.4-44.4-1.5-6.2-3-12.5-3.8-18.9-.7-5.1-1.2-10.2-1.6-15.3-.2-2-.2-4.1-.3-6.1-.1-1.1.4-1.6 1.5-1.5.4.1.7.1 1 .1 3.4.4 6.8.5 10.2.6 4.3 0 8.5-.3 12.8-.7 9.2-.9 18.2-2.7 27-5.5 2.5-.9 5.1-1.8 7.7-2.6 1.3-.4 1.7-.1 1.8 1.2.1 1.4.2 2.9.2 4.3.1 5 .3 9.9.5 14.9.2 8 .6 15.9 1.2 23.9.4 6 .8 12 1.3 18 .7 8.3 1.5 16.6 2.5 24.9 1.4 11.6 3 23.2 5.3 34.7 3.1 15.4 6.8 30.6 14.5 44.6.7 1.2.3 1.8-1.1 1.9-6.3.6-12.7.8-19.4 1-4.7-.2-9.7-.3-14.6-.5z',
        },
      ],
      path:
        'M703.9 1002.6c-1.6-18.1-2-36.2 3.8-53.9.7-2.3.6-5.4-.3-7.7-8.6-22.1-10.2-45.3-10.8-68.6-.4-15.3-1.1-30.6-1.4-45.9 0-1.7 1.4-4.1 2.9-4.9 17.9-10.3 35.4-21.4 53.9-30.4 24.8-12.1 50.9-19.5 79.1-15.7 20 2.7 36.5 11.6 49.5 26.8 16.9 19.8 35 38.3 55.4 54.5 1.5 1.3 2.7 4.3 2.4 6.2-1.1 6.9-2.7 13.7-4.5 20.4-4.1 15.1-3.6 29.7 2.4 44.4 4.7 11.5 8.4 23.4 11.8 35.4 4.2 15 7.6 30.3 11.6 46.1-2.7-1.1-4.7-1.8-6.6-2.6-40.3-15.9-81.9-23.3-125.2-17.1-31.7 4.5-60.7 16.1-86.7 34.9-10.8 7.8-20.7 16.6-31.9 25.6-1.9-16.1-4.1-31.8-5.4-47.5z',
    },
    {
      name: BODYPARTS.Torso_L,
      viewBox: '0 0 573.1 400.3',
      background: imgTorsoTopSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Left_Lower_Chest_L,
          path:
            'M170.4 252.9c-6.1-1.9-11.7-4.8-16.2-9.4-.7-.7-1.1-1.4-1-2.4.2-2.2.2-4.3.4-6.5.1-.9 0-1.8-.3-2.7-.9-2.4-1.7-4.7-2.3-7.1-2.1-8.2-2.6-16.5-1.9-24.9.3-3.8.9-7.6 1.7-11.3.3-1.2 1-1.7 2.3-1.8 3.9-.2 7.7-.4 11.6-.8 9.3-1 18.4-2.5 27.3-5.3 14.2-4.4 27.1-11.2 37.4-22.2 4.5-4.8 8.2-10.1 10.8-16.1.7-1.6 1.4-1.8 2.8-.7 3.4 2.6 7 5 10.8 6.9 8 4 16.5 6.6 25.2 8.4 2.4.5 2.8 1 2.3 3.4-1.9 9.2-5.3 18-9.8 26.3-6.6 12.5-15.2 23.6-24.9 33.8-7.2 7.6-15 14.7-23.2 21.3-5.6 4.4-11.3 8.7-17.3 12.5-.6.5-1.3.7-2.1.8-3.7.3-7.5.7-10.8.5-8 .2-15.4-.7-22.8-2.7z',
        },
        {
          name: BODYPARTS.Left_Shoulder_Blade_L,
          path:
            'M357.8 236c2.5-6.6 5.5-12.9 8.3-19.4 6.3-14.6 11.2-29.6 12.4-45.6.7-8 .3-15.9-2.3-23.5-.6-1.7-.5-2.1.9-3.1 8.6-6.5 15.3-14.5 20.1-24.1 3.1-6.2 5.2-12.7 6.5-19.5.1-.6.2-1.4 1-1.6s1.1.5 1.5 1c3.4 4.5 6.1 9.5 8.3 14.7 2.9 7.1 4.6 14.5 5.3 22.1.6 5.9.4 11.8-.2 17.7-.5 4-1.3 7.9-2 11.8-1.2 6.5-2.5 13.1-4.4 19.5-2.6 8.4-6.7 15.9-11.9 22.8-.6.9-1 1.7-1.3 2.7-1.4 4.6-2.8 9.3-4.2 13.9-2.9 9.6-5.9 19.1-8.7 28.6-2.3 7.7-4.1 15.5-6.1 23.3-1.4 5.5-3 10.9-4.8 16.3-.1.5-.3 1.2-1 1.3-.7.1-1-.4-1.4-.9-3.6-4.7-7-9.6-10.1-14.6-5.5-8.9-8.9-18.4-8.5-28.3-.2-5.7.9-10.5 2.6-15.1z',
        },
        {
          name: BODYPARTS.Front_Left_Ribcage_L,
          path:
            'M307.9 208.5c-.3 6.7-1 13.3-2 19.9-2.2 14.5-6.4 28.3-13 41.4-1.5 3-3.1 6-5.5 8.5-7.8 7.8-15.6 15.6-24 22.8-8.6 7.3-17.4 14.2-27.1 20-4.4 2.6-8.8 5-13.7 6.6-1.8.6-2.6 0-2.7-1.8-.3-4.3-.4-8.7-.7-13-.5-7.5-1.3-15-2.5-22.4-1.1-6.7-2.6-13.5-4.8-20-1.6-4.7-3.5-9.1-6.3-13.2-.9-1.3-.8-1.6.4-2.6.9-.8 1.9-1.5 2.9-2.2 13.8-9.6 26.8-20.4 38.3-32.7 4.1-4.4 8-8.9 11.7-13.7 10.7-13.9 19-29.1 22.6-46.5.3-1.5.8-1.9 2.3-1.7s3.1.5 4.6.7c4.6.7 9.3 1.1 13.9 1.6 2 .2 2.1.4 2.5 2.5 2.1 12.1 3.1 24.2 3.1 36.5-.1 3.1 0 6.2 0 9.3z',
        },
        {
          name: BODYPARTS.Left_Upper_Chest_L,
          path:
            'M153.6 186.5c-1.6 0-2.2-.8-1.9-2.3 0-.3.1-.6.2-.9 2.1-8.6 5.1-16.8 8.6-24.9 3.4-8 7.3-15.7 11.3-23.4 2-3.9 4.4-7.8 5.9-12 2.4-6.4 5.2-12.6 8.3-18.6 3.4-6.5 6.4-13.1 8.1-20.2 1.2-5 3.2-9.7 5.7-14.2 1.8-3.4 3.8-6.7 5.5-10.1.8-1.7 2-2.5 3.7-2.9 6.5-1.7 12.9-3.8 19.3-6.1 4.2-1.5 8.4-3.1 12.6-4.6.7-.3 1.5-.6 2.1.1.6.7.1 1.3-.2 1.9-.9 2-1.9 4-2.9 6-1.4 2.8-2.1 6-3.5 8.9-4.2 9-7.5 18.3-9.2 28-.2 1.1-.6 1.9-1.4 2.7-2.8 2.7-5.7 5.3-7.9 8.4s-5.1 5.3-8.4 7c-2.7 1.4-5.2 3-7.2 5.3-1.7 2-3.2 4.1-3.2 6.9 0 2.5-.6 4.8-1.6 7.1-1.1 2.4-1.6 5.1-2.1 7.7-.3 1.3 0 2.1 1.2 2.8 1.4.9 2.7 1.9 3.8 3.2 1.7 2 3.1 2.8 6.2 1.7 1.9-.7 3.6-1.5 5.1-2.7.8-.6 1.6-1.1 2.5-1.5 1.4-.5 2.5-1.5 3.4-2.6 1-1 1.8-2.2 2.7-3.2 1-1 1.8-2.3 2.3-3.6.4-.9 1-1.5 1.9-2 1.3-.8 2.7-.7 4.1-.9 1.6-.3 2.6.4 3.4 1.9 1.8 3.6 4.3 6.8 7.4 9.4 1.8 1.5 1.6 1.7.8 3.6-3.3 7.8-8.4 14.3-14.7 20-6.8 6.3-14.7 11-23.2 14.6-15.2 6.5-31.2 9-47.9 9.7 0 0-.4 0-.8-.2z',
        },
        {
          name: BODYPARTS.Upper_Left_Flank_L,
          path:
            'M374.4 300.8c-1.6 6.2-2.2 12.6-3.1 18.9-1.3 9.1-2.4 18.3-3.7 27.4-1.1 7.5-1.7 15.1-2.6 22.6-.4 3.6-.7 7.2-1.4 10.8-.5 2.7-2.4 3.5-4.7 1.8-4.6-3.4-9-7.1-13.2-11.1-6.9-6.6-13.5-13.5-19.4-21-8.6-11-16.2-22.6-22.8-34.9-3-5.7-5.7-11.4-8.3-17.2-2.3-5.3-4.5-10.6-6.3-16-.7-2.2-.6-4.3.7-6.3 1.2-1.8 2.3-3.8 3.3-5.8 4.9-9.6 8.4-19.7 10.8-30.2 2.7-11.9 4.1-23.9 4.3-36.1.1-5 .1-9.9-.2-14.9-.2-4.5-.6-9-1.1-13.4-.4-3.4-1-6.8-1.3-10.2-.2-3.1 1-4.1 4-4.2 3.9-.1 7.7.3 11.6.1 6-.2 12-.6 17.9-1.5 10.8-1.7 21.2-4.8 30.9-10.2 1.1-.6 2.1-1.4 3.4-1.7 1.4-.3 2.7.2 3.3 1.5.3.6.5 1.2.7 1.9 1.8 7.2 1.9 14.5 1.2 21.8-.9 9-3 17.7-5.7 26.3-3.2 10.1-7.7 19.6-11.9 29.3-1.9 4.3-3.7 8.8-4.8 13.4-1.1 4.3-1 8.6-.7 13 .4 6.1 1.8 11.9 4.5 17.4 3.7 7.4 8.4 14.2 13.3 20.8 1.1 1.4 1.6 2.9 1.5 4.6.1.9 0 1.8-.2 3.1z',
        },
      ],
      path:
        'M939.3 859.4c-1.7-1.1-2.7-1.7-3.7-2.3-18.6-14.8-35.6-31.2-50.9-49.3-10.6-12.7-22.6-23.2-38.7-28.5-21.7-7.2-43.2-5.1-64.6.9-27.3 7.6-51.5 21.6-75.4 36.3-3.7 2.3-7.4 4.6-11.4 7.1-3-19.1-3.5-37.4-1.5-55.9 1.3-11.7 2.8-23.6 1.8-35.2-1.3-14.6-3.1-29.1-1.6-43.8.2-1.6 0-3.4-.5-4.9-8.4-23.1-6.6-46.3 2.4-68.3 11.9-29.2 25.9-57.5 39-86.2 1.5-3.2 3.1-6.3 4.1-9.6 7.5-24.8 19.2-47 36.5-66.6 7.1-8.1 14.8-14 24.2-18.3 5.4-2.5 10.9-4.9 16.4-7.1 30.7-12.2 62.8-17.9 95.6-19.9 19.2-1.2 37.3 3 54.7 10.7 2.3 1 4.6 3.4 5.7 5.7 7.3 14.9 14.9 29.7 21.3 45 4.5 10.8 7.7 22.2 10.5 33.6 1.9 7.5 2.3 15.4 3.1 23.2 1.1 9.9 2.8 19.8 2.7 29.7-.2 13.6-1.1 27.3-3 40.8-3 21.7-9 42.6-22.4 60.6-1.1 1.6-1.8 3.6-2.3 5.5-6.7 23.1-12.9 46.4-20.1 69.3-8.1 25.9-13.1 52.4-16 79.4-1.7 15.8-3.9 31.6-5.9 48.1z',
    },
    {
      name: BODYPARTS.Hip_Region_L,
      viewBox: '0 0 573.1 400.3',
      background: imgTorsoLowSrc,
      // sub areas displayed when zooming in
      areas: [
        {
          name: BODYPARTS.Left_Gluteal_BL,
          path:
            'M465.6 254.8c-.2 5.1-.7 10.2-1.3 15.2-1.2 10.5-3.1 20.8-5.8 30.9-1.7 6.3-3.4 12.5-5.7 18.5-3.8 10-8.4 19.6-14.3 28.6-2 3.1-4.2 6.2-6.5 9.1-.9 1.1-1.9 1.5-3.4 1.2-2.2-.5-4.4-1-6.6-1.6-9.7-2.5-18.8-6.4-27.3-11.6-9.9-6-18.4-13.5-26.1-22.1-6.6-7.3-12.3-15.3-17.5-23.7-8.3-13.5-15.1-27.9-21.1-42.6-5.7-13.9-10.5-28.2-15.4-42.4-4.7-13.7-9.1-27.5-13.4-41.4-3.2-10.1-6.3-20.3-9.3-30.5-4.3-14.2-8.3-28.4-12.3-42.7-4.1-14.8-8.1-29.6-11.9-44.4-.3-1.2-.9-2.5.6-3.5.1 0 .1-.2.1-.3.1-1.2.8-1.6 1.9-1.7 5.9-.8 11.8-.9 17.7-1.1 4.8-.2 9.7-.1 14.6.1 5.1.3 10.3.6 15.5 1 9 .8 17.8 2.1 26.6 3.8 13.1 2.6 26 6.2 38.5 10.9 10.1 3.8 19.8 8.2 29.2 13.4.8.5 1.6 1.1 1.8 2.1.2 1.2.8 2.1 1.5 3.1 10.2 14.7 18.6 30.4 25.7 46.7 4.9 11.1 9.1 22.4 12.7 34 4.7 15.4 8.1 31.2 10.1 47.2 1.3 10 1.8 20 1.9 30.1 0 4.6-.3 9.1-.5 13.7z',
        },
      ],
      path:
        'M709.3 1050.1c6.1 28.5 9.1 61.3 11.1 90.2.4 6.8 2.4 13.4 3.2 20.2.8 6.2 1.7 12.5 1.3 18.7-.9 16.8-2.6 33.5-4 50.9.5-.2 1.1-.3 1.6-.5 40.5-12.4 81-20.7 123-14.5 47.3 7.1 111.3 28.4 118.3 38.5.1-.1.1-.2.2-.3 6.5-9.4 13.5-18.6 19.2-28.5 11.4-19.7 16.3-41.4 18.2-63.8 4.6-54.3-11.2-103.1-40.1-148.3-1.2-1.9-3.4-3.7-5.5-4.6-31.9-13.4-65.1-20.9-99.8-20.5-56 .7-98.3 19.1-146.7 62.5z',
    },
  ],
};
