import Session from './session';

import { mapPatientListData, mapSummaryData } from './dataMapper';
import { ROLE } from '../constants';

const { apiUrl } = window['_env_'];
const ENDPOINT = `https://${apiUrl}/medvice/rest/api/v1`;

export class RequestError extends Error {}
export class ServerError extends Error {}
export class UnAuthorizedError extends RequestError {}
export class NotFoundError extends RequestError {}

function makeCommonHeaders({ auth = true, headers = {} }) {
  const result = {
    Accept: 'application/json',
  };
  if (auth) {
    result.Authorization = 'Bearer ' + Session.authToken;
  }
  Object.assign(result, headers);
  return result;
}

const Net = {
  async get(path, options = {}) {
    return await fetch(path, {
      headers: makeCommonHeaders(options),
    }).then(parseResponseBody(options));
  },
  async post(path, body, options = {}) {
    return await fetch(path, {
      method: 'POST',
      headers: Object.assign(makeCommonHeaders(options), {
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(body),
    }).then(parseResponseBody(options));
  },
  async patch(path, body, options = {}) {
    return await fetch(path, {
      method: 'PATCH',
      headers: Object.assign(makeCommonHeaders(options), {
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(body),
    })
      .then(parseResponseBody(options))
      .catch((err) => {
        throw err;
      });
  },
  async delete(path, options = {}) {
    return await fetch(path, {
      method: 'DELETE',
      headers: makeCommonHeaders(options),
    }).then(parseResponseBody(options));
  },
};

const parseResponseBody = ({ parseBody = true }) => async (response) => {
  switch (response.status) {
    case 401:
      throw new UnAuthorizedError();
    case 404:
      throw new NotFoundError();
    default:
      let firstNum = response.status.toString()[0];
      if (firstNum === '4') {
        throw new RequestError();
      } else if (firstNum === '5') {
        throw new ServerError();
      }
      break;
  }
  if (parseBody) {
    var contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return await response.json();
    } else {
      return await response.text();
    }
  }
};

export const LoginApi = {
  async signIn(username, password) {
    const result = await Net.post(
      `${ENDPOINT}/login`,
      { username, password },
      { auth: false },
    );
    if (result) {
      if (result.token) {
        // save token to session
        Session.authToken = result.token;
      }
      if (result.role) {
        // save role to session
        Session.role = result.role;
      }
    }
  },
  logOut() {
    Session.authToken = null;
    Session.role = null;
  },
  async checkLogin() {
    try {
      if (Session.authToken) {
        await Net.get(`${ENDPOINT}/login/check`, { parseBody: false });
        return true;
      }
    } catch (err) {
      if (!(err instanceof UnAuthorizedError)) {
        throw err;
      }
    }
    return false;
  },
};

class ResourceApi {
  constructor(resourceName) {
    this.resourceName = resourceName;
  }
  findAll = async () => {
    return await Net.get(`${ENDPOINT}/${this.resourceName}`);
  };
  find = async (id) => {
    return await Net.get(`${ENDPOINT}/${this.resourceName}/${id}`);
  };
  create = async (data) => {
    return await Net.post(`${ENDPOINT}/${this.resourceName}`, data);
  };
  update = async (id, data) => {
    return await Net.patch(`${ENDPOINT}/${this.resourceName}/${id}`, data);
  };
  delete = async (id) => {
    return await Net.delete(`${ENDPOINT}/${this.resourceName}/${id}`);
  };
}

export const ComplaintsApi = new ResourceApi('complaints');

export const PatientApi = {
  async create({
    username,
    date_of_birth,
    first_name,
    gender,
    height,
    last_name,
    weight,
  }) {
    const body = {
      date_of_birth, // 'YYYY-MM-DD'
      email: null,
      first_name,
      gender,
      height: parseFloat(height),
      last_name,
      password: null,
      role: ROLE.PATIENT,
      username,
      weight: parseFloat(weight),
    };

    return await Net.post(`${ENDPOINT}/account/create`, body, { auth: true });
  },
  async findBySsn(ssn) {
    return await Net.post(
      `${ENDPOINT}/account/from/username`,
      {
        username: ssn,
        role: ROLE.PATIENT,
      },
      { auth: true },
    );
  },
};

export const SummaryApi = {
  async waitList() {
    const rawList = await Net.get(
      `${ENDPOINT}/patientslist`,
      {},
      { auth: true },
    );

    const mappedList = rawList.map(mapPatientListData);
    return mappedList;
  },

  async pastConsults() {
    const result = await Net.get(
      `${ENDPOINT}/summary/consulted`,
      {},
      { auth: true },
    );
    return result;
  },

  async find(id) {
    const rawItem = await Net.get(
      `${ENDPOINT}/summary/detailed/${id}`,
      {},
      { auth: true },
    );

    return mapSummaryData(rawItem);
  },

  async startConsult(id) {
    return await Net.post(
      `${ENDPOINT}/summary/${id}/consult`,
      {},
      {
        auth: true,
      },
    );
  },

  async finishConsult(id, body) {
    return await Net.post(`${ENDPOINT}/summary/${id}/terminate`, body, {
      auth: true,
    });
  },

  async updateConsult(id, body) {
    return await Net.post(`${ENDPOINT}/summary/${id}/terminate`, body, {
      auth: true,
    });
  },
};

export const ICPCApi = {
  async list() {
    return await Net.get(`${ENDPOINT}/icpcs`, {}, { auth: true });
  },
};

export const JobApi = {
  async syncTablet({ tabletId, patientId, complaintId }) {
    return await Net.post(
      `${ENDPOINT}/jobs`,
      {
        type: 'MIA',
        target: tabletId,
        account: patientId,
        complaint_id: complaintId,
      },
      { auth: true },
    );
  },
};

export const TabletApi = {
  async list() {
    return await Net.get(`${ENDPOINT}/accounts/tablets`, {}, { auth: true });
  },
};
