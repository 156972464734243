import React, { useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';

import Card from '../../../widgets/Card';
import Label from '../../misc/Label';
import ErrorText from '../../misc/ErrorText';

import styles from './TextField.module.scss';

const TextField = forwardRef((props, ref) => {
  const {
    label,
    value: propValue,
    onChange = () => null,
    onBlur,
    onFocus,
    type = 'text',
    disabled = false,
    error,
    fullWidth = false,
    className,
    inputClassName = null,
    labelWrapperClassName = null,
    keepErrorSpace = false,
    disableClearButton = false,
    cardProps: cardExtraProps = {},
    placeholder,
    inputComponent: InputComponent = 'input',
    readOnly = false,
    ...rest
  } = props;
  const [ctrl, setCtrl] = useState(typeof propValue !== 'undefined');
  const [stateValue, setStateValue] = useState('');
  useEffect(() => {
    let type = typeof propValue;
    if (ctrl && type === 'undefined') {
      console.warn(
        'TextField - changing controlled input to uncontrolled mode',
      );
      setCtrl(false);
    } else if (!ctrl && type !== 'undefined') {
      console.warn(
        'TextField - changing uncontrolled input to controlled mode',
      );
      setCtrl(true);
    }
  }, [propValue, ctrl]);
  const handleChange = (e) => {
    const newValue = e.target.value;
    onChange(newValue);
    setStateValue(newValue);
  };
  const handleClear = () => {
    onChange('');
    setStateValue('');
  };
  const value = ctrl ? propValue : stateValue;
  const inputProps = {
    value,
    placeholder,
    type,
    disabled,
    onChange: handleChange,
    onBlur,
    onFocus,
    className: inputClassName,
    readOnly,
  };
  const { className: cardExtraClassName, ...cardProps } = cardExtraProps;
  cardProps.className = classNames(styles.inputWrapper, cardExtraClassName);
  const showClearButton = !disableClearButton && !!value;
  return (
    <div
      className={classNames(
        className,
        styles.textField,
        fullWidth && styles.fullWidth,
        disabled && styles.disabled,
        showClearButton && styles.withClearSign,
      )}
      {...rest}>
      <label className={labelWrapperClassName}>
        <Label className={styles.label}>{label}</Label>
        <Card {...cardProps}>
          <InputComponent ref={ref} {...inputProps} />
          <span
            className={styles.clearSign}
            onClick={handleClear}
            tabIndex="-1">
            &times;
          </span>
        </Card>
        <ErrorText keepSpace={keepErrorSpace}>{error}</ErrorText>
      </label>
    </div>
  );
});

export default TextField;
