const LOCALIZATION_ANSWER_NAME = 'Localization';

export const mapPatientListData = (backendData) => {
  const response = {
    id: backendData.summary_id,
    complaintName: backendData.complaint_name,
    deviceId: backendData.device_id,
    gender: backendData.gender,
    birthDate: backendData.dob,
    firstName: backendData.first_name,
    lastName: backendData.last_name,
    miaUseTime: backendData.duration || 0,
    patientId: backendData.username,
    status: backendData.status,
  };

  return response;
};

export const mapSummaryData = (backendData) => {
  let complaintName = '';

  if (backendData.main_complaint) {
    complaintName = backendData.main_complaint.name;
  }

  let otherComplaints = '';
  if (backendData.complaints && backendData.complaints.length > 0) {
    otherComplaints = backendData.complaints.map((c) => c.name).join(', ');
  }

  const { answers } = backendData;
  const localizationAnswer = answers.find(
    ({ question: { template } }) => template === LOCALIZATION_ANSWER_NAME,
  );
  let dummySelection = null;
  if (localizationAnswer) {
    dummySelection = JSON.parse(localizationAnswer.response);
  }

  let ICPCCode = backendData.icpc_relation.icpc;
  if (ICPCCode.id === 0) {
    ICPCCode = null;
  }

  const response = {
    id: backendData.id,
    birthDate: backendData.account.date_of_birth,
    complaintName,
    deviceId: backendData.device_id,
    firstName: backendData.account.first_name,
    freeText: backendData.main_comment,
    gender: backendData.account.gender,
    height: backendData.account.height,
    helpingQuestion: backendData.patient_comment,
    ICPCCode,
    lastName: backendData.account.last_name,
    dummySelection,
    otherComplaints,
    patientId: backendData.account.username || '',
    miaUseTime: backendData.duration || 0,
    status: backendData.status,
    summary: backendData.sumup,
    weight: backendData.account.weight,
  };

  return response;
};
