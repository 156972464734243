import BODYPARTS from './bodyparts';

export default {
  en: {
    [BODYPARTS.Body_F]: 'Body (Front)',
    [BODYPARTS.Body_B]: 'Body (Back)',
    [BODYPARTS.Body_L]: 'Body (Left)',
    [BODYPARTS.Body_R]: 'Body (Right)',
    [BODYPARTS.Right_Arm_RF]: 'Right Arm (Front side)',
    [BODYPARTS.Medial_Elbow_RF]: 'Right Inner Elbow',
    [BODYPARTS.Lateral_Elbow_RF]: 'Right Outer Elbow',
    [BODYPARTS.Biceps_RF]: 'Right Upper Arm',
    [BODYPARTS.Hand_RF]: 'Right Hand',
    [BODYPARTS.Ventral_Lower_Arm_RF]: 'Right Lower Arm',
    [BODYPARTS.Right_Arm_RB]: 'Right Arm (Front side)',
    [BODYPARTS.Olecranon_RB]: 'Right Elbow',
    [BODYPARTS.Medial_Elbow_RB]: 'Right Inner Elbow',
    [BODYPARTS.Lateral_Elbow_RB]: 'Right Outer Elbow',
    [BODYPARTS.Triceps_RB]: 'Right Upper Arm (Back side)',
    [BODYPARTS.Hand_RB]: 'Right Hand',
    [BODYPARTS.Dorsal_Lower_Arm_RB]: 'Right Lower Arm (Back side)',
    [BODYPARTS.Left_Arm_LF]: 'Left_Arm_LF',
    [BODYPARTS.Medial_Elbow_LF]: 'Left Inner Elbow',
    [BODYPARTS.Lateral_Elbow_LF]: 'Left Outer Elbow',
    [BODYPARTS.Biceps_LF]: 'Left Upper Arm',
    [BODYPARTS.Hand_LF]: 'Left Hand',
    [BODYPARTS.Ventral_Lower_Arm_LF]: 'Left Lower Arm',
    [BODYPARTS.Left_Arm_LB]: 'Left Arm (Back side)',
    [BODYPARTS.Olecranon_LB]: 'Left Elbow',
    [BODYPARTS.Medial_Elbow_LB]: 'Left Inner Elbow',
    [BODYPARTS.Lateral_Elbow_LB]: 'Left Outer Elbow',
    [BODYPARTS.Triceps_LB]: 'Left Upper Arm (Back side)',
    [BODYPARTS.Hand_LB]: 'Left Hand',
    [BODYPARTS.Dorsal_Lower_Arm_LB]: 'Left Lower Arm (Back side)',
    [BODYPARTS.Right_Leg_RF]: 'Right Leg (Front side)',
    [BODYPARTS.Upper_Leg_RF]: 'Right Upper Leg',
    [BODYPARTS.Lower_Leg_RF]: 'Right Shin',
    [BODYPARTS.Knee_RF]: 'Right Knee',
    [BODYPARTS.Foot_RF]: 'Right Foot (Front side)',
    [BODYPARTS.Medial_Thigh_RF]: 'Right Inner Thigh',
    [BODYPARTS.Right_Leg_RB]: 'Right Leg (Back side)',
    [BODYPARTS.Hamstrings_RB]: 'Right Hamstrings',
    [BODYPARTS.Foot_RB]: 'Right Foot (Back side)',
    [BODYPARTS.Popliteal_Region_RB]: 'Right Knee (Back side)',
    [BODYPARTS.Calve_RB]: 'Right Calve',
    [BODYPARTS.Right_Leg_RM]: 'Right Inner Leg',
    [BODYPARTS.Medial_Thigh_RM]: 'Right Inner Thigh',
    [BODYPARTS.Medial_Knee_RM]: 'Right Inner Knee',
    [BODYPARTS.Medial_Upper_Leg_RM]: 'Right Upper Leg',
    [BODYPARTS.Medial_Hamstrings_RM]: 'Right Hamstrings',
    [BODYPARTS.Knee_RM]: 'Right Knee',
    [BODYPARTS.Politea_RM]: 'Right Knee (Back side)',
    [BODYPARTS.Calve_RM]: 'Right Calve',
    [BODYPARTS.Medial_Lower_Leg_RM]: 'Right Inner Shin',
    [BODYPARTS.Foot_RM]: 'Right Foot RM',
    [BODYPARTS.Right_Leg_RL]: 'Right Outer Leg',
    [BODYPARTS.Lateral_Thigh_RL]: 'Right Outer Thigh',
    [BODYPARTS.Lateral_Knee_RL]: 'Right Outer Knee',
    [BODYPARTS.Lateral_Upper_Leg_RL]: 'Right Upper Leg',
    [BODYPARTS.Lateral_Hamstrings_RL]: 'Right Hamstrings',
    [BODYPARTS.Knee_RL]: 'Right Knee',
    [BODYPARTS.Politea_RL]: 'Right Knee (Back side)',
    [BODYPARTS.Calve_RL]: 'Right Calve',
    [BODYPARTS.Lateral_Lower_Leg_RL]: 'Right Outer Shin',
    [BODYPARTS.Foot_RL]: 'Right Foot RL',
    [BODYPARTS.Left_Leg_LF]: 'Left Leg (Front side)',
    [BODYPARTS.Upper_Leg_LF]: 'Left Upper Leg',
    [BODYPARTS.Lower_Leg_LF]: 'Left Shin',
    [BODYPARTS.Knee_LF]: 'Left Knee (Front Side)',
    [BODYPARTS.Foot_LF]: 'Left Foot (Front side)',
    [BODYPARTS.Medial_Thigh_LF]: 'Left Inner Thigh',
    [BODYPARTS.Left_Leg_LB]: 'Left Leg (Back side)',
    [BODYPARTS.Hamstrings_LB]: 'Left Hamstrings',
    [BODYPARTS.Foot_LB]: 'Left Foot (Back side)',
    [BODYPARTS.Popliteal_Region_LB]: 'Left Knee (Back side)',
    [BODYPARTS.Calve_LB]: 'Left Calve (Back Side)',
    [BODYPARTS.Left_Leg_LM]: 'Left Inner Leg',
    [BODYPARTS.Medial_Thigh_LM]: 'Left Inner Thigh',
    [BODYPARTS.Medial_Knee_LM]: 'Left Inner Knee',
    [BODYPARTS.Medial_Upper_Leg_LM]: 'Left Upper Leg',
    [BODYPARTS.Medial_Hamstrings_LM]: 'Left Hamstrings',
    [BODYPARTS.Knee_LM]: 'Left Knee',
    [BODYPARTS.Politea_LM]: 'Left Knee (Back side)',
    [BODYPARTS.Calve_LM]: 'Left Calve',
    [BODYPARTS.Medial_Lower_Leg_LM]: 'Left Inner Shin',
    [BODYPARTS.Foot_LM]: 'Left Foot',
    [BODYPARTS.Left_Leg_LL]: 'Left Outer Leg',
    [BODYPARTS.Lateral_Thigh_LL]: 'Left Outer Thigh',
    [BODYPARTS.Lateral_Knee_LL]: 'Left Outer Knee',
    [BODYPARTS.Lateral_Upper_Leg_LL]: 'Left Upper Leg',
    [BODYPARTS.Lateral_Hamstrings_LL]: 'Left Hamstrings',
    [BODYPARTS.Knee_LL]: 'Left Knee (Left Side)',
    [BODYPARTS.Politea_LL]: 'Left Knee (Left side)',
    [BODYPARTS.Calve_LL]: 'Left Calve (Left Side)',
    [BODYPARTS.Lateral_Lower_Leg_LL]: 'Left Outer Shin',
    [BODYPARTS.Foot_LL]: 'Left Foot',
    [BODYPARTS.Right_Foot_RF]: 'Right Foot (Front side)',
    [BODYPARTS.Right_Hallux_1_RF]: 'Right Big Toe',
    [BODYPARTS.Right_Hallux_2_RF]: 'Right Second Toe',
    [BODYPARTS.Right_Hallux_3_RF]: 'Right Middle Toe',
    [BODYPARTS.Right_Hallux_4_RF]: 'Right Fourth Toe',
    [BODYPARTS.Right_Hallux_5_RF]: 'Right Little Toe',
    [BODYPARTS.Right_Forefoot_RF]: 'Right Forefoot',
    [BODYPARTS.Right_Ankle_RF]: 'Right Front Ankle',
    [BODYPARTS.Right_Foot_RB]: 'Right Foot (Back side)',
    [BODYPARTS.Right_Achillesheel_RB]: 'Right Achillesheel',
    [BODYPARTS.Right_Lateral_Ankle_RB]: 'Right Outer Ankle',
    [BODYPARTS.Right_Medial_Ankle_RB]: 'Right Inner Ankle',
    [BODYPARTS.Right_Heel_RB]: 'Right Heel',
    [BODYPARTS.Right_Sole_RB]: 'Right Sole',
    [BODYPARTS.Right_Foot_RM]: 'Right Foot (Inner side)',
    [BODYPARTS.Right_Medial_Ankle_RM]: 'Right Inner Ankle',
    [BODYPARTS.Right_Medial_Foot_RM]: 'Right Inner Foot',
    [BODYPARTS.Right_Heel_RM]: 'Right Heel',
    [BODYPARTS.Right_Sole_RM]: 'Right Sole',
    [BODYPARTS.Right_Forefoot_RM]: 'Right Inner Forefoot',
    [BODYPARTS.Right_Foot_RL]: 'Right Foot (Outer side)',
    [BODYPARTS.Right_Lateral_Ankle_RL]: 'Right Inner Ankle',
    [BODYPARTS.Right_Lateral_Foot_RL]: 'Right Inner Foot',
    [BODYPARTS.Right_Heel_RL]: 'Right Heel',
    [BODYPARTS.Right_Sole_RL]: 'Right Sole',
    [BODYPARTS.Right_Forefoot_RL]: 'Right Inner Forefoot',
    [BODYPARTS.Left_Foot_LF]: 'Left Foot (Front side)',
    [BODYPARTS.Left_Hallux_1_LF]: 'Left Big Toe',
    [BODYPARTS.Left_Hallux_2_LF]: 'Left Second Toe',
    [BODYPARTS.Left_Hallux_3_LF]: 'Left Middle Toe',
    [BODYPARTS.Left_Hallux_4_LF]: 'Left Fourth Toe',
    [BODYPARTS.Left_Hallux_5_LF]: 'Left Little Toe',
    [BODYPARTS.Left_Forefoot_LF]: 'Left Forefoot',
    [BODYPARTS.Left_Front_Ankle_LF]: 'Left Front Ankle',
    [BODYPARTS.Left_Foot_LB]: 'Left Foot (Back side)',
    [BODYPARTS.Left_Achillesheel_LB]: 'Left Achillesheel',
    [BODYPARTS.Left_Lateral_Ankle_LB]: 'Left Outer Ankle',
    [BODYPARTS.Left_Medial_Ankle_LB]: 'Left Inner Ankle',
    [BODYPARTS.Left_Heel_LB]: 'Left Heel',
    [BODYPARTS.Left_Sole_LB]: 'Left Sole (Back side)',
    [BODYPARTS.Left_Foot_LM]: 'Left Foot (Inner side)',
    [BODYPARTS.Left_Medial_Ankle_LM]: 'Left Inner Ankle',
    [BODYPARTS.Left_Medial_Foot_LM]: 'Left Inner Foot',
    [BODYPARTS.Left_Heel_LM]: 'Left Heel',
    [BODYPARTS.Left_Sole_LM]: 'Left Sole',
    [BODYPARTS.Left_Forefoot_LM]: 'Left Inner Forefoot',
    [BODYPARTS.Left_Foot_LL]: 'Left Foot (Outer side)',
    [BODYPARTS.Left_Lateral_Ankle_LL]: 'Left Inner Ankle',
    [BODYPARTS.Left_Lateral_Foot_LL]: 'Left Inner Foot',
    [BODYPARTS.Left_Heel_LL]: 'Left Heel',
    [BODYPARTS.Left_Sole_LL]: 'Left Sole (Left side)',
    [BODYPARTS.Left_Forefoot_LL]: 'Left Inner Forefoot',
    [BODYPARTS.Left_Hand_LF]: 'Left Hand (Front side)',
    [BODYPARTS.Left_Thumb_LF]: 'Left Thumb',
    [BODYPARTS.Left_Index_Finger_LF]: 'Left Index Finger',
    [BODYPARTS.Left_Middle_Finger_LF]: 'Left Middle Finger',
    [BODYPARTS.Left_Ring_Finger_LF]: 'Left Ring Finger',
    [BODYPARTS.Left_Pink_LF]: 'Left Pink',
    [BODYPARTS.Left_Palm_LF]: 'Left Palm',
    [BODYPARTS.Left_Wrist_LF]: 'Left Wrist',
    [BODYPARTS.Left_Hand_LB]: 'Left Hand (Back side)',
    [BODYPARTS.Left_Thumb_LB]: 'Left Thumb (Back side)',
    [BODYPARTS.Left_Index_Finger_LB]: 'Left Index Finger (Back side)',
    [BODYPARTS.Left_Middle_Finger_LB]: 'Left Middle Finger (Back side)',
    [BODYPARTS.Left_Ring_Finger_LB]: 'Left Ring Finger (Back side)',
    [BODYPARTS.Left_Pink_LB]: 'Left Pink (Back side)',
    [BODYPARTS.Left_Back_Hand_LB]: 'Left Back Hand',
    [BODYPARTS.Left_Wrist_LB]: 'Left Wrist (Back side)',
    [BODYPARTS.Right_Hand_RF]: 'Right Hand (Front side)',
    [BODYPARTS.Right_Thumb_RF]: 'Right Thumb',
    [BODYPARTS.Right_Index_Finger_RF]: 'Right Index Finger',
    [BODYPARTS.Right_Middle_Finger_RF]: 'Right Middle Finger',
    [BODYPARTS.Right_Ring_Finger_RF]: 'Right Ring Finger',
    [BODYPARTS.Right_Pink_RF]: 'Right Pink',
    [BODYPARTS.Right_Palm_RF]: 'Right Palm',
    [BODYPARTS.Right_Wrist_RF]: 'Right Wrist',
    [BODYPARTS.Right_Hand_RB]: 'Right Hand (Back side)',
    [BODYPARTS.Right_Thumb_RB]: 'Right Thumb (Back side)',
    [BODYPARTS.Right_Index_Finger_RB]: 'Right Index Finger (Back side)',
    [BODYPARTS.Right_Middle_Finger_RB]: 'Right Middle Finger (Back side)',
    [BODYPARTS.Right_Ring_Finger_RB]: 'Right Ring Finger (Back side)',
    [BODYPARTS.Right_Pink_RB]: 'Right Pink (Back side)',
    [BODYPARTS.Right_Back_Hand_RB]: 'Right Back Hand',
    [BODYPARTS.Right_Wrist_RB]: 'Right Wrist (Back side)',
    [BODYPARTS.Head_F]: 'Head (Front side)',
    [BODYPARTS.Head_Top_F]: 'Top Head',
    [BODYPARTS.Throat_F]: 'Throat',
    [BODYPARTS.Chin_F]: 'Chin',
    [BODYPARTS.Neck_Left_F]: 'Left Neck',
    [BODYPARTS.Neck_Right_F]: 'Right Neck',
    [BODYPARTS.Jaw_Left_F]: 'Left Jaw',
    [BODYPARTS.Jaw_Right_F]: 'Right Jaw',
    [BODYPARTS.Eye_Left_F]: 'Left Eye',
    [BODYPARTS.Eye_Right_F]: 'Right Eye',
    [BODYPARTS.Ear_Left_F]: 'Left Ear',
    [BODYPARTS.Ear_Right_F]: 'Right Ear',
    [BODYPARTS.Cheek_Left_F]: 'Left Cheek',
    [BODYPARTS.Cheek_Right_F]: 'Right Cheek',
    [BODYPARTS.Mouth_F]: 'Mouth',
    [BODYPARTS.Nose_F]: 'Nose',
    [BODYPARTS.Lower_Neck_Left_F]: 'Left Lower Neck',
    [BODYPARTS.Lower_Neck_Right_F]: 'Right Lower Neck',
    [BODYPARTS.Forehead_F]: 'Forehead',
    [BODYPARTS.Head_B]: 'Head_B',
    [BODYPARTS.Backside_Head_B]: 'Backside of Head (Back side)',
    [BODYPARTS.Head_Top_B]: 'Top Head (Back side)',
    [BODYPARTS.Ear_Left_B]: 'Left Ear',
    [BODYPARTS.Ear_Right_B]: 'Right Ear',
    [BODYPARTS.Neck_Left_B]: 'Left Neck (Back side)',
    [BODYPARTS.Neck_Right_B]: 'Right Neck (Back side)',
    [BODYPARTS.Cervical_neck_B]: 'Neck Spine',
    [BODYPARTS.Lower_Neck_Left_B]: 'Left Lower Neck (Back side)',
    [BODYPARTS.Lower_Neck_Right_B]: 'Right Lower Neck (Back side)',
    [BODYPARTS.Head_L]: 'Head (Left side)',
    [BODYPARTS.Mastoid_L]: 'Behind the Left Ear',
    [BODYPARTS.Backside_Head_L]: 'Backside of Head (Left side)',
    [BODYPARTS.Head_Top_L]: 'Top Head',
    [BODYPARTS.Chin_L]: 'Chin',
    [BODYPARTS.Neck_Frontside_L]: 'Left Neck (Front side)',
    [BODYPARTS.Jaw_L]: 'Left Jaw',
    [BODYPARTS.Eye_L]: 'Left Eye',
    [BODYPARTS.Ear_L]: 'Left Ear',
    [BODYPARTS.Temporal_L]: 'Left Temple',
    [BODYPARTS.Cheek_L]: 'Left Cheek',
    [BODYPARTS.Mouth_L]: 'Mouth',
    [BODYPARTS.Neck_Backside_L]: 'Left Neck (Back side)',
    [BODYPARTS.Nose_L]: 'Nose',
    [BODYPARTS.Lower_Neck_L]: 'Left Lower Neck',
    [BODYPARTS.Throat_L]: 'Throat (Left side)',
    [BODYPARTS.Preauricular_L]: 'Front side of Left Ear',
    [BODYPARTS.Forehead_L]: 'Forehead (Left side)',
    [BODYPARTS.Head_R]: 'Head (Right side)',
    [BODYPARTS.Mastoid_R]: 'Behind the Right Ear',
    [BODYPARTS.Backside_Head_R]: 'Backside of Head (Right side)',
    [BODYPARTS.Head_Top_R]: 'Top Head',
    [BODYPARTS.Chin_R]: 'Chin',
    [BODYPARTS.Neck_Frontside_R]: 'Right Neck (Front side)',
    [BODYPARTS.Jaw_R]: 'Right Jaw',
    [BODYPARTS.Eye_R]: 'Right Eye',
    [BODYPARTS.Ear_R]: 'Right Ear',
    [BODYPARTS.Temporal_R]: 'Right Temple',
    [BODYPARTS.Cheek_R]: 'Right Cheek',
    [BODYPARTS.Mouth_R]: 'Mouth',
    [BODYPARTS.Neck_Backside_R]: 'Right Neck (Back side)',
    [BODYPARTS.Nose_R]: 'Nose',
    [BODYPARTS.Lower_Neck_R]: 'Right Lower Neck',
    [BODYPARTS.Throat_R]: 'Throat (Right side)',
    [BODYPARTS.Preauricular_R]: 'Front side of Right Ear',
    [BODYPARTS.Forehead_R]: 'Forehead (Right side)',
    [BODYPARTS.Upper_Back_B]: 'Upper Back',
    [BODYPARTS.Upper_Left_Flank_B]: 'Upper Left Flank',
    [BODYPARTS.Upper_Left_Paravertebral_B]: 'Middle Upper Back (Left side)',
    [BODYPARTS.Back_Left_Shoulder_B]: 'Left Shoulder (Back side)',
    [BODYPARTS.Back_Left_Ribcage_B]: 'Left Ribcage (Back side)',
    [BODYPARTS.Left_Shoulder_Blade_B]: 'Left Shoulder Blade',
    [BODYPARTS.Mid_Left_Paravertebral_B]: 'Middle Back (Left side)',
    [BODYPARTS.Upper_Right_Flank_B]: 'UpperFlank Right',
    [BODYPARTS.Upper_Right_Paravertebral_B]: 'Middle Upper Back (Right side)',
    [BODYPARTS.Back_Right_Shoulder_B]: 'Right Shoulder (Back side)',
    [BODYPARTS.Back_Right_Ribcage_B]: 'Right Ribcage (Back side)',
    [BODYPARTS.Right_Shoulder_Blade_B]: 'Right Shoulder Blade',
    [BODYPARTS.Mid_Right_Paravertebral_B]: 'Middle Back (Right side)',
    [BODYPARTS.Upper_Spinal_B]: 'Upper Spine',
    [BODYPARTS.Mid_Spinal_B]: 'Middle Spine',
    [BODYPARTS.Torso_F]: 'Chest',
    [BODYPARTS.Sternum_F]: 'Sternum',
    [BODYPARTS.Left_Lower_Chest_F]: 'Lower Chest Left',
    [BODYPARTS.Left_Upper_Chest_F]: 'Upper Chest Left',
    [BODYPARTS.Left_Front_Shoulder_F]: 'Left Front Shoulder',
    [BODYPARTS.Left_Clavicle_F]: 'Left Clavicle',
    [BODYPARTS.Right_Lower_Chest_F]: 'Lower Chest Right',
    [BODYPARTS.Right_Upper_Chest_F]: 'Upper Chest Right',
    [BODYPARTS.Right_Front_Shoulder_F]: 'Right Front Shoulder',
    [BODYPARTS.Right_Clavicle_F]: 'Right Clavicle',
    [BODYPARTS.Torso_R]: 'Chest (Right side)',
    [BODYPARTS.Right_Upper_Chest_R]: 'Upper Right Chest',
    [BODYPARTS.Upper_Right_Flank_R]: 'Upper Right Flank',
    [BODYPARTS.Right_Shoulder_Blade_R]: 'Right Shoulder Blade',
    [BODYPARTS.Right_Lower_Chest_R]: 'Lower Right Chest',
    [BODYPARTS.Front_Right_Ribcage_R]: 'Rightside Front Ribcage',
    [BODYPARTS.Torso_L]: 'Chest (Left side)',
    [BODYPARTS.Left_Upper_Chest_L]: 'Upper Left Chest',
    [BODYPARTS.Upper_Left_Flank_L]: 'Upper Left Flank',
    [BODYPARTS.Left_Shoulder_Blade_L]: 'Left Shoulder Blade',
    [BODYPARTS.Left_Lower_Chest_L]: 'Lower Left Chest',
    [BODYPARTS.Front_Left_Ribcage_L]: 'Leftside Front Ribcage',
    [BODYPARTS.Abdomen_F]: 'Abdomen / Belly',
    [BODYPARTS.Epigastric_F]: 'Upper abdomen / Stomach',
    [BODYPARTS.Left_Upper_Abdomen_F]: 'Left Upper Belly',
    [BODYPARTS.Right_Upper_Abdomen_F]: 'Right Upper Belly',
    [BODYPARTS.Left_Lower_Abdomen_F]: 'Left Lower Belly',
    [BODYPARTS.Right_Lower_Abdomen_F]: 'Right Lower Belly',
    [BODYPARTS.Umbilical_region_F]: 'Middle Belly',
    [BODYPARTS.Right_Middle_Abdomen_F]: 'Rightside of Belly',
    [BODYPARTS.Left_Middle_Abdomen_F]: 'Leftside of Belly',
    [BODYPARTS.Lumbal_region_B]: 'Lower Back',
    [BODYPARTS.Lumbal_Paravertebral_Left_B]: 'Middle Lowerback (Left side)',
    [BODYPARTS.Lumbal_Paravertebral_Right_B]: 'Middle Lowerback (Right side)',
    [BODYPARTS.Left_SI_Joint_B]: 'Lowerback Pit Left',
    [BODYPARTS.Right_SI_Joint_B]: 'Lowerback Pit Right',
    [BODYPARTS.Left_Lowerback_B]: 'Left Lower Back',
    [BODYPARTS.Right_Lowerback_B]: 'Right Lower Back',
    [BODYPARTS.Left_Flank_B]: 'Left Flank',
    [BODYPARTS.Right_Flank_B]: 'Right Flank',
    [BODYPARTS.Lumbal_spine_B]: 'Lower spine',
    [BODYPARTS.Abdomen_L]: 'Belly (Left side)',
    [BODYPARTS.Left_Upper_Flank_L]: 'Left Upper Flank',
    [BODYPARTS.Left_Middle_Abdomen_L]: 'Left Middle Belly',
    [BODYPARTS.Left_Lower_Flank_L]: 'Left Lower Flank',
    [BODYPARTS.Abdomen_R]: 'Belly (Right side)',
    [BODYPARTS.Right_Upper_Flank_R]: 'Right Upper Flank',
    [BODYPARTS.Right_Middle_Abdomen_R]: 'Right Middle Belly',
    [BODYPARTS.Right_Lower_Flank_R]: 'Right Lower Flank',
    [BODYPARTS.Hip_Region_F]: 'Hip area (Front side)',
    [BODYPARTS.Left_Hipbone_F]: 'Left Hipbone',
    [BODYPARTS.Right_Hipbone_F]: 'Right Hipbone',
    [BODYPARTS.Lower_Abdominal_F]: 'Lower Abdomen',
    [BODYPARTS.Left_Inguinals_F]: 'Left Groin',
    [BODYPARTS.Right_Inguinals_F]: 'Right Groin',
    [BODYPARTS.Pubic_region_F]: 'Pubic area',
    [BODYPARTS.Hip_Region_B]: 'Hip area (Back side)',
    [BODYPARTS.Left_Gluteal_B]: 'Left Buttock',
    [BODYPARTS.Right_Gluteal_B]: 'Right Buttock',
    [BODYPARTS.Perianal_region_B]: 'Anal region',
    [BODYPARTS.Sacral_region_B]: 'Tailbone region',
    [BODYPARTS.Hip_Region_L]: 'Hip area (Left side)',
    [BODYPARTS.Left_Gluteal_BL]: 'Left Buttock',
    [BODYPARTS.Hip_Region_R]: 'Hip area (Right side)',
    [BODYPARTS.Right_Gluteal_BR]: 'Right Buttock',
  },
  nl: {
    [BODYPARTS.Body_F]: 'Lichaam (Voor)',
    [BODYPARTS.Body_B]: 'Lichaam (Achter)',
    [BODYPARTS.Body_L]: 'Lichaam (Links)',
    [BODYPARTS.Body_R]: 'Lichaam (Rechts)',
    [BODYPARTS.Right_Arm_RF]: 'Voorzijde Rechter Arm',
    [BODYPARTS.Medial_Elbow_RF]: 'Binnenzijde Rechter Elleboog',
    [BODYPARTS.Lateral_Elbow_RF]: 'Buitenzijde Rechter Elleboog',
    [BODYPARTS.Biceps_RF]: 'Voorzijde Rechter Bovenarm',
    [BODYPARTS.Hand_RF]: 'Rechter Hand',
    [BODYPARTS.Ventral_Lower_Arm_RF]: 'Voorzijde Rechter Onderarm',
    [BODYPARTS.Right_Arm_RB]: 'Achterzijde Rechter Arm',
    [BODYPARTS.Olecranon_RB]: 'Rechter Elleboog',
    [BODYPARTS.Medial_Elbow_RB]: 'Binnenzijde Rechter Elleboog',
    [BODYPARTS.Lateral_Elbow_RB]: 'Buitenzijde Rechter Elleboog',
    [BODYPARTS.Triceps_RB]: 'Achterzijde Rechter Bovenarm',
    [BODYPARTS.Hand_RB]: 'Rechter Hand',
    [BODYPARTS.Dorsal_Lower_Arm_RB]: 'Achterzijde Rechter Onderarm',
    [BODYPARTS.Left_Arm_LF]: 'Voorzijde Linker Arm',
    [BODYPARTS.Medial_Elbow_LF]: 'Binnenzijde Linker Elleboog',
    [BODYPARTS.Lateral_Elbow_LF]: 'Buitenzijde Linker Elleboog',
    [BODYPARTS.Biceps_LF]: 'Voorzijde Linker Bovenarm',
    [BODYPARTS.Hand_LF]: 'Linker  Hand',
    [BODYPARTS.Ventral_Lower_Arm_LF]: 'Voorzijde Linker Onderarm',
    [BODYPARTS.Left_Arm_LB]: 'Achterzijde Linker Arm',
    [BODYPARTS.Olecranon_LB]: 'Linker Elleboog',
    [BODYPARTS.Medial_Elbow_LB]: 'Binnenzijde Linker Elleboog',
    [BODYPARTS.Lateral_Elbow_LB]: 'Buitenzijde Linker  Elleboog',
    [BODYPARTS.Triceps_LB]: 'Achterzijde Linker Bovenarm',
    [BODYPARTS.Hand_LB]: 'Linker Hand',
    [BODYPARTS.Dorsal_Lower_Arm_LB]: 'Achterzijde Linker Onderarm',
    [BODYPARTS.Right_Leg_RF]: 'Voorzijde Rechter Been',
    [BODYPARTS.Upper_Leg_RF]: 'Voorzijde Rechter Bovenbeen',
    [BODYPARTS.Lower_Leg_RF]: 'Rechter Scheenbeen',
    [BODYPARTS.Knee_RF]: 'Rechter Knie',
    [BODYPARTS.Foot_RF]: 'Rechter Voet',
    [BODYPARTS.Medial_Thigh_RF]: 'Binnenzijde Rechter Dijbeen',
    [BODYPARTS.Right_Leg_RB]: 'Achterzijde Rechter Been',
    [BODYPARTS.Hamstrings_RB]: 'Rechter Hamstrings',
    [BODYPARTS.Foot_RB]: 'Rechter Voet',
    [BODYPARTS.Popliteal_Region_RB]: 'Rechter Knieholte',
    [BODYPARTS.Calve_RB]: 'Rechter Kuit',
    [BODYPARTS.Right_Leg_RM]: 'Binnenzijde Rechter Been',
    [BODYPARTS.Medial_Thigh_RM]: 'Binnenzijde Rechter Dijbeen',
    [BODYPARTS.Medial_Knee_RM]: 'Binnenzijde Rechter Knie',
    [BODYPARTS.Medial_Upper_Leg_RM]: 'Binnenzijde Rechter Bovenbeen',
    [BODYPARTS.Medial_Hamstrings_RM]: 'Binnenzijde Rechter Hamstrings',
    [BODYPARTS.Knee_RM]: 'Rechter Knie',
    [BODYPARTS.Politea_RM]: 'Rechter Knieholte',
    [BODYPARTS.Calve_RM]: 'Rechter Kuit',
    [BODYPARTS.Medial_Lower_Leg_RM]: 'Binnenzijde Rechter Scheenbeen',
    [BODYPARTS.Foot_RM]: 'Rechter Voet',
    [BODYPARTS.Right_Leg_RL]: 'Buitenzijde Rechter Been',
    [BODYPARTS.Lateral_Thigh_RL]: 'Buitenzijde Rechter Dijbeen',
    [BODYPARTS.Lateral_Knee_RL]: 'Buitenzijde Rechter Knie',
    [BODYPARTS.Lateral_Upper_Leg_RL]: 'Buitenzijde Rechter Bovenbeen',
    [BODYPARTS.Lateral_Hamstrings_RL]: 'Buitenzijde Rechter Hamstrings',
    [BODYPARTS.Knee_RL]: 'Rechter Knie',
    [BODYPARTS.Politea_RL]: 'Buitenzijde Rechter Knieholte',
    [BODYPARTS.Calve_RL]: 'Buitenzijde Rechter Kuit',
    [BODYPARTS.Lateral_Lower_Leg_RL]: 'Buitenzijde  Rechter Scheenbeen',
    [BODYPARTS.Foot_RL]: 'Rechter Voet',
    [BODYPARTS.Left_Leg_LF]: 'Voorzijde Linker Been',
    [BODYPARTS.Upper_Leg_LF]: 'Voorzijde Linker Bovenbeen',
    [BODYPARTS.Lower_Leg_LF]: 'Linker Scheenbeen',
    [BODYPARTS.Knee_LF]: 'Linker Knie',
    [BODYPARTS.Foot_LF]: 'Linker Voet',
    [BODYPARTS.Medial_Thigh_LF]: 'Binnenzijde Linker Dijbeen',
    [BODYPARTS.Left_Leg_LB]: 'Achterzijde Linker Been',
    [BODYPARTS.Hamstrings_LB]: 'Linker Hamstrings',
    [BODYPARTS.Foot_LB]: 'Linker Voet',
    [BODYPARTS.Popliteal_Region_LB]: 'Linker Knieholte',
    [BODYPARTS.Calve_LB]: 'Linker Kuit',
    [BODYPARTS.Left_Leg_LM]: 'Binnenzijde Linker Been',
    [BODYPARTS.Medial_Thigh_LM]: 'Binnenzijde Linker Dijbeen',
    [BODYPARTS.Medial_Knee_LM]: 'Binnenzijde Linker Knie',
    [BODYPARTS.Medial_Upper_Leg_LM]: 'Binnenzijde Linker Bovenbeen',
    [BODYPARTS.Medial_Hamstrings_LM]: 'Binnenzijde Linker Hamstrings',
    [BODYPARTS.Knee_LM]: 'Linker Knie',
    [BODYPARTS.Politea_LM]: 'Linker Knieholte',
    [BODYPARTS.Calve_LM]: 'Linker Kuit',
    [BODYPARTS.Medial_Lower_Leg_LM]: 'Binnenzijde Linker  Scheenbeen',
    [BODYPARTS.Foot_LM]: 'Linker Voet',
    [BODYPARTS.Left_Leg_LL]: 'Buitenzijde Linker Been',
    [BODYPARTS.Lateral_Thigh_LL]: 'Buitenzijde Linker Dijbeen',
    [BODYPARTS.Lateral_Knee_LL]: 'Buitenzijde Linker Knie',
    [BODYPARTS.Lateral_Upper_Leg_LL]: 'Buitenzijde Linker Bovenbeen',
    [BODYPARTS.Lateral_Hamstrings_LL]: 'Buitenzijde Linker Hamstrings',
    [BODYPARTS.Knee_LL]: 'Linker Knie',
    [BODYPARTS.Politea_LL]: 'Buitenzijde Linker Knieholte',
    [BODYPARTS.Calve_LL]: 'Buitenzijde Linker Kuit',
    [BODYPARTS.Lateral_Lower_Leg_LL]: 'Buitenzijde  Linker Scheenbeen',
    [BODYPARTS.Foot_LL]: 'Linker Voet',
    [BODYPARTS.Right_Foot_RF]: 'Voorzijde Rechter Voet',
    [BODYPARTS.Right_Hallux_1_RF]: 'Rechter Grote teen',
    [BODYPARTS.Right_Hallux_2_RF]: 'Rechter Tweede teen',
    [BODYPARTS.Right_Hallux_3_RF]: 'Rechter Derde teen',
    [BODYPARTS.Right_Hallux_4_RF]: 'Rechter Vierde teen',
    [BODYPARTS.Right_Hallux_5_RF]: 'Rechter Kleine teen',
    [BODYPARTS.Right_Forefoot_RF]: 'Rechter Voorvoet',
    [BODYPARTS.Right_Ankle_RF]: 'Rechter Enkel',
    [BODYPARTS.Right_Foot_RB]: 'Achterzijde Rechter Voet',
    [BODYPARTS.Right_Achillesheel_RB]: 'Rechter Achilleshiel',
    [BODYPARTS.Right_Lateral_Ankle_RB]: 'Buitenzijde Rechter Enkel',
    [BODYPARTS.Right_Medial_Ankle_RB]: 'Binnenzijde Rechter Enkel',
    [BODYPARTS.Right_Heel_RB]: 'Rechter Hiel',
    [BODYPARTS.Right_Sole_RB]: 'Achterzijde Rechter Voetzool',
    [BODYPARTS.Right_Foot_RM]: 'Binnenzijde Rechter Voet',
    [BODYPARTS.Right_Medial_Ankle_RM]: 'Binnenzijde Rechter Enkel',
    [BODYPARTS.Right_Medial_Foot_RM]: 'Binnenzijde Rechter Voet',
    [BODYPARTS.Right_Heel_RM]: 'Rechter Hiel',
    [BODYPARTS.Right_Sole_RM]: 'Binnenzijde Rechter Voetzool',
    [BODYPARTS.Right_Forefoot_RM]: 'Binnenzijde Rechter Voorvoet',
    [BODYPARTS.Right_Foot_RL]: 'Buitenzijde Rechter Voet',
    [BODYPARTS.Right_Lateral_Ankle_RL]: 'Buitenzijde Rechter Enkel',
    [BODYPARTS.Right_Lateral_Foot_RL]: 'Buitenzijde Rechter Voet',
    [BODYPARTS.Right_Heel_RL]: 'Rechter Hiel',
    [BODYPARTS.Right_Sole_RL]: 'Buitenzijde Rechter Voetzool',
    [BODYPARTS.Right_Forefoot_RL]: 'Buitenzijde Rechter Voorvoet',
    [BODYPARTS.Left_Foot_LF]: 'Voorzijde Linker Voet',
    [BODYPARTS.Left_Hallux_1_LF]: 'Linker Grote teen',
    [BODYPARTS.Left_Hallux_2_LF]: 'Linker Tweede teen',
    [BODYPARTS.Left_Hallux_3_LF]: 'Linker Derde teen',
    [BODYPARTS.Left_Hallux_4_LF]: 'Linker Vierde teen',
    [BODYPARTS.Left_Hallux_5_LF]: 'Linker Kleine teen',
    [BODYPARTS.Left_Forefoot_LF]: 'Linker Voorvoet',
    [BODYPARTS.Left_Front_Ankle_LF]: 'Linker Enkel',
    [BODYPARTS.Left_Foot_LB]: 'Achterzijde Linker Voet',
    [BODYPARTS.Left_Achillesheel_LB]: 'Linker Achilleshiel',
    [BODYPARTS.Left_Lateral_Ankle_LB]: 'Buitenzijde Linker Enkel',
    [BODYPARTS.Left_Medial_Ankle_LB]: 'Binnenzijde Linker Enkel',
    [BODYPARTS.Left_Heel_LB]: 'Linker Hiel',
    [BODYPARTS.Left_Sole_LB]: 'Achterzijde Linker Voetzool',
    [BODYPARTS.Left_Foot_LM]: 'Binnenzijde Linker Voet',
    [BODYPARTS.Left_Medial_Ankle_LM]: 'Binnenzijde Linker Enkel',
    [BODYPARTS.Left_Medial_Foot_LM]: 'Binnenzijde Linker  Voet',
    [BODYPARTS.Left_Heel_LM]: 'Linker Hiel',
    [BODYPARTS.Left_Sole_LM]: 'Binnenzijde Linker Voetzool',
    [BODYPARTS.Left_Forefoot_LM]: 'Binnenzijde Linker Voorvoet',
    [BODYPARTS.Left_Foot_LL]: 'Buitenzijde Linker Voet',
    [BODYPARTS.Left_Lateral_Ankle_LL]: 'Buitenzijde Linker Enkel',
    [BODYPARTS.Left_Lateral_Foot_LL]: 'Buitenzijde Linker Voet',
    [BODYPARTS.Left_Heel_LL]: 'Linker Hiel',
    [BODYPARTS.Left_Sole_LL]: 'Buitenzijde Linker Voetzool',
    [BODYPARTS.Left_Forefoot_LL]: 'Buitenzijde Linker Voorvoet',
    [BODYPARTS.Left_Hand_LF]: 'Voorzijde Linker Hand',
    [BODYPARTS.Left_Thumb_LF]: 'Voorzijde Linker Duim',
    [BODYPARTS.Left_Index_Finger_LF]: 'Voorzijde Linker Wijsvinger',
    [BODYPARTS.Left_Middle_Finger_LF]: 'Voorzijde Linker Middelvinger',
    [BODYPARTS.Left_Ring_Finger_LF]: 'Voorzijde Linker Ringvinger',
    [BODYPARTS.Left_Pink_LF]: 'Voorzijde Linker Pink',
    [BODYPARTS.Left_Palm_LF]: 'Voorzijde Linker Handpalm',
    [BODYPARTS.Left_Wrist_LF]: 'Voorzijde Linker Pols',
    [BODYPARTS.Left_Hand_LB]: 'Achterzijde Linker Hand',
    [BODYPARTS.Left_Thumb_LB]: 'Achterzijde Linker Duim',
    [BODYPARTS.Left_Index_Finger_LB]: 'Achterzijde Linker Wijsvinger',
    [BODYPARTS.Left_Middle_Finger_LB]: 'Achterzijde Linker Middelvinger',
    [BODYPARTS.Left_Ring_Finger_LB]: 'Achterzijde Linker Ringvinger',
    [BODYPARTS.Left_Pink_LB]: 'Achterzijde Linker Pink',
    [BODYPARTS.Left_Back_Hand_LB]: 'Achterzijde Linker Handrug',
    [BODYPARTS.Left_Wrist_LB]: 'Achterzijde Linker Pols',
    [BODYPARTS.Right_Hand_RF]: 'Voorzijde Rechter Hand',
    [BODYPARTS.Right_Thumb_RF]: 'Voorzijde Rechter Duim',
    [BODYPARTS.Right_Index_Finger_RF]: 'Voorzijde Rechter Wijsvinger',
    [BODYPARTS.Right_Middle_Finger_RF]: 'Voorzijde Rechter Middelvinger',
    [BODYPARTS.Right_Ring_Finger_RF]: 'Voorzijde Rechter Ringvinger',
    [BODYPARTS.Right_Pink_RF]: 'Voorzijde Rechter Pink',
    [BODYPARTS.Right_Palm_RF]: 'Voorzijde Rechter Handpalm',
    [BODYPARTS.Right_Wrist_RF]: 'Voorzijde Rechter Pols',
    [BODYPARTS.Right_Hand_RB]: 'Achterzijde Rechter Hand',
    [BODYPARTS.Right_Thumb_RB]: 'Achterzijde Rechter Duim',
    [BODYPARTS.Right_Index_Finger_RB]: 'Achterzijde Rechter Wijsvinger',
    [BODYPARTS.Right_Middle_Finger_RB]: 'Achterzijde Rechter Middelvinger',
    [BODYPARTS.Right_Ring_Finger_RB]: 'Achterzijde Rechter Ringvinger',
    [BODYPARTS.Right_Pink_RB]: 'Achterzijde Rechter Pink',
    [BODYPARTS.Right_Back_Hand_RB]: 'Achterzijde Rechter Handrug',
    [BODYPARTS.Right_Wrist_RB]: 'Achterzijde Rechter Pols',
    [BODYPARTS.Head_F]: 'Voorzijde Hoofd',
    [BODYPARTS.Head_Top_F]: 'Bovenzijde Hoofd',
    [BODYPARTS.Throat_F]: 'Keel',
    [BODYPARTS.Chin_F]: 'Kin',
    [BODYPARTS.Neck_Left_F]: 'Linker Hals',
    [BODYPARTS.Neck_Right_F]: 'Rechter Hals',
    [BODYPARTS.Jaw_Left_F]: 'Linker Kaak',
    [BODYPARTS.Jaw_Right_F]: 'Rechter Kaak',
    [BODYPARTS.Eye_Left_F]: 'Linker Oog',
    [BODYPARTS.Eye_Right_F]: 'Rechter Oog',
    [BODYPARTS.Ear_Left_F]: 'Linker Oor',
    [BODYPARTS.Ear_Right_F]: 'Rechter Oor',
    [BODYPARTS.Cheek_Left_F]: 'Linker Wang',
    [BODYPARTS.Cheek_Right_F]: 'Rechter Wang',
    [BODYPARTS.Mouth_F]: 'Mond',
    [BODYPARTS.Nose_F]: 'Neus',
    [BODYPARTS.Lower_Neck_Left_F]: 'Linker Hals Onder',
    [BODYPARTS.Lower_Neck_Right_F]: 'Rechter Hals Onder',
    [BODYPARTS.Forehead_F]: 'Voorhoofd',
    [BODYPARTS.Head_B]: 'Achterzijde hoofd',
    [BODYPARTS.Backside_Head_B]: 'Achterhoofd',
    [BODYPARTS.Head_Top_B]: 'Bovenzijde hoofd',
    [BODYPARTS.Ear_Left_B]: 'Linker Oor',
    [BODYPARTS.Ear_Right_B]: 'Rechter Oor',
    [BODYPARTS.Neck_Left_B]: 'Linkerzijde Nek',
    [BODYPARTS.Neck_Right_B]: 'Rechterzijde Nek',
    [BODYPARTS.Cervical_neck_B]: 'Nekwervels',
    [BODYPARTS.Lower_Neck_Left_B]: 'Linker Nek Onder',
    [BODYPARTS.Lower_Neck_Right_B]: 'Rechter Nek Onder',
    [BODYPARTS.Head_L]: 'Linkerzijde Hoofd',
    [BODYPARTS.Mastoid_L]: 'Achter Het Linker oor',
    [BODYPARTS.Backside_Head_L]: 'Achterhoofd Links',
    [BODYPARTS.Head_Top_L]: 'Bovenzijde Hoofd Links',
    [BODYPARTS.Chin_L]: 'Kin',
    [BODYPARTS.Neck_Frontside_L]: 'Linker Hals',
    [BODYPARTS.Jaw_L]: 'Linker Kaak',
    [BODYPARTS.Eye_L]: 'Linker Oog',
    [BODYPARTS.Ear_L]: 'Linker Oor',
    [BODYPARTS.Temporal_L]: 'Linker Slaap',
    [BODYPARTS.Cheek_L]: 'Linker Wang',
    [BODYPARTS.Mouth_L]: 'Mond',
    [BODYPARTS.Neck_Backside_L]: 'Linker Nek',
    [BODYPARTS.Nose_L]: 'Neus',
    [BODYPARTS.Lower_Neck_L]: 'Linker Nek Onder',
    [BODYPARTS.Throat_L]: 'Keel Links',
    [BODYPARTS.Preauricular_L]: 'Vóór het Linker oor',
    [BODYPARTS.Forehead_L]: 'Voorhoofd Links',
    [BODYPARTS.Head_R]: 'Rechterzijde Hoofd',
    [BODYPARTS.Mastoid_R]: 'Achter Het Rechter Oor',
    [BODYPARTS.Backside_Head_R]: 'Achterhoofd Rechts',
    [BODYPARTS.Head_Top_R]: 'Bovenzijde Hoofd Rechts',
    [BODYPARTS.Chin_R]: 'Kin',
    [BODYPARTS.Neck_Frontside_R]: 'Rechter Hals',
    [BODYPARTS.Jaw_R]: 'Rechter Kaak',
    [BODYPARTS.Eye_R]: 'Rechter Oog',
    [BODYPARTS.Ear_R]: 'Rechter Oor',
    [BODYPARTS.Temporal_R]: 'Rechter Slaap',
    [BODYPARTS.Cheek_R]: 'Rechter Wang',
    [BODYPARTS.Mouth_R]: 'Mond',
    [BODYPARTS.Neck_Backside_R]: 'Rechter Nek',
    [BODYPARTS.Nose_R]: 'Neus',
    [BODYPARTS.Lower_Neck_R]: 'Rechter Nek Onder',
    [BODYPARTS.Throat_R]: 'Keel',
    [BODYPARTS.Preauricular_R]: 'Vóór het Rechter Oor',
    [BODYPARTS.Forehead_R]: 'Voorhoofd Rechts',
    [BODYPARTS.Upper_Back_B]: 'Bovenste Rug',
    [BODYPARTS.Upper_Left_Flank_B]: 'Linker Hoge Flank',
    [BODYPARTS.Upper_Left_Paravertebral_B]: 'Hoge Middenrug Links',
    [BODYPARTS.Back_Left_Shoulder_B]: 'Achterzijde Linker Schouder',
    [BODYPARTS.Back_Left_Ribcage_B]: 'Achterzijde Linker Ribbenkas',
    [BODYPARTS.Left_Shoulder_Blade_B]: 'Linker Schouderblad',
    [BODYPARTS.Mid_Left_Paravertebral_B]: 'Middenrug Links',
    [BODYPARTS.Upper_Right_Flank_B]: 'Rechter Hoge Flank',
    [BODYPARTS.Upper_Right_Paravertebral_B]: 'Hoge Middenrug Links',
    [BODYPARTS.Back_Right_Shoulder_B]: 'Achterzijde Rechter Schouder',
    [BODYPARTS.Back_Right_Ribcage_B]: 'Achterzijde Rechter Ribbenkas',
    [BODYPARTS.Right_Shoulder_Blade_B]: 'Rechter Schouderblad',
    [BODYPARTS.Mid_Right_Paravertebral_B]: 'Middenrug Rechts',
    [BODYPARTS.Upper_Spinal_B]: 'Hoge Wervels',
    [BODYPARTS.Mid_Spinal_B]: 'Midden Wervels',
    [BODYPARTS.Torso_F]: 'Borstkas',
    [BODYPARTS.Sternum_F]: 'Borstbeen',
    [BODYPARTS.Left_Lower_Chest_F]: 'Onderzijde Linker Borst',
    [BODYPARTS.Left_Upper_Chest_F]: 'Bovenzijde Linker Borst',
    [BODYPARTS.Left_Front_Shoulder_F]: 'Voorzijde Linker Schouder',
    [BODYPARTS.Left_Clavicle_F]: 'Linker Sleutelbeen',
    [BODYPARTS.Right_Lower_Chest_F]: 'Onderzijde Rechter Borst',
    [BODYPARTS.Right_Upper_Chest_F]: 'Bovenzijde Rechter Borst',
    [BODYPARTS.Right_Front_Shoulder_F]: 'Voorzijde Rechter Schouder',
    [BODYPARTS.Right_Clavicle_F]: 'Rechter Sleutelbeen',
    [BODYPARTS.Torso_R]: 'Rechterzijde Borstkas',
    [BODYPARTS.Right_Upper_Chest_R]: 'Bovenzijde Rechter Borst',
    [BODYPARTS.Upper_Right_Flank_R]: 'Rechter Hoge Flank',
    [BODYPARTS.Right_Shoulder_Blade_R]: 'Rechter Schouderblad',
    [BODYPARTS.Right_Lower_Chest_R]: 'Onderzijde Rechter Borst',
    [BODYPARTS.Front_Right_Ribcage_R]: 'Voorzijde Rechter Ribbenkas ',
    [BODYPARTS.Torso_L]: 'Linkerzijde Borstkas',
    [BODYPARTS.Left_Upper_Chest_L]: 'Bovenzijde Linker Borst',
    [BODYPARTS.Upper_Left_Flank_L]: 'Linker Hoge Flank',
    [BODYPARTS.Left_Shoulder_Blade_L]: 'Linker Schouderblad',
    [BODYPARTS.Left_Lower_Chest_L]: 'Onderzijde Linker Borst',
    [BODYPARTS.Front_Left_Ribcage_L]: 'Voorzijde Linker Ribbenkas ',
    [BODYPARTS.Abdomen_F]: 'Buik',
    [BODYPARTS.Epigastric_F]: 'Bovenbuik / Maag',
    [BODYPARTS.Left_Upper_Abdomen_F]: 'Linker Bovenbuik',
    [BODYPARTS.Right_Upper_Abdomen_F]: 'Rechter Bovenbuik',
    [BODYPARTS.Left_Lower_Abdomen_F]: 'Linker Onderbuik',
    [BODYPARTS.Right_Lower_Abdomen_F]: 'Rechter Onderbuik',
    [BODYPARTS.Umbilical_region_F]: 'Middenbuik',
    [BODYPARTS.Right_Middle_Abdomen_F]: 'Rechterzijde Buik',
    [BODYPARTS.Left_Middle_Abdomen_F]: 'Linkerzijde Buik',
    [BODYPARTS.Lumbal_region_B]: 'Onderrug',
    [BODYPARTS.Lumbal_Paravertebral_Left_B]: 'Ondermiddenrug Links',
    [BODYPARTS.Lumbal_Paravertebral_Right_B]: 'Ondermiddenrug Rechts',
    [BODYPARTS.Left_SI_Joint_B]: 'Rugkuiltje Links',
    [BODYPARTS.Right_SI_Joint_B]: 'Rugkuiltje Rechts',
    [BODYPARTS.Left_Lowerback_B]: 'Linkeronder rug',
    [BODYPARTS.Right_Lowerback_B]: 'Rechteronder rug',
    [BODYPARTS.Left_Flank_B]: 'Linker Flank',
    [BODYPARTS.Right_Flank_B]: 'Rechter Flank',
    [BODYPARTS.Lumbal_spine_B]: 'Lage rugwervels',
    [BODYPARTS.Abdomen_L]: 'Linkerzijde Buik',
    [BODYPARTS.Left_Upper_Flank_L]: 'Linker Bovenzij',
    [BODYPARTS.Left_Middle_Abdomen_L]: 'Linkerzijde Buik',
    [BODYPARTS.Left_Lower_Flank_L]: 'Linker Zij',
    [BODYPARTS.Abdomen_R]: 'Rechterzijde Buik',
    [BODYPARTS.Right_Upper_Flank_R]: 'Rechter Bovenzij',
    [BODYPARTS.Right_Middle_Abdomen_R]: 'Rechter zijde Buik',
    [BODYPARTS.Right_Lower_Flank_R]: 'Rechter Zij',
    [BODYPARTS.Hip_Region_F]: 'Voorzijde Heupgebied',
    [BODYPARTS.Left_Hipbone_F]: 'Linker Heuprand',
    [BODYPARTS.Right_Hipbone_F]: 'Rechter Heuprand',
    [BODYPARTS.Lower_Abdominal_F]: 'Onderbuik',
    [BODYPARTS.Left_Inguinals_F]: 'Linker Lies',
    [BODYPARTS.Right_Inguinals_F]: 'Rechter Lies',
    [BODYPARTS.Pubic_region_F]: 'Schaamstreek',
    [BODYPARTS.Hip_Region_B]: 'Achterzijde Heupgebied',
    [BODYPARTS.Left_Gluteal_B]: 'Linker Bil',
    [BODYPARTS.Right_Gluteal_B]: 'Rechter Bil',
    [BODYPARTS.Perianal_region_B]: 'Anale gebied',
    [BODYPARTS.Sacral_region_B]: 'Stuitgebied',
    [BODYPARTS.Hip_Region_L]: 'Linkerzijde Heupgebied',
    [BODYPARTS.Left_Gluteal_BL]: 'Linker Bil',
    [BODYPARTS.Hip_Region_R]: 'Rechterzijde Heupgebied',
    [BODYPARTS.Right_Gluteal_BR]: 'Rechter Bil',
  },
};
