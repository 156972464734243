import React, { useState, useEffect } from 'react';
import styles from './RadioButtonRound.module.scss';

const RadioButtonRound = ({
  name,
  option,
  disabled = false,
  checked = false,
  onChange = () => null,
}) => {
  const handleChange = (e) => {
    if (e.target.checked) {
      onChange(option);
    }
  };
  return (
    <label className={styles.root}>
      <div className={styles.inputWrapper}>
        <input
          name={name}
          type="radio"
          value={option}
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          className={styles.actualInput}
        />
        <div className={styles.styledInput} />
      </div>
    </label>
  );
};

export default RadioButtonRound;
