import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './Button.module.scss';

const Button = ({
  className,
  type = 'button',
  busy = false,
  disabled = false,
  fullWidth = false,
  primary = false,
  muted = false,
  autoFocus = false,
  children,
  href,
  ...rest
}) => {
  const attrs = rest;

  let Tag = 'button';
  let linkAsButton = false;
  if (href) {
    Tag = Link;
    attrs.to = href;
    linkAsButton = true;
  }

  const classes = classNames(
    className,
    styles.button,
    fullWidth && styles.fullWidth,
    disabled && styles.disabled,
    primary && styles.primary,
    muted && styles.muted,
    linkAsButton && styles.linkAsButton,
  );

  const content = busy ? <CircularProgress size={16} /> : children;

  return (
    <Tag className={classes} disabled={disabled || busy} type={type} {...attrs}>
      {content}
    </Tag>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  busy: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  primary: PropTypes.bool,
  muted: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default Button;
