import React from 'react';
import classNames from 'classnames';

import styles from './SvgIcon.module.scss';

const SvgIcon = ({ className, ...rest }) => (
  <svg className={classNames(styles.icon, className)} {...rest} />
);

export default SvgIcon;
