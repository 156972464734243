class Session {
  get authToken() {
    return sessionStorage.getItem('auth');
  }

  set authToken(value) {
    sessionStorage.setItem('auth', value);
  }

  get role() {
    return sessionStorage.getItem('role');
  }

  set role(value) {
    sessionStorage.setItem('role', value);
  }
}

export default new Session();