import React from 'react';
import Label from '../../components/form/misc/Label';

import styles from './MiaOverview.module.scss';

const ValueDisplay = ({ label, children }) => (
  <div className={styles.valueDisplay}>
    <Label>{label}</Label>
    <div className={styles.value}>{children}</div>
  </div>
);

export default ValueDisplay;