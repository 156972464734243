import React from 'react';
import PropTypes from 'prop-types';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';

import { renderTextField } from '../form/renderers';
import Button from '../widgets/Button/index';
import { PatientApi } from '../../utils/api';

import styles from './VerifySSN.module.scss';

import i18n from '../../i18n';

const VerifySSN = ({ className, encryptedUserId, onSSNVerified }) => {
  const handleVerification = async ({ SSN }) => {
    let patientData;
    try {
      patientData = await PatientApi.findBySsn(SSN);
    } catch (err) {
      return { [FORM_ERROR]: i18n.t('ssn_verification_failed') };
    }

    if (patientData.username !== encryptedUserId) {
      return { [FORM_ERROR]: i18n.t('ssn_dont_match_patient') };
    }

    if (onSSNVerified) {
      onSSNVerified();
    }

    return null;
  };

  return (
    <Form onSubmit={handleVerification}>
      {({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit} className={className}>
          <Field name="SSN" label="SSN" fullWidth component={renderTextField} autoFocus />

          {!submitting && (
            <Button type="submit" fullWidth>
              {i18n.t('verify')}
            </Button>
          )}
          {submitting && <div className={styles.loading}><CircularProgress /></div>}
          <p className={styles.submitError}>{submitError}</p>
        </form>
      )}
    </Form>
  );
};

VerifySSN.propTypes = {
  className: PropTypes.string,
  encryptedUserId: PropTypes.string.isRequired,
  onSSNVerified: PropTypes.func.isRequired,
};

VerifySSN.defaultProps = {
  className: null,
}

export default VerifySSN;
