import { STATUS } from '../constants';

import i18n from '../i18n';

export const renderStatus = (status) => {
  const {
    WAITING,
    MIA_IN_PROCESS,
    MIA_COMPLETE,
    CONSULT_IN_PROCESS,
    CONSULT_COMPLETE,
  } = STATUS;

  const labels = {
    [WAITING]: i18n.t('waiting_for_mia_sync'),
    [MIA_IN_PROCESS]: i18n.t('mia_in_process'),
    [MIA_COMPLETE]: i18n.t('mia_complete'),
    [CONSULT_IN_PROCESS]: i18n.t('consult_in_process'),
    [CONSULT_COMPLETE]: i18n.t('consult_completed'),
  };

  if (!labels[status]) {
    throw new Error(`No label for status '${status}'`);
  }

  return labels[status];
};
