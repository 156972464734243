import React from 'react';

const BodyArea = ({ onClick, active, path }) => (
  <path
    onClick={onClick}
    fill={active ? 'rgba(41, 171, 226, .3)' : 'transparent'}
    d={path}
  />
);

const BodyView = ({
  viewBox,
  background,
  areas,
  selectedAreas,
  onClickArea,
  path, // might be there, don't spread
  ...rest
}) => {
  const [x, y, width, height] = viewBox.split(' ');
  const imgProps = { x, y, width, height };
  return (
    <svg viewBox={viewBox} preserveAspectRatio="xMidYMid meet" {...rest}>
      <image {...imgProps} xlinkHref={background} overflow="visible" />
      {areas.map((area, i) => (
        <BodyArea
          key={i}
          onClick={() => onClickArea(area)}
          active={selectedAreas.includes(area)}
          path={area.path}
        />
      ))}
    </svg>
  );
};

export default BodyView;
