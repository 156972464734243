import React from 'react';

import SvgIcon from './SvgIcon';

const HelpIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M2.5 0A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0021.5 0zM12 19a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm1.6-6.08a1 1 0 00-.6.917 1 1 0 01-2 0 3 3 0 011.8-2.75A2 2 0 1010 9.255a1 1 0 01-2 0 4 4 0 115.6 3.666z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default HelpIcon;
