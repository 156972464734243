import React, { useRef, useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Form, Field, useField } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';

import FillingContainer from '../../../components/widgets/FillingContainer';
import { renderRadioButton } from '../../../components/form/renderers';
import Card from '../../../components/widgets/Card';
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableHeader,
  TableCell,
} from '../../../components/widgets/Table';
import { RADIO_FIELD_NAME } from '../constants';
import styles from './List.module.scss';

const useDebounce = (fn, time) => {
  const debouncedRef = useRef(debounce(fn, time));
  useEffect(() => {
    if (debouncedRef.current) {
      debouncedRef.current.cancel();
    }
    debouncedRef.current = debounce(fn, time);
  }, [fn, time]);
  return debouncedRef.current;
};

const List = ({
  items,
  columns,
  loading,
  selectable,
  onSelectionChange,
  isSelectable,
  emptyMessage,
  searchable,
  onSearch,
}) => {
  const colCount = columns.length + (selectable ? 1 : 0);
  const [searchText, setSearchText] = useState('');
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const {
    input: { value: radioValue, onChange: updateSelectedRow },
  } = useField(RADIO_FIELD_NAME);
  const handleClickOnRow = (i) => () => {
    if (selectable) {
      updateSelectedRow(i);
    }
  };
  const doSearch = useCallback(
    async (text) => {
      setSearchResults(await onSearch(text));
      setSearching(false);
    },
    [onSearch],
  );
  const debouncedSearch = useDebounce(doSearch, 500);
  const handleSearchChange = useCallback(
    (e) => {
      const newSearchText = e.target.value;
      setSearchText(newSearchText);
      debouncedSearch.cancel();
      if (newSearchText) {
        setSearching(true);
        debouncedSearch(newSearchText);
      } else {
        setSearching(false);
        setSearchResults([]);
      }
    },
    [setSearchText, debouncedSearch, setSearching, setSearchResults],
  );
  useEffect(() => {
    onSelectionChange(radioValue);
  }, [radioValue]);

  const itemsToDisplay = searchText ? searchResults : items;
  return (
    <Card className={styles.card}>
      <Table className={styles.list}>
        <TableHead className={styles.listHead}>
          <TableRow>
            {selectable && <TableCell />}
            {columns.map(({ label }, j) => (
              <TableHeader key={j}>{label}</TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={styles.listBody}>
          {!loading && searchable && (
            <TableRow>
              <TableCell colSpan={colCount} className={styles.searchCell}>
                <input
                  type="text"
                  placeholder="Search"
                  className={styles.searchInput}
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            itemsToDisplay.map((item, i) => {
              const rowProps = {};
              let isDisabled = false;
              if (selectable) {
                if (isSelectable(item)) {
                  rowProps.onClick = handleClickOnRow(i);
                } else {
                  isDisabled = true;
                }
              }
              rowProps.className = classnames(
                styles.row,
                radioValue === i && styles.selectedRow,
                isDisabled && styles.disabledRow,
              );

              return (
                <TableRow key={i} {...rowProps}>
                  {selectable && (
                    <TableCell>
                      <Field
                        name={RADIO_FIELD_NAME}
                        option={i}
                        disabled={!isSelectable(item)}
                        component={renderRadioButton}
                      />
                    </TableCell>
                  )}
                  {columns.map(({ render }, j) => (
                    <TableCell key={j}>{render(item)}</TableCell>
                  ))}
                </TableRow>
              );
            })}
          {(loading || searching) && (
            <TableRow>
              <TableCell colSpan={colCount} className={styles.placeholderCell}>
                <FillingContainer>
                  <CircularProgress />
                </FillingContainer>
              </TableCell>
            </TableRow>
          )}
          {!loading && !searching && itemsToDisplay.length === 0 && (
            <TableRow>
              <TableCell colSpan={colCount} className={styles.placeholderCell}>
                <FillingContainer>{emptyMessage}</FillingContainer>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
};

const noOp = () => null;

const FormList = (props) => (
  <Form onSubmit={noOp} component={List} {...props} />
);

FormList.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      render: PropTypes.func.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isSelectable: PropTypes.func,
  searchable: PropTypes.bool,
  onSearch: PropTypes.func,
};

FormList.defaultProps = {
  loading: false,
  selectable: false,
  onSelectionChange: () => null,
  emptyMessage: 'no data',
  isSelectable: () => true,
  searchable: false,
  onSearch: () => [],
};

export default FormList;
