import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';

import { GENDER } from '../../constants';
import {
  renderAutoComplete,
  renderDateTextField,
  renderRadioButtonCards,
  renderTextField,
} from '../../components/form/renderers';
import FillingContainer from '../../components/widgets/FillingContainer';
import GenderMaleIcon from '../../components/icons/GenderMale';
import GenderFemaleIcon from '../../components/icons/GenderFemale';
import Button from '../../components/widgets/Button';

import styles from './NewPatient.module.scss';
import { ComplaintsApi } from '../../utils/api';
import { isValidDate } from '../../utils/functions';
import { MIN_SSN_LENGTH } from './constants';

import i18n from '../../i18n';

const isValidNumber = (n) => {
  if (!n) {
    return true;
  }
  const res = n.match(/(^[0-9]*[.])?[0-9]+$/);
  return res && res.length === 2;
};

const MAX_COMPLAINTS_COUNT = 10;
const searchComplaints = (searchText, complaints) => {
  let lowerSearch = searchText.trim().toLowerCase();
  let filteredComplaints = complaints;
  if (lowerSearch) {
    filteredComplaints = complaints
      // compute match index
      .map((d) => [d, d.name.toLowerCase().indexOf(lowerSearch)])
      // filter out when no match
      .filter(([, idx]) => idx !== -1)
      // sort by match index
      .sort(([, i1], [, i2]) => i1 - i2)
      // remap to model only
      .map(([d]) => d);
  }
  return filteredComplaints.slice(0, MAX_COMPLAINTS_COUNT);
};

const formatComplaint = ({ name }) => name;

const GENDER_OPTIONS = [
  {
    value: GENDER.Male,
    label: (
      <>
        <GenderMaleIcon />
        &nbsp;{i18n.t('male')}
      </>
    ),
  },
  {
    value: GENDER.Female,
    label: (
      <>
        <GenderFemaleIcon />
        &nbsp;{i18n.t('female')}
      </>
    ),
  },
];

const ChangeListener = ({ onChange, input: { value } }) => {
  const [init, setInit] = useState(false);
  useEffect(() => setInit(true), []);
  useEffect(() => {
    if (init) {
      onChange(value);
    }
  }, [value]);
  return null;
};

const PatientForm = ({
  onSubmit,
  onSsnChange,
  checkingSsn,
  ssnChecked,
  ssnExists,
}) => {
  const [loading, setLoading] = useState(true);
  const [complaints, setComplaints] = useState(true);
  useEffect(() => {
    (async () => {
      const complaints = await ComplaintsApi.findAll();
      setComplaints(complaints);
      setLoading(false);
    })();
  }, []);

  const validate = (values) => {
    const {
      complaint,
      birthDate,
      height,
      weight,
      firstName,
      gender,
      lastName,
      patientId,
    } = values;
    const errors = {};
    if (!complaint) {
      errors.complaint = i18n.t('required');
    }
    if (!patientId) {
      errors.patientId = i18n.t('required');
    } else if (patientId.length < MIN_SSN_LENGTH) {
      errors.patientId = i18n.t('minSSNLength', { length: MIN_SSN_LENGTH });
    }
    if (ssnChecked && !ssnExists) {
      if (!birthDate) {
        errors.birthDate = i18n.t('required');
      } else if (!isValidDate(birthDate)) {
        errors.birthDate = i18n.t('invalid_date');
      }
      if (!gender) {
        errors.gender = i18n.t('required');
      }
      if (!firstName) {
        errors.firstName = i18n.t('required');
      }
      if (!lastName) {
        errors.lastName = i18n.t('required');
      }
      if (!isValidNumber(height)) {
        errors.height = i18n.t('invalid');
      }
      if (!isValidNumber(weight)) {
        errors.weight = i18n.t('invalid');
      }
    }
    return errors;
  };

  const handleComplaintSearch = (searchText) =>
    searchComplaints(searchText, complaints);

  if (loading) {
    return (
      <FillingContainer>
        <CircularProgress />
      </FillingContainer>
    );
  }

  const ssnButtonLabel = ssnExists
    ? i18n.t('proceed')
    : i18n.t('register_patient');

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, pristine, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="complaint"
            label={i18n.t('initial_complaint')}
            onSearch={handleComplaintSearch}
            formatSearchResult={formatComplaint}
            fullWidth
            component={renderAutoComplete}
          />
          <div className={styles.patientIdRow}>
            <Field
              label={i18n.t('patient_id')}
              name="patientId"
              component={renderTextField}
              maxLength="9"
              fullWidth
            />
            {checkingSsn && (
              <CircularProgress size={24} className={styles.spinner} />
            )}
          </div>
          {ssnChecked && ssnExists && (
            <div className={styles.patientExistsMsg}>
              {i18n.t('patient_already_exists')}
            </div>
          )}
          {ssnChecked && !ssnExists && (
            <>
              <div className={styles.nameRow}>
                <Field
                  label={i18n.t('first_name')}
                  name="firstName"
                  className={styles.responsiveTextField}
                  component={renderTextField}
                  placeholder=""
                />
                <Field
                  label={i18n.t('last_name')}
                  name="lastName"
                  className={styles.responsiveTextField}
                  component={renderTextField}
                  placeholder=""
                />
              </div>
              <div className={styles.ageGenderRow}>
                <Field
                  label={i18n.t('birthdate')}
                  name="birthDate"
                  className={styles.responsiveTextField}
                  component={renderDateTextField}
                  placeholder="DD/MM/YYYY"
                />
                <Field
                  label={i18n.t('gender')}
                  name="gender"
                  className={styles.responsiveTextField}
                  options={GENDER_OPTIONS}
                  component={renderRadioButtonCards}
                />
              </div>
              <div className={styles.heightWeightRow}>
                <Field
                  label={i18n.t('height')}
                  name="height"
                  className={styles.responsiveTextField}
                  component={renderTextField}
                  placeholder={i18n.t('heightInCm')}
                />
                <Field
                  label={i18n.t('weight')}
                  name="weight"
                  className={styles.responsiveTextField}
                  component={renderTextField}
                  placeholder={i18n.t('weightInKg')}
                />
              </div>
            </>
          )}
          <Button
            className={styles.btn}
            type="submit"
            disabled={pristine || invalid || submitting || checkingSsn}
            fullWidth>
            {ssnButtonLabel}
          </Button>
          {/* Following does not render anything, it only detects changes */}
          <Field
            name="patientId"
            component={ChangeListener}
            onChange={onSsnChange}
          />
        </form>
      )}
    </Form>
  );
};

PatientForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSsnChange: PropTypes.func.isRequired,
  checkingSsn: PropTypes.bool.isRequired,
  ssnChecked: PropTypes.bool.isRequired,
  ssnExists: PropTypes.bool.isRequired,
};

export default PatientForm;
