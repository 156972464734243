import React from 'react';

import SvgIcon from './SvgIcon';

const GenderFemaleIcon = (props) => (
  <SvgIcon viewBox="0 0 39 36" {...props}>
    <g
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M22.361 22a5 5 0 01-6 0M23.361 16.5a.5.5 0 110 1 .5.5 0 010-1M15.361 16.5a.5.5 0 110 1 .5.5 0 010-1" />
      <path d="M28.361 14a10 10 0 01-9-5.636 10 10 0 01-10 5.586V19c0 5.523 4.477 10 10 10s10-4.477 10-10v-5.05c-.332.034-.666.05-1 .05z" />
      <path d="M28.361 34.738a37.812 37.812 0 008.8-3.848 1 1 0 00.312-1.546c-2.642-3.09-4.112-5.32-4.112-9.406V15c0-7.732-6.268-14-14-14s-14 6.268-14 14v4.938c0 4.094-1.47 6.324-4.12 9.4a1 1 0 00.312 1.546 49.432 49.432 0 008.8 3.426" />
    </g>
  </SvgIcon>
);

export default GenderFemaleIcon;
