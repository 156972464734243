const BODYPARTS = {
  Body_F: 'Body_F',
  Body_B: 'Body_B',
  Body_L: 'Body_L',
  Body_R: 'Body_R',
  Right_Arm_RF: 'Right_Arm_RF',
  Medial_Elbow_RF: 'Medial_Elbow_RF',
  Lateral_Elbow_RF: 'Lateral_Elbow_RF',
  Biceps_RF: 'Biceps_RF',
  Hand_RF: 'Hand_RF',
  Ventral_Lower_Arm_RF: 'Ventral_Lower_Arm_RF',
  Right_Arm_RB: 'Right_Arm_RB',
  Olecranon_RB: 'Olecranon_RB',
  Medial_Elbow_RB: 'Medial_Elbow_RB',
  Lateral_Elbow_RB: 'Lateral_Elbow_RB',
  Triceps_RB: 'Triceps_RB',
  Hand_RB: 'Hand_RB',
  Dorsal_Lower_Arm_RB: 'Dorsal_Lower_Arm_RB',
  Left_Arm_LF: 'Left_Arm_LF',
  Medial_Elbow_LF: 'Medial_Elbow_LF',
  Lateral_Elbow_LF: 'Lateral_Elbow_LF',
  Biceps_LF: 'Biceps_LF',
  Hand_LF: 'Hand_LF',
  Ventral_Lower_Arm_LF: 'Ventral_Lower_Arm_LF',
  Left_Arm_LB: 'Left_Arm_LB',
  Olecranon_LB: 'Olecranon_LB',
  Medial_Elbow_LB: 'Medial_Elbow_LB',
  Lateral_Elbow_LB: 'Lateral_Elbow_LB',
  Triceps_LB: 'Triceps_LB',
  Hand_LB: 'Hand_LB',
  Dorsal_Lower_Arm_LB: 'Dorsal_Lower_Arm_LB',
  Right_Leg_RF: 'Right_Leg_RF',
  Upper_Leg_RF: 'Upper_Leg_RF',
  Lower_Leg_RF: 'Lower_Leg_RF',
  Knee_RF: 'Knee_RF',
  Foot_RF: 'Foot_RF',
  Medial_Thigh_RF: 'Medial_Thigh_RF',
  Right_Leg_RB: 'Right_Leg_RB',
  Hamstrings_RB: 'Hamstrings_RB',
  Foot_RB: 'Foot_RB',
  Popliteal_Region_RB: 'Popliteal_Region_RB',
  Calve_RB: 'Calve_RB',
  Right_Leg_RM: 'Right_Leg_RM',
  Medial_Thigh_RM: 'Medial_Thigh_RM',
  Medial_Knee_RM: 'Medial_Knee_RM',
  Medial_Upper_Leg_RM: 'Medial_Upper_Leg_RM',
  Medial_Hamstrings_RM: 'Medial_Hamstrings_RM',
  Knee_RM: 'Knee_RM',
  Politea_RM: 'Politea_RM',
  Calve_RM: 'Calve_RM',
  Medial_Lower_Leg_RM: 'Medial_Lower_Leg_RM',
  Foot_RM: 'Foot_RM',
  Right_Leg_RL: 'Right_Leg_RL',
  Lateral_Thigh_RL: 'Lateral_Thigh_RL',
  Lateral_Knee_RL: 'Lateral_Knee_RL',
  Lateral_Upper_Leg_RL: 'Lateral_Upper_Leg_RL',
  Lateral_Hamstrings_RL: 'Lateral_Hamstrings_RL',
  Knee_RL: 'Knee_RL',
  Politea_RL: 'Politea_RL',
  Calve_RL: 'Calve_RL',
  Lateral_Lower_Leg_RL: 'Lateral_Lower_Leg_RL',
  Foot_RL: 'Foot_RL',
  Left_Leg_LF: 'Left_Leg_LF',
  Upper_Leg_LF: 'Upper_Leg_LF',
  Lower_Leg_LF: 'Lower_Leg_LF',
  Knee_LF: 'Knee_LF',
  Foot_LF: 'Foot_LF',
  Medial_Thigh_LF: 'Medial_Thigh_LF',
  Left_Leg_LB: 'Left_Leg_LB',
  Hamstrings_LB: 'Hamstrings_LB',
  Foot_LB: 'Foot_LB',
  Popliteal_Region_LB: 'Popliteal_Region_LB',
  Calve_LB: 'Calve_LB',
  Left_Leg_LM: 'Left_Leg_LM',
  Medial_Thigh_LM: 'Medial_Thigh_LM',
  Medial_Knee_LM: 'Medial_Knee_LM',
  Medial_Upper_Leg_LM: 'Medial_Upper_Leg_LM',
  Medial_Hamstrings_LM: 'Medial_Hamstrings_LM',
  Knee_LM: 'Knee_LM',
  Politea_LM: 'Politea_LM',
  Calve_LM: 'Calve_LM',
  Medial_Lower_Leg_LM: 'Medial_Lower_Leg_LM',
  Foot_LM: 'Foot_LM',
  Left_Leg_LL: 'Left_Leg_LL',
  Lateral_Thigh_LL: 'Lateral_Thigh_LL',
  Lateral_Knee_LL: 'Lateral_Knee_LL',
  Lateral_Upper_Leg_LL: 'Lateral_Upper_Leg_LL',
  Lateral_Hamstrings_LL: 'Lateral_Hamstrings_LL',
  Knee_LL: 'Knee_LL',
  Politea_LL: 'Politea_LL',
  Calve_LL: 'Calve_LL',
  Lateral_Lower_Leg_LL: 'Lateral_Lower_Leg_LL',
  Foot_LL: 'Foot_LL',
  Right_Foot_RF: 'Right_Foot_RF',
  Right_Hallux_1_RF: 'Right_Hallux_1_RF',
  Right_Hallux_2_RF: 'Right_Hallux_2_RF',
  Right_Hallux_3_RF: 'Right_Hallux_3_RF',
  Right_Hallux_4_RF: 'Right_Hallux_4_RF',
  Right_Hallux_5_RF: 'Right_Hallux_5_RF',
  Right_Forefoot_RF: 'Right_Forefoot_RF',
  Right_Ankle_RF: 'Right_Ankle_RF',
  Right_Foot_RB: 'Right_Foot_RB',
  Right_Achillesheel_RB: 'Right_Achillesheel_RB',
  Right_Lateral_Ankle_RB: 'Right_Lateral_Ankle_RB',
  Right_Medial_Ankle_RB: 'Right_Medial_Ankle_RB',
  Right_Heel_RB: 'Right_Heel_RB',
  Right_Sole_RB: 'Right_Sole_RB',
  Right_Foot_RM: 'Right_Foot_RM',
  Right_Medial_Ankle_RM: 'Right_Medial_Ankle_RM',
  Right_Medial_Foot_RM: 'Right_Medial_Foot_RM',
  Right_Heel_RM: 'Right_Heel_RM',
  Right_Sole_RM: 'Right_Sole_RM',
  Right_Forefoot_RM: 'Right_Forefoot_RM',
  Right_Foot_RL: 'Right_Foot_RL',
  Right_Lateral_Ankle_RL: 'Right_Lateral_Ankle_RL',
  Right_Lateral_Foot_RL: 'Right_Lateral_Foot_RL',
  Right_Heel_RL: 'Right_Heel_RL',
  Right_Sole_RL: 'Right_Sole_RL',
  Right_Forefoot_RL: 'Right_Forefoot_RL',
  Left_Foot_LF: 'Left_Foot_LF',
  Left_Hallux_1_LF: 'Left_Hallux_1_LF',
  Left_Hallux_2_LF: 'Left_Hallux_2_LF',
  Left_Hallux_3_LF: 'Left_Hallux_3_LF',
  Left_Hallux_4_LF: 'Left_Hallux_4_LF',
  Left_Hallux_5_LF: 'Left_Hallux_5_LF',
  Left_Forefoot_LF: 'Left_Forefoot_LF',
  Left_Front_Ankle_LF: 'Left_Front_Ankle_LF',
  Left_Foot_LB: 'Left_Foot_LB',
  Left_Achillesheel_LB: 'Left_Achillesheel_LB',
  Left_Lateral_Ankle_LB: 'Left_Lateral_Ankle_LB',
  Left_Medial_Ankle_LB: 'Left_Medial_Ankle_LB',
  Left_Heel_LB: 'Left_Heel_LB',
  Left_Sole_LB: 'Left_Sole_LB',
  Left_Foot_LM: 'Left_Foot_LM',
  Left_Medial_Ankle_LM: 'Left_Medial_Ankle_LM',
  Left_Medial_Foot_LM: 'Left_Medial_Foot_LM',
  Left_Heel_LM: 'Left_Heel_LM',
  Left_Sole_LM: 'Left_Sole_LM',
  Left_Forefoot_LM: 'Left_Forefoot_LM',
  Left_Foot_LL: 'Left_Foot_LL',
  Left_Lateral_Ankle_LL: 'Left_Lateral_Ankle_LL',
  Left_Lateral_Foot_LL: 'Left_Lateral_Foot_LL',
  Left_Heel_LL: 'Left_Heel_LL',
  Left_Sole_LL: 'Left_Sole_LL',
  Left_Forefoot_LL: 'Left_Forefoot_LL',
  Left_Hand_LF: 'Left_Hand_LF',
  Left_Thumb_LF: 'Left_Thumb_LF',
  Left_Index_Finger_LF: 'Left_Index_Finger_LF',
  Left_Middle_Finger_LF: 'Left_Middle_Finger_LF',
  Left_Ring_Finger_LF: 'Left_Ring_Finger_LF',
  Left_Pink_LF: 'Left_Pink_LF',
  Left_Palm_LF: 'Left_Palm_LF',
  Left_Wrist_LF: 'Left_Wrist_LF',
  Left_Hand_LB: 'Left_Hand_LB',
  Left_Thumb_LB: 'Left_Thumb_LB',
  Left_Index_Finger_LB: 'Left_Index_Finger_LB',
  Left_Middle_Finger_LB: 'Left_Middle_Finger_LB',
  Left_Ring_Finger_LB: 'Left_Ring_Finger_LB',
  Left_Pink_LB: 'Left_Pink_LB',
  Left_Back_Hand_LB: 'Left_Back_Hand_LB',
  Left_Wrist_LB: 'Left_Wrist_LB',
  Right_Hand_RF: 'Right_Hand_RF',
  Right_Thumb_RF: 'Right_Thumb_RF',
  Right_Index_Finger_RF: 'Right_Index_Finger_RF',
  Right_Middle_Finger_RF: 'Right_Middle_Finger_RF',
  Right_Ring_Finger_RF: 'Right_Ring_Finger_RF',
  Right_Pink_RF: 'Right_Pink_RF',
  Right_Palm_RF: 'Right_Palm_RF',
  Right_Wrist_RF: 'Right_Wrist_RF',
  Right_Hand_RB: 'Right_Hand_RB',
  Right_Thumb_RB: 'Right_Thumb_RB',
  Right_Index_Finger_RB: 'Right_Index_Finger_RB',
  Right_Middle_Finger_RB: 'Right_Middle_Finger_RB',
  Right_Ring_Finger_RB: 'Right_Ring_Finger_RB',
  Right_Pink_RB: 'Right_Pink_RB',
  Right_Back_Hand_RB: 'Right_Back_Hand_RB',
  Right_Wrist_RB: 'Right_Wrist_RB',
  Head_F: 'Head_F',
  Head_Top_F: 'Head_Top_F',
  Throat_F: 'Throat_F',
  Chin_F: 'Chin_F',
  Neck_Left_F: 'Neck_Left_F',
  Neck_Right_F: 'Neck_Right_F',
  Jaw_Left_F: 'Jaw_Left_F',
  Jaw_Right_F: 'Jaw_Right_F',
  Eye_Left_F: 'Eye_Left_F',
  Eye_Right_F: 'Eye_Right_F',
  Ear_Left_F: 'Ear_Left_F',
  Ear_Right_F: 'Ear_Right_F',
  Cheek_Left_F: 'Cheek_Left_F',
  Cheek_Right_F: 'Cheek_Right_F',
  Mouth_F: 'Mouth_F',
  Nose_F: 'Nose_F',
  Lower_Neck_Left_F: 'Lower_Neck_Left_F',
  Lower_Neck_Right_F: 'Lower_Neck_Right_F',
  Forehead_F: 'Forehead_F',
  Head_B: 'Head_B',
  Backside_Head_B: 'Backside_Head_B',
  Head_Top_B: 'Head_Top_B',
  Ear_Left_B: 'Ear_Left_B',
  Ear_Right_B: 'Ear_Right_B',
  Neck_Left_B: 'Neck_Left_B',
  Neck_Right_B: 'Neck_Right_B',
  Cervical_neck_B: 'Cervical_neck_B',
  Lower_Neck_Left_B: 'Lower_Neck_Left_B',
  Lower_Neck_Right_B: 'Lower_Neck_Right_B',
  Head_L: 'Head_L',
  Mastoid_L: 'Mastoid_L',
  Backside_Head_L: 'Backside_Head_L',
  Head_Top_L: 'Head_Top_L',
  Chin_L: 'Chin_L',
  Neck_Frontside_L: 'Neck_Frontside_L',
  Jaw_L: 'Jaw_L',
  Eye_L: 'Eye_L',
  Ear_L: 'Ear_L',
  Temporal_L: 'Temporal_L',
  Cheek_L: 'Cheek_L',
  Mouth_L: 'Mouth_L',
  Neck_Backside_L: 'Neck_Backside_L',
  Nose_L: 'Nose_L',
  Lower_Neck_L: 'Lower_Neck_L',
  Throat_L: 'Throat_L',
  Preauricular_L: 'Preauricular_L',
  Forehead_L: 'Forehead_L',
  Head_R: 'Head_R',
  Mastoid_R: 'Mastoid_R',
  Backside_Head_R: 'Backside_Head_R',
  Head_Top_R: 'Head_Top_R',
  Chin_R: 'Chin_R',
  Neck_Frontside_R: 'Neck_Frontside_R',
  Jaw_R: 'Jaw_R',
  Eye_R: 'Eye_R',
  Ear_R: 'Ear_R',
  Temporal_R: 'Temporal_R',
  Cheek_R: 'Cheek_R',
  Mouth_R: 'Mouth_R',
  Neck_Backside_R: 'Neck_Backside_R',
  Nose_R: 'Nose_R',
  Lower_Neck_R: 'Lower_Neck_R',
  Throat_R: 'Throat_R',
  Preauricular_R: 'Preauricular_R',
  Forehead_R: 'Forehead_R',
  Upper_Back_B: 'Upper_Back_B',
  Upper_Left_Flank_B: 'Upper_Left_Flank_B',
  Upper_Left_Paravertebral_B: 'Upper_Left_Paravertebral_B',
  Back_Left_Shoulder_B: 'Back_Left_Shoulder_B',
  Back_Left_Ribcage_B: 'Back_Left_Ribcage_B',
  Left_Shoulder_Blade_B: 'Left_Shoulder_Blade_B',
  Mid_Left_Paravertebral_B: 'Mid_Left_Paravertebral_B',
  Upper_Right_Flank_B: 'Upper_Right_Flank_B',
  Upper_Right_Paravertebral_B: 'Upper_Right_Paravertebral_B',
  Back_Right_Shoulder_B: 'Back_Right_Shoulder_B',
  Back_Right_Ribcage_B: 'Back_Right_Ribcage_B',
  Right_Shoulder_Blade_B: 'Right_Shoulder_Blade_B',
  Mid_Right_Paravertebral_B: 'Mid_Right_Paravertebral_B',
  Upper_Spinal_B: 'Upper_Spinal_B',
  Mid_Spinal_B: 'Mid_Spinal_B',
  Torso_F: 'Torso_F',
  Sternum_F: 'Sternum_F',
  Left_Lower_Chest_F: 'Left_Lower_Chest_F',
  Left_Upper_Chest_F: 'Left_Upper_Chest_F',
  Left_Front_Shoulder_F: 'Left_Front_Shoulder_F',
  Left_Clavicle_F: 'Left_Clavicle_F',
  Right_Lower_Chest_F: 'Right_Lower_Chest_F',
  Right_Upper_Chest_F: 'Right_Upper_Chest_F',
  Right_Front_Shoulder_F: 'Right_Front_Shoulder_F',
  Right_Clavicle_F: 'Right_Clavicle_F',
  Torso_R: 'Torso_R',
  Right_Upper_Chest_R: 'Right_Upper_Chest_R',
  Upper_Right_Flank_R: 'Upper_Right_Flank_R',
  Right_Shoulder_Blade_R: 'Right_Shoulder_Blade_R',
  Right_Lower_Chest_R: 'Right_Lower_Chest_R',
  Front_Right_Ribcage_R: 'Front_Right_Ribcage_R',
  Torso_L: 'Torso_L',
  Left_Upper_Chest_L: 'Left_Upper_Chest_L',
  Upper_Left_Flank_L: 'Upper_Left_Flank_L',
  Left_Shoulder_Blade_L: 'Left_Shoulder_Blade_L',
  Left_Lower_Chest_L: 'Left_Lower_Chest_L',
  Front_Left_Ribcage_L: 'Front_Left_Ribcage_L',
  Abdomen_F: 'Abdomen_F',
  Epigastric_F: 'Epigastric_F',
  Left_Upper_Abdomen_F: 'Left_Upper_Abdomen_F',
  Right_Upper_Abdomen_F: 'Right_Upper_Abdomen_F',
  Left_Lower_Abdomen_F: 'Left_Lower_Abdomen_F',
  Right_Lower_Abdomen_F: 'Right_Lower_Abdomen_F',
  Umbilical_region_F: 'Umbilical_region_F',
  Right_Middle_Abdomen_F: 'Right_Middle_Abdomen_F',
  Left_Middle_Abdomen_F: 'Left_Middle_Abdomen_F',
  Lumbal_region_B: 'Lumbal_region_B',
  Lumbal_Paravertebral_Left_B: 'Lumbal_Paravertebral_Left_B',
  Lumbal_Paravertebral_Right_B: 'Lumbal_Paravertebral_Right_B',
  Left_SI_Joint_B: 'Left_SI_Joint_B',
  Right_SI_Joint_B: 'Right_SI_Joint_B',
  Left_Lowerback_B: 'Left_Lowerback_B',
  Right_Lowerback_B: 'Right_Lowerback_B',
  Left_Flank_B: 'Left_Flank_B',
  Right_Flank_B: 'Right_Flank_B',
  Lumbal_spine_B: 'Lumbal_spine_B',
  Abdomen_L: 'Abdomen_L',
  Left_Upper_Flank_L: 'Left_Upper_Flank_L',
  Left_Middle_Abdomen_L: 'Left_Middle_Abdomen_L',
  Left_Lower_Flank_L: 'Left_Lower_Flank_L',
  Abdomen_R: 'Abdomen_R',
  Right_Upper_Flank_R: 'Right_Upper_Flank_R',
  Right_Middle_Abdomen_R: 'Right_Middle_Abdomen_R',
  Right_Lower_Flank_R: 'Right_Lower_Flank_R',
  Hip_Region_F: 'Hip_Region_F',
  Left_Hipbone_F: 'Left_Hipbone_F',
  Right_Hipbone_F: 'Right_Hipbone_F',
  Lower_Abdominal_F: 'Lower_Abdominal_F',
  Left_Inguinals_F: 'Left_Inguinals_F',
  Right_Inguinals_F: 'Right_Inguinals_F',
  Pubic_region_F: 'Pubic_region_F',
  Hip_Region_B: 'Hip_Region_B',
  Left_Gluteal_B: 'Left_Gluteal_B',
  Right_Gluteal_B: 'Right_Gluteal_B',
  Perianal_region_B: 'Perianal_region_B',
  Sacral_region_B: 'Sacral_region_B',
  Hip_Region_L: 'Hip_Region_L',
  Left_Gluteal_BL: 'Left_Gluteal_BL',
  Hip_Region_R: 'Hip_Region_R',
  Right_Gluteal_BR: 'Right_Gluteal_BR',
};

export default BODYPARTS;
