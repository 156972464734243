import React from 'react';
import PropTypes from 'prop-types';

import styles from './NewPatient.module.scss';
import { STEPS } from './constants';
import PatientForm from './PatientForm';
import TabletSyncForm from './TabletSyncForm';

const { SSN, SYNC } = STEPS;

const NewPatientView = ({
  checkingSsn,
  complaint,
  displayStep,
  ssnChecked,
  ssnExists,
  syncSuccess,
  uniqueId,
  onSsnChange,
  onPatientSubmit,
  onSyncSubmit,
}) => {
  const patientFormAttrs = {
    onSubmit: onPatientSubmit,
    onSsnChange,
    checkingSsn,
    ssnChecked,
    ssnExists,
  };

  const syncFormAttrs = {
    complaint,
    onSubmit: onSyncSubmit,
    patientId: uniqueId,
    syncSuccess,
  };

  return (
    <div className={styles.root}>
      <div className={styles.column}>
        {displayStep === SSN && <PatientForm {...patientFormAttrs} />}
        {displayStep === SYNC && <TabletSyncForm {...syncFormAttrs} />}
      </div>
    </div>
  );
};

NewPatientView.propTypes = {
  checkingSsn: PropTypes.bool.isRequired,
  complaint: PropTypes.shape({ name: PropTypes.string, id: PropTypes.string }),
  displayStep: PropTypes.oneOf([STEPS.SSN, STEPS.SYNC]).isRequired,
  ssnChecked: PropTypes.bool.isRequired,
  ssnExists: PropTypes.bool.isRequired,
  syncSuccess: PropTypes.bool,
  onSsnChange: PropTypes.func.isRequired,
  onPatientSubmit: PropTypes.func.isRequired,
  onSyncSubmit: PropTypes.func.isRequired,
  uniqueId: PropTypes.string,
};
NewPatientView.defaultProps = {
  checkingSsn: false,
  complaint: null,
  ssnChecked: false,
  ssnExists: false,
  syncSuccess: null,
  uniqueId: '',
};

export default NewPatientView;
