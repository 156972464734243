import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import LoginPage from './pages/Login';
import MiaOverviewPage from './pages/MiaOverview/index';
import NewPatientPage from './pages/NewPatient/index';
import SearchPatientPage from './pages/SearchPatient/index';

import { ROLE, ROUTES } from './constants';
import { LoginApi } from './utils/api';
import Layout from './components/widgets/Layout';
import FillingContainer from './components/widgets/FillingContainer';
import styles from './App.module.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2e4ce6',
    },
  },
});

const ErrorScreen = () => (
  <div className={styles.errorScreen}>
    <div>Sorry, something went wrong</div>
    <div>You may try to reload the page</div>
  </div>
);

const NotFoundScreen = () => <div>Page Not Found</div>;

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const withLayout = (Component) => {
  const WithLayout = (props) => (
    <Layout {...props}>
      <Component {...props} />
    </Layout>
  );
  WithLayout.displayName = `WithLayout(${getDisplayName(Component)})`;
  return WithLayout;
};

const checkLogin = (Component, role = null) => {
  class CheckLogin extends React.PureComponent {
    state = { checked: false, error: null };

    componentDidMount() {
      this.checkLogin();
    }

    async checkLogin() {
      try {
        const isLoggedIn = await LoginApi.checkLogin();
        if (!isLoggedIn) {
          const { history } = this.props;
          history.push(ROUTES.LOGIN(history.location.pathname));
        } else {
          if (!role) {
            this.setState({ checked: true });
          } else {
            this.setState({ checked: true });
          }
        }
      } catch (error) {
        this.setState({ error });
      }
    }

    render() {
      const { checked, error } = this.state;

      if (error) {
        // throw in render to let the App component catch and display error message
        throw error;
      }

      if (!checked) {
        return (
          <FillingContainer>
            <CircularProgress />
          </FillingContainer>
        );
      }

      return <Component {...this.props} />;
    }
  }
  CheckLogin.displayName = `CheckLogin(${getDisplayName(Component)})`;
  return CheckLogin;
};

export default class App extends React.PureComponent {
  state = {
    crashed: false,
  };

  componentDidCatch(err) {
    this.setState({ crashed: true });
  }

  render() {
    const { crashed } = this.state;
    if (crashed) {
      return <ErrorScreen />;
    }

    return (
      <ThemeProvider theme={theme}>
        <div className={styles.root}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/">
                <Redirect to={ROUTES.SEARCH_PATIENT} />
              </Route>
              <Route exact path={ROUTES.LOGIN()} component={LoginPage} />
              <Route
                exact
                path={ROUTES.NEW_PATIENT}
                component={withLayout(
                  checkLogin(NewPatientPage),
                  ROLE.ASSISTANT,
                )}
              />
              <Route
                exact
                path={ROUTES.SEARCH_PATIENT}
                component={withLayout(checkLogin(SearchPatientPage))}
              />
              <Route
                exact
                path={ROUTES.MIA_OVERVIEW}
                component={withLayout(checkLogin(MiaOverviewPage))}
              />
              <Route path="/" component={NotFoundScreen} />
            </Switch>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    );
  }
}
