import i18n from 'i18n-js';

const getNavigatorLanguage = (defaultLanguage) => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return (
      navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      defaultLanguage
    );
  }
};

const en = {
  welcome: 'Welcome to MIA Dashboard',
  job_title: 'I am a',
  physcian: 'Physcian',
  clinical_assistant: 'Clinical Assistant',
  username: 'Username',
  password: 'Password',
  forgot_password: 'Forgot Password?',
  log_in: 'Log in',
  new_patient: 'New Patient',
  initial_complaint: 'Initial Complaint',
  age: 'Age',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  patient_id: 'Patient ID',
  generate_unique_id: 'Generate Unique ID',
  sync_and_activate: 'Sync and Activate MIA app',
  unique_id: 'Unique ID (Medvice Privacy Code)',
  mia_status_overview: 'MIA Status Overview',
  generation_succes: 'Generation succes!',
  years: 'years',
  patient_is_being_linked: 'Patient is being linked to MIA app no:x',
  choose_mia_device_id: 'Choose MIA device ID',
  device_id: 'Device ID',
  age_gender: 'Age  / Gender',
  mia_use_time: 'MIA Use time',
  status: 'Status',
  consult_completed: 'Consult Completed',
  consult_in_process: 'Consult in process',
  mia_complete: 'MIA complete',
  mia_in_process: 'MIA in process',
  waiting_for_mia_sync: 'Waiting for MIA sync',
  f: 'F',
  m: 'M',
  mia_patient_overview: 'MIA Patient Overview (X)',
  start_consult_confirmation:
    'Would you like to start the consult and call in the patient?',
  view_only: 'View only',
  start_consult: 'Start consult',
  consult_in_progress: 'This consult is still in progress',
  consult_has_been_completed: 'This consult has been completed',
  review_consult: 'Review consult',
  patient_data: 'Patient data',
  mia_summary: 'MIA Summary',
  general_anamnesis: 'General anamnesis',
  view_text: 'View text / audio input',
  other_complaints: 'Other complaints',
  helping_question: 'Helping question',
  copy_summary: 'Copy Summary',
  diagnosis_and_feedback: 'Diagnosis and Feedback',
  fill_in_icpc_code: 'Fill in ICPC code',
  search: 'Search',
  free_text: 'Free text',
  location_of_complaint: 'Location of complaint',
  ai_results: 'AI Results',
  close_overview: 'Close overview',
  finish_consult: 'Finish consult',
  update_consult: 'Update consult',
  go_back: 'Go back',
  verify_patient_data: 'Verify patient data',
  insert_ssn: 'Insert SSN',
  consult_time: 'Consult time',
  cancel: 'Cancel',
  ok: 'OK',
  access_mia_overview: 'Access MIA Overview',
  patient_already_exists: 'Patient already exists',
  birthdate: 'Birthdate',
  tablet: 'Tablet',
  tablet_synchronized: 'Tablet synchronized',
  error_tablet_sync:
    'an error occurred and tablet synchronization failed. Please try again.',
  proceed: 'Proceed',
  register_patient: 'Register Patient',
  error_loading_data:
    "An error occurred while loading the patient's data. Please try again.",
  ssn_verification_failed: 'SSN verification failed',
  ssn_dont_match_patient: 'SSN does not match this patient',
  verify: 'Verify',
  search_patient: 'Search Patient',
  mia_overview: 'MIA Overview',
  first_name: 'First name',
  last_name: 'Last name',
  name: 'Name',
  height: 'height',
  weight: 'weight',
  heightInCm: '(in cm)',
  weightInKg: '(in kg)',
  minSSNLength: 'Must be at least %{length} letters long',
  required: 'Required',
  invalid: 'Invalid',
  invalid_date: 'Invalid Date',
  reload: 'Refresh',
  summaryNotFound: 'MIA data are not found',
  emptySummaryListMessage: 'There are currently no active MIA consults',
  view: 'View',
  past_consults: 'Past consultations',
};

const nl = {
  welcome: 'Welkom bij MIA Dashboard',
  job_title: 'Ik ben',
  physcian: 'Arts',
  clinical_assistant: 'Medisch Assistent',
  username: 'Gebruikersnaam',
  password: 'Wachtwoord',
  forgot_password: 'Wachtwoord vergeten?',
  log_in: 'Aanmelden',
  new_patient: 'Patient aanmelden',
  initial_complaint: 'Ingangsklacht',
  age: 'Leeftijd',
  gender: 'Geslacht',
  male: 'Man',
  female: 'Vrouw',
  patient_id: 'BSN Nummer / Patientnummer',
  generate_unique_id: 'Versleutel Identeit',
  sync_and_activate: 'Koppelen aan MIA',
  unique_id: 'Versleuteling',
  mia_status_overview: 'MIA Wachtruimte',
  generation_succes: 'Succesvol versleuteld!',
  years: 'jaar oud',
  patient_is_being_linked: 'Patient wordt gekoppeld aan MIA apparaat nummer X',
  choose_mia_device_id: 'Kies een MIA apparaat voor koppeling',
  device_id: 'Apparaat ID',
  age_gender: 'Leeftijd  / Geslacht',
  mia_use_time: 'Tijd in gebruik',
  status: 'Status',
  consult_completed: 'Consult afgerond',
  consult_in_process: 'Consult bezig',
  mia_complete: 'MIA vragenlijst afgerond!',
  mia_in_process: 'MIA in gebruik',
  waiting_for_mia_sync: 'Wacht op  koppeling',
  f: 'V',
  m: 'M',
  mia_patient_overview: 'Overzicht MIA X',
  start_consult_confirmation: 'Wilt u het consult starten of enkel inzage?',
  view_only: 'Inzien',
  start_consult: 'Consult starten',
  consult_in_progress: 'Dit consult is nog bezig!',
  this_consult_has_been_completed: 'Dit consult is afgerond',
  review_consult: 'Consultinfo inzien',
  patient_data: 'Patientgegevens',
  mia_summary: 'MIA samenvatting',
  general_anamnesis: 'Anamnese',
  view_text: 'Aanvullende info / Spraakherkenning',
  other_complaints: 'Bijkomende klachten',
  helping_question: 'Hulpvraag',
  copy_summary: 'Kopieer naar SOEP',
  diagnosis_and_feedback: 'Evaluatie en Feedback',
  fill_in_icpc_code: 'ICPC toevoegen',
  search: 'Zoeken',
  free_text: 'Beschrijving',
  location_of_complaint: 'Klachtenlokalisatie',
  ai_results: 'Voorspellingen Medvice.AI',
  close_overview: 'Overzicht sluiten',
  finish_consult: 'Consult beeindigen',
  update_consult: 'Consult updaten',
  go_back: 'Naar menu (Consult blijft Actief!)',
  verify_patient_data: 'Gegevensverificatie',
  insert_ssn: 'Vul BSN / Patientnummer in',
  consult_time: 'Totale consulttijd',
  cancel: 'Annuleren',
  ok: 'OK',
  access_mia_overview: 'Open MIA verslag',
  patient_already_exists: 'Patient is al geregistreerd',
  birthdate: 'Geboortedatum',
  tablet: 'Smart device',
  tablet_synchronized: 'Smart device gekoppeld',
  error_tablet_sync:
    'Een fout heeft zich voorgedaan tijdens synchronisatie, probeert u het nog eens',
  proceed: 'Doorgaan',
  register_patient: 'Patient registreren',
  error_loading_data:
    'Een fout heeft zich voorgedaan tijdens het laden van patientgegevens, probeert u het nog eens.',
  ssn_verification_failed: 'BSN verificatiefout',
  ssn_dont_match_patient: 'BSN komt niet overeen',
  verify: 'Verifiëren',
  search_patient: 'MIA Statuslijst',
  mia_overview: 'MIA Overzicht',
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  name: 'Naam',
  height: 'hoogte',
  weight: 'gewicht',
  heightInCm: '(in cm)',
  weightInKg: '(in kg)',
  minSSNLength: 'Moet tenminste %{length} lang zijn',
  required: 'Vereist',
  invalid: 'Ongeldig',
  invalid_date: 'Ongeldig datum',
  reload: 'Overzicht bijwerken',
  summaryNotFound: 'MIA gegevens niet gevonden',
  emptySummaryListMessage: 'Geen actieve MIA consulten',
  view: 'Inzage',
  past_consults: 'Eerdere raadplegingen',
};

i18n.fallbacks = true;
i18n.translations = { nl, en };
i18n.locale = getNavigatorLanguage(en);
// i18n.locale = 'nl';

export default i18n;
