import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/widgets/Modal';
import { STATUS, CONSULT_MODE } from '../../constants';
import { SummaryApi } from '../../utils/api';
import Button from '../../components/widgets/Button/index';

import styles from './SearchPatient.module.scss';

import i18n from '../../i18n';

const { REVIEW, START, VIEW_ONLY } = CONSULT_MODE;

const ViewModeModal = ({ id, onClose, onProceed, patientId, status }) => {
  const parameters = {
    [STATUS.MIA_COMPLETE]: {
      head: i18n.t('start_consult_confirmation'),
      buttons: [
        { label: i18n.t('view_only'), mode: VIEW_ONLY },
        { label: i18n.t('start_consult'), mode: START },
      ],
    },
    [STATUS.CONSULT_IN_PROCESS]: {
      head: i18n.t('consult_in_progress'),
      buttons: [{ label: i18n.t('view_only'), mode: VIEW_ONLY }],
    },
    [STATUS.CONSULT_COMPLETE]: {
      head: i18n.t('consult_has_been_completed'),
      buttons: [
        { label: i18n.t('view_only'), mode: VIEW_ONLY },
        { label: i18n.t('review_consult'), mode: REVIEW },
      ],
    },
  };

  const currentParams = parameters[status];
  if (!currentParams) {
    throw new Error(`Unsupported summary status '${status}'`);
  }
  const { head, buttons } = currentParams;

  const proceed = (mode) => {
    onClose();
    onProceed(mode);
  };

  return (
    <Modal
      modalTitle={head}
      ariaLabel="A dialog to choose which mode to view the MIA Overview in"
      onClose={onClose}
      className={styles.viewModeModal}>
      <div className={styles.viewModeModalRoot}>
        {buttons.map(({ label, mode }, index) => {
          let attrs = {
            onClick: () => {
              proceed(mode);
            },
          };
          switch (mode) {
            default:
            case VIEW_ONLY:
              attrs.autoFocus = true;
              break;
            case START:
              attrs.onClick = async () => {
                await SummaryApi.startConsult(id);
                proceed(mode);
              };
              break;
            case REVIEW:
              break;
          }
          return (
            <Button type="button" muted {...attrs} key={index}>
              {label}
            </Button>
          );
        })}
      </div>
    </Modal>
  );
};

ViewModeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default ViewModeModal;
