import React, { useState } from 'react';

import { PatientApi, JobApi } from '../../utils/api';

import NewPatientView from './NewPatient.view';
import { MIN_SSN_LENGTH, STEPS } from './constants';

const DEBOUNCE_DURATION = 500;

const NewPatient = () => {
  const [ssnChecked, setSsnChecked] = useState(false);
  const [checkingSsn, setCheckingSsn] = useState(false);
  const [searchTimer, setSearchTimer] = useState(null);
  const [existingPatient, setExistingPatient] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [displayStep, setDisplayStep] = useState(STEPS.SSN);
  const [complaint, setComplaint] = useState(null);
  const [syncSuccess, setSyncSuccess] = useState(null);

  // Called on every key change:
  const searchSsn = (ssn) => {
    setSsnChecked(false);

    if (!ssn || ssn.length < MIN_SSN_LENGTH) {
      return;
    }

    setCheckingSsn(true);

    // Custom debouncing
    clearTimeout(searchTimer);
    setSearchTimer(
      setTimeout(async () => {
        setUniqueId(null);
        setExistingPatient(null);
        setDisplayStep(STEPS.SSN);
        let patient;
        try {
          patient = await PatientApi.findBySsn(ssn);
        } catch (err) {
          patient = null;
        }

        setExistingPatient(patient);
        setSsnChecked(true);
        setCheckingSsn(false);
      }, DEBOUNCE_DURATION),
    );
  };

  const onPatientSubmit = async (values) => {
    const {
      birthDate,
      firstName,
      gender,
      height,
      lastName,
      complaint,
      patientId,
      weight,
    } = values;
    setComplaint(complaint);

    if (existingPatient) {
      setUniqueId(existingPatient.username);
    } else {
      const [d, m, y] = birthDate.split('/');

      const newPatient = await PatientApi.create({
        username: patientId,
        date_of_birth: [y, m, d].join('-'),
        first_name: firstName,
        gender,
        last_name: lastName,
        height,
        weight,
      });
      setUniqueId(newPatient.username);
    }
    setDisplayStep(STEPS.SYNC);
  };

  const onSyncSubmit = async ({ tablet, patientId, complaintId }) => {
    let success;
    try {
      await JobApi.syncTablet({
        tabletId: tablet.username,
        patientId,
        complaintId,
      });
      success = true;
    } catch (err) {
      success = false;
      console.error('Failed to perform tablet sync operation', err);
    }
    setSyncSuccess(success);
  };

  const viewProps = {
    checkingSsn,
    ssnChecked,
    ssnExists: !!existingPatient,
    syncSuccess,
    uniqueId,
    complaint,
    displayStep,
    onPatientSubmit,
    onSyncSubmit,
    onSsnChange: searchSsn,
  };
  return <NewPatientView {...viewProps} />;
};

export default NewPatient;
