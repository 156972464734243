import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, Field } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';

import Label from '../../components/form/misc/Label';
import { renderDropDown } from '../../components/form/renderers';
import Button from '../../components/widgets/Button';
import Card from '../../components/widgets/Card';
import FillingContainer from '../../components/widgets/FillingContainer';

import styles from './NewPatient.module.scss';
import { TabletApi } from '../../utils/api';
import { renderHashedSSN } from '../../utils/functions';
import { ROUTES } from '../../constants';

import i18n from '../../i18n';

const TabletSyncForm = ({ patientId, onSubmit, complaint, syncSuccess }) => {
  const patientIdRef = React.createRef();

  const [loading, setLoading] = useState(true);
  const [tablets, setTablets] = useState([]);
  useEffect(() => {
    (async () => {
      const tablets = await TabletApi.list();
      setTablets(tablets);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <FillingContainer>
        <CircularProgress />
      </FillingContainer>
    );
  }

  const validate = ({ tablet }) => {
    const errors = {};
    if (!tablet) {
      errors.tablet = 'Required';
    }
    if (Object.keys(errors).length > 0) {
      return errors;
    }

    return null;
  };

  let result;
  switch (syncSuccess) {
    case true:
      result = (
        <div className={styles.result}>
          <p className={styles.msg}>{i18n.t('tablet_synchronized')}</p>
          <Link to={ROUTES.SEARCH_PATIENT}>{i18n.t('go_back')}</Link>
        </div>
      );
      break;
    case false:
      result = (
        <div className={classnames(styles.result, styles.error)}>
          {i18n.t('error_tablet_sync')}
        </div>
      );
      break;
    default:
      result = null;
      break;
  }

  return (
    <Fragment>
      <Label>{i18n.t('unique_id')}</Label>
      <Card className={styles.privacyDisplay}>
        <input
          readOnly
          ref={patientIdRef}
          type="text"
          value={renderHashedSSN(patientId)}
          className={styles.privacyInput}
          onClick={() => {
            patientIdRef.current.select();
          }}
        />
      </Card>
      <Form
        onSubmit={onSubmit}
        initialValues={{ patientId, complaintId: complaint.id }}
        validate={validate}>
        {({ handleSubmit, submitting, pristine, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Field name="patientId" type="hidden" component="input" />
            <Field name="complaintId" type="hidden" component="input" />

            <div className={styles.formItem}>
              <Label>
                <span className={styles.initialComplaint}>
                  {i18n.t('initial_complaint')}
                </span>
                <span>{complaint.name}</span>
              </Label>
            </div>
            <div className={styles.formItem}>
              <Field
                name="tablet"
                options={tablets}
                formatOption={({ username }) => username}
                label={i18n.t('tablet')}
                fullWidth
                component={renderDropDown}
              />
            </div>
            <Button
              type="submit"
              className={styles.formItem}
              primary
              fullWidth
              disabled={submitting || pristine || invalid || syncSuccess}>
              {i18n.t('sync_and_activate')}
            </Button>
            {result}
          </form>
        )}
      </Form>
    </Fragment>
  );
};

TabletSyncForm.propTypes = {
  complaint: PropTypes.shape({ name: PropTypes.string, id: PropTypes.string })
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  syncSuccess: PropTypes.bool,
};

TabletSyncForm.defaultProps = {
  syncSuccess: null,
};

export default TabletSyncForm;
