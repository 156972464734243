import React from 'react';
import classnames from 'classnames';

import styles from './Table.module.scss';

export const Table = ({ className, children, ...rest }) => (
  <table className={classnames(styles.table, className)} {...rest}>
    {children}
  </table>
);

export const TableRow = ({ className, children, ...rest }) => (
  <tr className={classnames(styles.tableRow, className)} {...rest}>
    {children}
  </tr>
);

export const TableHead = ({ className, children, ...rest }) => (
  <thead className={classnames(styles.tableHead, className)} {...rest}>
    {children}
  </thead>
);

export const TableBody = ({ className, children, ...rest }) => (
  <tbody className={classnames(styles.tableBody, className)} {...rest}>
    {children}
  </tbody>
);

export const TableHeader = ({ className, children, ...rest }) => (
  <th className={classnames(styles.tableHeader, className)} {...rest}>
    {children}
  </th>
);

export const TableCell = ({ className, children, ...rest }) => (
  <td className={classnames(styles.tableCell, className)} {...rest}>
    {children}
  </td>
);
