import { ROLE } from '../constants';
import Session from './session';

const { ASSISTANT, DOCTOR } = ROLE;

export const userIsAssistant = () => Session.role === ASSISTANT;

export const userIsDoctor = () => Session.role === DOCTOR;

export const canAccessResource = (resource) => {
  // Allow to pass a string or an object with a 'role' property
  const role = typeof resource === 'string' ? resource : resource.role;
  return !role || Session.role === role;
};
