import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ExpandIcon from '@material-ui/icons/ExpandMore';

import Label from '../../misc/Label';
import ErrorText from '../../misc/ErrorText';

import styles from './DropDown.module.scss';

export const DropDown = ({
  value: propValue,
  options = [],
  error,
  label,
  className,
  disabled = false,
  fullWidth = false,
  onChange = () => null,
  formatOption = (i) => i,
}) => {
  const [ctrl, setCtrl] = useState(typeof propValue !== 'undefined');
  const [stateValue, setStateValue] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let type = typeof propValue;
    if (ctrl && type === 'undefined') {
      console.warn('DropDown - changing controlled input to uncontrolled mode');
      setCtrl(false);
    } else if (!ctrl && type !== 'undefined') {
      console.warn('DropDown - changing uncontrolled input to controlled mode');
      setCtrl(true);
    }
  }, [propValue, ctrl]);

  const handleChange = (newValue) => {
    onChange(newValue);
    setStateValue(newValue);
  };

  const closeChoices = () => {
    window.removeEventListener('click', closeChoices);
    setOpen(false);
  };
  const toggleChoices = (e) => {
    if (disabled) return;
    e.stopPropagation();
    setOpen((open) => !open);
    window.addEventListener('click', closeChoices);
  };

  const value = ctrl ? propValue : stateValue;
  const classes = classNames(
    className,
    styles.dropDown,
    fullWidth && styles.fullWidth,
    disabled && styles.disabled,
    open && styles.open,
  );
  const valueLabel = value ? formatOption(value) : '';
  return (
    <div className={classes}>
      <label>
        <Label>{label}</Label>
        <div className={styles.dropDownWrapper}>
          <div className={styles.box} onClick={toggleChoices}>
            <div className={styles.valueDisplay}>{valueLabel}</div>
            <ExpandIcon className={styles.icon} />
          </div>
          <div className={styles.dropPanel}>
            {options.map((opt, index) => {
              const classes = classNames(
                styles.option,
                opt === value && styles.selected,
              );
              const label = formatOption(opt);
              return (
                <div
                  key={index}
                  className={classes}
                  title={label}
                  onClick={() => handleChange(opt)}>
                  {label}
                </div>
              );
            })}
          </div>
        </div>
        <ErrorText>{error}</ErrorText>
      </label>
    </div>
  );
};

export default DropDown;
