import React from 'react';

import SvgIcon from './SvgIcon';

const GenderMaleIcon = (props) => (
  <SvgIcon viewBox="0 0 26 30" {...props}>
    <g
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M25 17c0 6.627-5.373 12-12 12S1 23.627 1 17v-4C1 6.373 6.373 1 13 1s12 5.373 12 12z" />
      <path d="M8 16.5a.5.5 0 110 1 .5.5 0 010-1M18 16.5a.5.5 0 110 1 .5.5 0 010-1M15.828 21.828a4 4 0 01-5.656 0M25 14s-8 0-12-6c-4 6-12 6-12 6" />
    </g>
  </SvgIcon>
);

export default GenderMaleIcon;
