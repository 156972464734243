import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { ROLE, ROUTES } from '../../../../constants';

import styles from './NavMenu.module.scss';

import i18n from '../../../../i18n';

const SEARCH_ITEM = {
  path: ROUTES.SEARCH_PATIENT,
  label: i18n.t('search_patient'),
};

const MIA_OVERVIEW_ITEM = {
  path: ROUTES.MIA_OVERVIEW,
  label: i18n.t('mia_overview'),
};

const NEW_PATIENT_ITEM = {
  path: ROUTES.NEW_PATIENT,
  label: i18n.t('new_patient'),
  role: ROLE.ASSISTANT,
};

const breadCrumbs = [
  { path: ROUTES.SEARCH_PATIENT, crumbs: [SEARCH_ITEM] },
  { path: ROUTES.NEW_PATIENT, crumbs: [SEARCH_ITEM, NEW_PATIENT_ITEM] },
  { path: ROUTES.MIA_OVERVIEW, crumbs: [SEARCH_ITEM, MIA_OVERVIEW_ITEM] },
];

const NavMenu = withRouter(({ className, match }) => {
  const activeItem = breadCrumbs.find((item) => match.path === item.path);
  if (!activeItem) {
    return null;
  }

  return (
    <div className={classNames(styles.root, className)}>
      <Breadcrumbs aria-label="breadcrumbs">
        {activeItem.crumbs.map(({ path: to, label }, i) => {
          const selected = match.path === to;
          if (selected) {
            return (
              <span className={classNames(styles.navItem, styles.disabled)}
                key={i}>
                {label}
              </span>
            );
          } else {
            return (
              <Link
                to={to}
                className={classNames(styles.navItem, {
                  [styles.selected]: selected,
                })}
                key={i}>
                {label}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
    </div>
  );
});

export default NavMenu;
