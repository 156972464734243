import React from 'react';

import SvgIcon from './SvgIcon';

const LogoutIcon = (props) => (
  <SvgIcon viewBox="0 0 35 34" {...props}>
    <path
      d="M32 16a1 1 0 010 2H21a1 1 0 010-2zm-2.707-2.293a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L32.586 17zM15 5a1 1 0 010-2h9.375C25.345 3 26 3.873 26 4.833V9a1 1 0 01-2 0V5zm9 20a1 1 0 012 0v4.167c0 .96-.655 1.833-1.625 1.833H15a1 1 0 010-2h9zM0 29.833V4.169c0-.823.608-1.5 1.415-1.66L13.86.035a1.848 1.848 0 011.432.306c.44.316.708.814.708 1.354v30.61a1.67 1.67 0 01-.708 1.354c-.41.295-.933.404-1.433.305L1.416 31.492C.608 31.332 0 30.655 0 29.832zm14 2.12V2.047L2 4.432V29.57zM9 21a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default LogoutIcon;
