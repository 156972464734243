import React from 'react';
import PropTypes from 'prop-types';

import PatientIcon from '../../components/icons/Patient';
import VerifySSN from '../../components/sections/VerifySSN';
import Modal from '../../components/widgets/Modal';
import SectionTitle from '../../pages/MiaOverview/SectionTitle';

import styles from './SearchPatient.module.scss';

import i18n from '../../i18n';

const VerifySSNModal = ({ patientId, onSSNVerified, onClose }) => {
  const title = (
    <SectionTitle>
      <PatientIcon />
      &nbsp;{i18n.t('verify_patient_data')}
    </SectionTitle>
  );
  return (
    <Modal
      modalTitle={title}
      ariaLabel="A dialog to verify a patient's SSN"
      onClose={onClose}
      className={styles.verifyModal}>
      <VerifySSN
        onClose={onClose}
        onSSNVerified={() => {
          onClose();
          onSSNVerified();
        }}
        encryptedUserId={patientId}
      />
    </Modal>
  );
};

VerifySSNModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSSNVerified: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default VerifySSNModal;
