import { GENDER, PATIENT_ID_LENGTH } from '../constants';
import i18n from '../i18n';

export function getQueryStringArgs() {
  return Object.fromEntries(
    window.location.search
      .slice(1)
      .split('&')
      .filter((kv) => !!kv)
      .map((kv) => kv.split('='))
      .map(([k, v]) => [k, decodeURIComponent(v)]),
  );
}

// Modulo function with always positive result
export const posMod = (x, mod) => ((x % mod) + mod) % mod;

const datePat = /^(\d{2,2})\/(\d{2,2})\/(\d{4}|\d{4})$/;
/**
 * Checks for the following valid date formats: DD/MM/YYYY
 * @param {string} dateStr input date string
 */
export function isValidDate(dateStr) {
  var matchArray = dateStr.match(datePat); // is the format ok?
  if (matchArray === null) {
    // Date must be in DD/MM/YYYY format
    return false;
  }

  // parse date into variables
  const [, _day, _month, _year] = matchArray;
  const day = parseInt(_day, 10);
  const month = parseInt(_month, 10);
  const year = parseInt(_year, 10);
  if (month < 1 || month > 12) {
    // check month range
    // ERROR: Month must be between 1 and 12
    return false;
  }
  if (day < 1 || day > 31) {
    // ERROR: Day must be between 1 and 31
    return false;
  }
  if ([4, 6, 9, 11].includes(month) && day === 31) {
    // ERROR: Month ${month} doesn't have 31 days!
    return false;
  }
  if (month === 2) {
    // check for february 29th
    var isleap = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    if (day > 29 || (day === 29 && !isleap)) {
      // ERROR: February ${year} doesn't have ${day} days!
      return false;
    }
  }
  return true; // date is valid
}

/**
 * Hash Social Security Number (SSN) using SHA-256
 * from: https://jameshfisher.com/2017/10/30/web-cryptography-api-hello-world/
 * @param {string} socialSecNum
 */
export async function hashSSN(socialSecNum) {
  const buf = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder('utf-8').encode(socialSecNum),
  );
  return Array.prototype.map
    .call(new Uint8Array(buf), (x) => ('00' + x.toString(16)).slice(-2))
    .join('');
}

export const renderHashedSSN = (ssn) => {
  // Drop the '=' at the end
  return ssn.substr(-PATIENT_ID_LENGTH - 1, PATIENT_ID_LENGTH);
};

export const renderAge = (birthDate) => {
  const defaultReturnValue = 'N/A';
  if (!birthDate) {
    console.warn('Rendering empty age');
    return defaultReturnValue;
  }

  const diff = Date.now() - new Date(birthDate).getTime();
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const renderGender = (gender, long = false) => {
  const defaultReturnValue = 'N/A';
  if (![GENDER.Male, GENDER.Female].includes(gender)) {
    console.warn(`Could not parse gender: '${gender}'`);
    return defaultReturnValue;
  }

  if (long) {
    return i18n.t(gender.toLowerCase());
  }
  return gender.slice(0, 1).toUpperCase();
};

export const renderName = (firstName, lastName) => {
  if (!firstName && !lastName) {
    return 'N/A';
  }
  return `${firstName} ${lastName}`;
};

export const renderHeight = (height) => {
  if (!height) {
    return 'N/A';
  }
  return `${height}cm`;
};

export const renderWeight = (weight) => {
  if (!weight) {
    return 'N/A';
  }
  return `${weight}kg`;
};
